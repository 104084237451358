import { Layout } from "../../layouts/Layout";
import { Tabs } from "./components/Tabs/Tabs";
import { Account } from "./components/Tabs/Account/Account";
import { Billing } from "./components/Tabs/Billing/Billing";
import { Support } from "./components/Tabs/Support/Support";
import { Favorites } from "./components/Tabs/Favorites/Favorites";
import { Alerts } from "./components/Tabs/Alerts/Alerts";
import { Customization } from "./components/Tabs/Customization/Customization";
import { TimeZone } from "./components/Tabs/TimeZone/TimeZone";
import { Bookies } from "./components/Tabs/Bookies/Bookies";
import { Odds } from "./components/Tabs/Odds/Odds";
import { useState } from "react";
import "../../assets/styles/profile.scss";

export const Profile = () => {
  const [activeTab, setActiveTab] = useState("Account");
  return (
    <Layout>
      <div className="container">
        <h1 className="profile__title">My Account</h1>
        <Tabs
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />

        {activeTab === "Account" && <Account />}
        {activeTab === "Billing" && <Billing />}
        {activeTab === "Support" && <Support />}
        {activeTab === "Favorites" && <Favorites />}
        {activeTab === "Alerts" && <Alerts />}
        {activeTab === "Customization" && <Customization />}
        {activeTab === "Time Zone" && <TimeZone />}
        {activeTab === "Bookies" && <Bookies />}
        {activeTab === "Odds" && <Odds />}
      </div>
    </Layout>
  );
};
