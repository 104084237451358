export const prepareTeamsTable = (data) => {
  const tableResult = [];

  const sumResult = {
    team_num: "All",
    name: "Team",
    count: data.sums.fixtures_count,
    w: "",
    d: "",
    l: "",
    goals: "",
    sc: "",
    pts: "",
    last_games: "",
    pm: "",
    avg_total: data.sums.avg_totals.t.toFixed(2),
    avg_total_home: data.sums.avg_totals.t1.toFixed(2),
    avg_total_away: data.sums.avg_totals.t2.toFixed(2),
    btts: data.sums.btts.toFixed(0) + "%",
    csw: data.sums.dry_wins.toFixed(0) + "%",
    over_one_and_half: data.sums.total_more["1.5"].toFixed(0) + "%",
    over_two_and_half: data.sums.total_more["2.5"].toFixed(0) + "%",
    over_three_and_half: data.sums.total_more["3.5"].toFixed(0) + "%",
  };

  delete sumResult.avg_totals;
  delete sumResult.total_more;

  for (let i = 0; i < data.table.length; i++) {
    const lastGamesResults = [];
    for (const match of data.table[i].last_fixtures) {
      if (data.table[i].id === match.teams.home) {
        if (match.score.home > match.score.away) {
          lastGamesResults.push("win");
        } else if (match.score.home < match.score.away) {
          lastGamesResults.push("lose");
        } else {
          lastGamesResults.push("draw")
        }
      } else {
        if (match.score.home > match.score.away) {
          lastGamesResults.push("lose");
        } else if (match.score.home < match.score.away) {
          lastGamesResults.push("win");
        } else {
          lastGamesResults.push("draw")
        }
      }
    }
    tableResult.push({
      team_num: i + 1,
      teamObj: { name: data.table[i].name, id: data.table[i].id },
      count: data.table[i].count,
      w: data.table[i].fixtures_results.win,
      d: data.table[i].fixtures_results.draw,
      l: data.table[i].fixtures_results.lose,
      goals: `${data.table[i].goals.scored}:${data.table[i].goals.skipped}`,
      sc: data.table[i].goals.difference,
      pts: data.table[i].points.all,
      last_games: lastGamesResults,
      pm: data.table[i].points.for_games.toFixed(2),
      avg_total: data.table[i].avg_totals.t.toFixed(2),
      avg_total_home: data.table[i].avg_totals.t1.toFixed(2),
      avg_total_away: data.table[i].avg_totals.t2.toFixed(2),
      btts: data.table[i].btts.toFixed(0) + "%",
      csw: data.table[i].dry_wins.toFixed(0) + "%",
      over_one_and_half: data.table[i].total_more["1.5"].toFixed(0) + "%",
      over_two_and_half: data.table[i].total_more["2.5"].toFixed(0) + "%",
      over_three_and_half: data.table[i].total_more["3.5"].toFixed(0) + "%",
    });
  }
  return { table: tableResult, sums: sumResult };
};
