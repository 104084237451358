export const prepareOddsTable = (data) => {
  const defaultParams = {
    t: [{ total: 0.5, count: 0 }],
    t1: [{ total: 0.5, count: 0 }],
    t2: [{ total: 0.5, count: 0 }],
    f1: [{ total: -0.5, count: 0 }],
    f2: [{ total: -0.5, count: 0 }],
  }

  const arrParams = {
    t: [0.5],
    t1: [0.5],
    t2: [0.5],
    f1: [-0.5],
    f2: [-0.5],
  }

  let homeWins = 0
  let draws = 0
  let awayWins = 0

  Array.from(data).forEach((el) => {
    if (el.t1 > el.t2) homeWins++
    else if (el.t1 < el.t2) awayWins++
    else draws++

    for (let i = 0; i < defaultParams.t.length; i++) {
      if (el.t > defaultParams.t[i].total) {
        if (!arrParams.t.includes(defaultParams.t[i].total + 1)) {
          defaultParams.t.push({
            total: defaultParams.t[i].total + 1,
            count: 0,
          })
          arrParams.t.push(defaultParams.t[i].total + 1)
        }
      } else break
    }
    for (let i = 0; i < defaultParams.t.length; i++) {
      if (el.t > defaultParams.t[i].total) defaultParams.t[i].count++
    }

    for (let i = 0; i < defaultParams.t1.length; i++) {
      if (el.t1 > defaultParams.t1[i].total) {
        if (!arrParams.t1.includes(defaultParams.t1[i].total + 1)) {
          defaultParams.t1.push({
            total: defaultParams.t1[i].total + 1,
            count: 0,
          })
          arrParams.t1.push(defaultParams.t1[i].total + 1)
        }
      } else break
    }
    for (let i = 0; i < defaultParams.t1.length; i++) {
      if (el.t1 > defaultParams.t1[i].total) defaultParams.t1[i].count++
    }

    for (let i = 0; i < defaultParams.t2.length; i++) {
      if (el.t2 > defaultParams.t2[i].total) {
        if (!arrParams.t2.includes(defaultParams.t2[i].total + 1)) {
          defaultParams.t2.push({
            total: defaultParams.t2[i].total + 1,
            count: 0,
          })
          arrParams.t2.push(defaultParams.t2[i].total + 1)
        }
      } else break
    }
    for (let i = 0; i < defaultParams.t2.length; i++) {
      if (el.t2 > defaultParams.t2[i].total) defaultParams.t2[i].count++
    }

    if (el.t1 > el.t2) {
      for (let i = 0; i < defaultParams.f1.length; i++) {
        if (el.t2 - el.t1 + 0.5 !== defaultParams.f1[i].total) {
          if (el.t2 - el.t1 + 0.5 < defaultParams.f1[i].total) {
            if (!arrParams.f1.includes(defaultParams.f1[i].total - 1)) {
              defaultParams.f1.push({
                total: defaultParams.f1[i].total - 1,
                count: 0,
              })
              arrParams.f1.push(defaultParams.f1[i].total - 1)
            }
            // counts.f1++
          } else {
            if (!arrParams.f1.includes(defaultParams.f1[i].total + 1)) {
              defaultParams.f1.push({
                total: defaultParams.f1[i].total + 1,
                count: 0,
              })
              arrParams.f1.push(defaultParams.f1[i].total + 1)
            }
            // counts.f1++
          }
        } else break
      }

      for (let i = 0; i < defaultParams.f1.length; i++) {
        if (el.t2 - el.t1 + 0.5 === defaultParams.f1[i].total)
          defaultParams.f1[i].count++
      }

      for (let i = 0; i < defaultParams.f2.length; i++) {
        if (Math.abs(el.t2 - el.t1 - 0.5) !== defaultParams.f2[i].total) {
          if (Math.abs(el.t2 - el.t1 - 0.5) > defaultParams.f2[i].total) {
            if (!arrParams.f2.includes(defaultParams.f2[i].total + 1)) {
              defaultParams.f2.push({
                total: defaultParams.f2[i].total + 1,
                count: 0,
              })
              arrParams.f2.push(defaultParams.f2[i].total + 1)
            }
            // counts.f2++
          } else {
            if (!arrParams.f2.includes(defaultParams.f2[i].total - 1)) {
              defaultParams.f2.push({
                total: defaultParams.f2[i].total - 1,
                count: 0,
              })
              arrParams.f2.push(defaultParams.f2[i].total - 1)
            }
            // counts.f2++
          }
        } else break
      }

      for (let i = 0; i < defaultParams.f2.length; i++) {
        if (Math.abs(el.t2 - el.t1 - 0.5) === defaultParams.f2[i].total)
          defaultParams.f2[i].count++
      }
    } else {
      for (let i = 0; i < defaultParams.f1; i++) {
        if (Math.abs(el.t1 - el.t2 - 0.5) !== defaultParams.f1[i].total) {
          if (Math.abs(el.t1 - el.t2 - 0.5) > defaultParams.f1[i].total) {
            if (!arrParams.f1.includes(defaultParams.f1[i].total + 1)) {
              defaultParams.f1.push({
                total: defaultParams.f1[i].total + 1,
                count: 0,
              })
              arrParams.f1.push(defaultParams.f1[i].total + 1)
            }
            // counts.f1++
          } else {
            if (!arrParams.f1.includes(defaultParams.f1[i].total - 1)) {
              defaultParams.f1.push({
                total: defaultParams.f1[i].total - 1,
                count: 0,
              })
              arrParams.f1.push(defaultParams.f1[i].total - 1)
            }
            // counts.f1++
          }
        } else break
      }

      for (let i = 0; i < defaultParams.f1.length; i++) {
        if (Math.abs(el.t1 - el.t2 - 0.5) === defaultParams.f1[i].total)
          defaultParams.f1[i].count++
      }

      for (let i = 0; i < defaultParams.f2.length; i++) {
        if (el.t1 - el.t2 + 0.5 !== defaultParams.f2[i].total) {
          if (el.t1 - el.t2 + 0.5 < defaultParams.f2[i].total) {
            if (!arrParams.f2.includes(defaultParams.f2[i].total - 1)) {
              defaultParams.f2.push({
                total: defaultParams.f2[i].total - 1,
                count: 0,
              })
              arrParams.f2.push(defaultParams.f2[i].total - 1)
            }
            // counts.f2++
          } else {
            if (!arrParams.f2.includes(defaultParams.f2[i].total + 1)) {
              defaultParams.f2.push({
                total: defaultParams.f2[i].total + 1,
                count: 0,
              })
              arrParams.f2.push(defaultParams.f2[i].total + 1)
            }
          }
        } else break
      }

      for (let i = 0; i < defaultParams.f2.length; i++) {
        if (el.t1 - el.t2 + 0.5 === defaultParams.f2[i].total)
          defaultParams.f2[i].count++
      }
    }
  })

  const tableResult = Object.entries(defaultParams).map(([key, value]) => ({
    [key]: value
      // .filter((_, index) => index + 1 < value.length)
      .sort((a, b) => a.total - b.total)
      ?.map((el) => {
        if (key !== "f1" && key !== "f2")
          return {
            total: el.total,
            over: el.count + "/" + data?.length,
            under: data?.length - el.count + "/" + data?.length,
          }
        return {
          total: el.total,
          handicap: el.count + "/" + data?.length,
        }
      }),
  }))

  return {
    table: tableResult,
    sums: {
      home_wins: homeWins + "/" + data?.length,
      draws: draws + "/" + data?.length,
      away_wins: awayWins + "/" + data?.length,
    },
  }
}
