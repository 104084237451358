import { useState } from "react";
import { Modal } from "../../../../../../components/Modal/Modal";
import { Button } from "../../../../../../UI/buttons/Button";
import "./BillingModal.scss";
import { Success } from "../../../../../../components/Success/Success";

export const BillingModal = ({ show, onCloseClick }) => {
  const [step, setStep] = useState(0);
  const [reason, setReason] = useState("Switched to another tool");

  const returnRadioClass = (activeReason) =>
    activeReason === reason ? "billing-radio__content_active" : "";

  const nodes = [
    {
      title: "What made you switch plans?",
      child: (
        <div>
          <p className="billing-modal__text">
            If you have a moment, we would love to know why you decided to
            switch to the Free plan.
          </p>
          <ul className="billing-modal-reasons">
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("Switched to another tool")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "Switched to another tool"
                  )}`}></div>
              </div>
              Switched to another tool
            </li>
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("Didn’t use the paid features")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "Didn’t use the paid features"
                  )}`}></div>
              </div>
              Didn’t use the paid features
            </li>
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("Too expensive")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "Too expensive"
                  )}`}></div>
              </div>
              Too expensive
            </li>
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("Didn’t use Statoola enough")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "Didn’t use Statoola enough"
                  )}`}></div>
              </div>
              Didn’t use Statoola enough
            </li>
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("No longer need Statoola")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "No longer need Statoola"
                  )}`}></div>
              </div>
              No longer need Statoola
              <span>(e.g. I’m no longer placing bets)</span>
            </li>
            <li
              className="billing-modal-reasons__item"
              onClick={() => setReason("Other")}>
              <div className="billing-radio">
                <div
                  className={`billing-radio__content ${returnRadioClass(
                    "Other"
                  )}`}></div>
              </div>
              Other
            </li>
          </ul>
          <p className="billing-modal__label">Add comment</p>
          <textarea
            className="billing-modal__textarea"
            type="text"
            placeholder="Add comment"></textarea>
          <div className="billing-modal-buttons">
            <Button
              color="blue"
              onClick={() => setStep((prev) => prev + 1)}>
              Send Feedback
            </Button>
            <Button color="gray">Not now</Button>
          </div>
        </div>
      ),
    },
    {
      title: "We’ll miss you",
      child: (
        <div>
          <textarea
            className="billing-modal__textarea"
            placeholder="Please share with us the areas that need to be corrected so that you can start using Statoola again. What factors would inspire you to return to our platform?"
          />
          <Button
            color="blue"
            onClick={() => setStep((prev) => prev + 1)}
            className="billing-modal__button_cancel">
            Confirm cancellation
          </Button>
          <p className="billing-modal__cancel">
            Never mind, I don’t want to cancel.
          </p>
        </div>
      ),
    },
    {},
  ];
  if (step < 2) {
    return (
      <Modal
        show={show}
        title={nodes[step].title}
        onCloseClick={() => {
          setStep(0);
          onCloseClick();
        }}>
        {nodes[step].child}
      </Modal>
    );
  } else {
    return (
      <Success
        onButtonClick={() => {
          setStep(0);
          onCloseClick();
        }}
        show={true}
        contentText="Your subscripition will be cancelled on 5 may 2023."
        buttonText="Ok"
        check={true}
      />
    );
  }
};
