import { useState, useEffect } from "react";
import arrow from "../../assets/svg/angle-down.svg";
import "./FilterWithPrev.scss";

export const FilterWithPrev = ({
  filters,
  setFilter,
  styles,
  activeFilter,
  setAll,
  maxLength,
  withAll = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    setIsAll(maxLength === activeFilter?.length);
  }, [activeFilter, maxLength]);

  const getSeasonsItems = (seasons) => {
    const previous = [];
    const current = [];
    for (let i = 0; i < seasons?.length; i++) {
      if (i > 9) {
        current.push(seasons[i]);
      } else {
        previous.push(seasons[i]);
      }
    }

    return [previous, current];
  };

  const toggleActive = () => {
    setIsActive((prev) => !prev);
  };

  const setInactive = () => {
    setIsActive(false);
  };

  return (
    <div className="line-filter__container">
      <div style={{ ...styles, display: "flex" }}>
        <div className="previous-filter">
          <div className="previous-filter-header" onClick={toggleActive}>
            <span>Previous seasonss</span>
            <img src={arrow} alt="" className="previos-filter__arrow" />
          </div>
          {isActive && (
              <div className="previous-filter-body">
                <div className="previous-filter-grid">
                  {getSeasonsItems(filters)[0].map((filter) => (
                    <div
                      className={`previous-filter__item ${
                        activeFilter.includes(filter) && !isAll
                          ? "previous-filter__item_active"
                          : ""
                      }`}
                      onClick={() => setFilter(filter)}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
                <button
                  className="previous-filter__button"
                  onClick={setInactive}
                >
                  Ok
                </button>
              </div>
            )}
        </div>
        <div className="line-filter" style={styles}>
          {getSeasonsItems(filters)[1].map((filter) => {
            return (
              <div
                className={`line-filter__item ${
                  activeFilter?.includes(filter) && !isAll
                    ? "line-filter__item_active"
                    : ""
                }`}
                onClick={() => setFilter(filter)}
              >
                {filter}
              </div>
            );
          })}
          {withAll && (
            <div
              className={`line-filter__item ${
                isAll ? "line-filter__item_active" : ""
              }`}
              onClick={() => setAll()}
            >
              All
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
