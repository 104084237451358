import { Layout } from "../../layouts/Layout";
import "../../assets/styles/learning.scss";

export const Learning = () => {
  const lessons = [
    {
      title: "Lesson 1",
      desc: "Subscribe to the newsletter and keep up to date with all the events of your favorite team",
      time: "2:50",
    },
    {
      title: "Lesson 2",
      desc: "Subscribe to the newsletter and keep up to date with all the events of your favorite team",
      time: "2:50",
    },
    {
      title: "Lesson 3",
      desc: "Subscribe to the newsletter and keep up to date with all the events of your favorite team",
      time: "2:50",
    },
    {
      title: "Lesson 4",
      desc: "Subscribe to the newsletter and keep up to date with all the events of your favorite team",
      time: "2:50",
    },
    {
      title: "Lesson 5",
      desc: "Subscribe to the newsletter and keep up to date with all the events of your favorite team",
      time: "2:50",
    },
  ];
  return (
    <Layout>
      <div className="container">
        <h1 className="title learning__title">Learning center</h1>
        <p className="learning__desc">
          Welcome to the "Learning Center", your one-stop destination for
          educational video tutorials on sports betting tools and statistics.
          Here, we aim to guide you through the intricate world of sports
          betting, offering you a wealth of knowledge and insights on how to
          leverage tools and interpret stats effectively, optimising your
          betting strategies for success. Explore, learn and enhance your
          understanding of sports betting with us.
        </p>
        <div className="learning-wrapper">
          <div className="learning__player"></div>
          <div className="learning-lessons">
            {lessons.map((lesson) => (
              <div className="learning__lesson">
                <div className="learning-topic">
                  <p className="learning__name">{lesson.title}</p>
                  <p className="learning__time">{lesson.time}</p>
                </div>
                <p className="learning__text">{lesson.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
