function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const convertDate = (date) => {
  let seconds = date;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};
