export const prepareRefereeSeasonTable = (data) => {
  const sumResult = [
    {
      seasons: "All",
      yellow_cards: 0,
      red_cards: 0,
      penalties: 0,
      fouls: 0,
    },
    { seasons: "All", yellow_cards: 0, red_cards: 0, penalties: 0, fouls: 0 },
  ];
  const averageLeague = [];
  const totalLeague = [];
  Object.entries(data || {}).forEach(([key, value]) => {
    const currentAvegareLeague = {
      seasons: value?.name,
      yellow_cards: 0,
      red_cards: 0,
      penalties: 0,
      fouls: 0,
    };
    const currentTotalLeague = {
      seasons: value?.name,
      yellow_cards: 0,
      red_cards: 0,
      penalties: 0,
      fouls: 0,
    };
    for (const [categKey, categVal] of Object.entries(value?.stats)) {
      currentAvegareLeague[categKey] = (
        isNaN(categVal?.value / categVal?.count)
          ? 0
          : categVal?.value / categVal?.count
      ).toFixed(2);

      sumResult[0][categKey] += isNaN(categVal?.value / categVal?.count)
        ? 0
        : categVal?.value / categVal?.count;

      sumResult[1][categKey] += categVal?.value;

      currentTotalLeague[categKey] = categVal?.value;
    }

    averageLeague.push(currentAvegareLeague);
    totalLeague.push(currentTotalLeague);
  });
  sumResult[0]["yellow_cards"] = sumResult[0]["yellow_cards"].toFixed(2);
  sumResult[0]["red_cards"] = sumResult[0]["red_cards"].toFixed(2);
  sumResult[0]["penalties"] = sumResult[0]["penalties"].toFixed(2);
  sumResult[0]["fouls"] = sumResult[0]["fouls"].toFixed(2);

  return {
    table: { averageLeague: averageLeague, totalLeague: totalLeague },
    sums: sumResult,
  };
};
