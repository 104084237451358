import "./Favorites.scss";

export const Favorites = () => {
  return (
    <div className="favorites">
      <p className="favorites__title">
        A list of favorite games. Manage your favorites to keep up to date with
        the news of your favorite team!
      </p>
    </div>
  );
};
