import { useEffect, useState } from "react"

export const useWindowResize = () => {
  const [windowWidth, setWindowWidth] = useState(window.outerWidth)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  })

  return windowWidth
}
