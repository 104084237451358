import onex from "../../../../../assets/bookies/bookies-settings/1xbet.svg";
import bethreesixfive from "../../../../../assets/bookies/bookies-settings/bet365.svg";
import pincale from "../../../../../assets/bookies/bookies-settings/pinacle.svg";
import unibet from "../../../../../assets/bookies/bookies-settings/unibet.svg";
import bwin from "../../../../../assets/bookies/bookies-settings/bwin.svg";
import youbet from "../../../../../assets/bookies/bookies-settings/youbet.svg";
import oneeightbet from "../../../../../assets/bookies/bookies-settings/188bet.svg";
import eightbet from "../../../../../assets/bookies/bookies-settings/888sport.svg";
import betathome from "../../../../../assets/bookies/bookies-settings/betathome.svg";
import betfair from "../../../../../assets/bookies/bookies-settings/betfair.svg";
import betvictor from "../../../../../assets/bookies/bookies-settings/betvictor.svg";
import coral from "../../../../../assets/bookies/bookies-settings/coral.svg";
import marafon from "../../../../../assets/bookies/bookies-settings/1xbet.svg";
import tipico from "../../../../../assets/bookies/bookies-settings/tipico.svg";
import betsson from "../../../../../assets/bookies/bookies-settings/1xbet.svg";
import hill from "../../../../../assets/bookies/bookies-settings/betsson.svg";
import betway from "../../../../../assets/bookies/bookies-settings/betway.svg";
import twotwobet from "../../../../../assets/bookies/bookies-settings/22bet.svg";
import paddypower from "../../../../../assets/bookies/bookies-settings/paddypower.svg";
import ladbrokes from "../../../../../assets/bookies/bookies-settings/ladbrokes.svg";
import { GridItem } from "../../../UI/GridItem/GridItem";
import { Button } from "../../../../../UI/buttons/Button";
import "./Bookies.scss";

export const Bookies = () => {
  const items = [
    { img: onex, name: "1xbet" },
    { img: bethreesixfive, name: "bet365" },
    { img: pincale, name: "pinacle" },
    { img: unibet, name: "unibet" },
    { img: bwin, name: "bwin" },
    { img: youbet, name: "youbet" },
    { img: oneeightbet, name: "188bet" },
    { img: eightbet, name: "888sport" },
    { img: betathome, name: "bet at home" },
    { img: betfair, name: "bet fair" },
    { img: betvictor, name: "bet victor" },
    { img: coral, name: "coral" },
    { img: marafon, name: "marafon bet" },
    { img: tipico, name: "tipico" },
    { img: betsson, name: "betsson" },
    { img: hill, name: "hill" },
    { img: betway, name: "betway" },
    { img: twotwobet, name: "22bet" },
    { img: paddypower, name: "paddypower" },
    { img: ladbrokes, name: "ladbrokes" },
    { img: marafon, name: "marafon" },
  ];
  return (
    <div className="bookies">
      <div className="bookies-header">
        <div className="bookies__title">
          Select the bookmakers that will be displayed in the dashboard.
        </div>
        <div>
          <p className="bookies-select-label">By default</p>
          <select className="bookies-select">
            {items.map((item, index) => (
              <option key={index}>{item.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="bookies-grid">
        {items.map((item, index) => (
          <GridItem key={index}>
            <div className="grid-item-checkbox"></div>
            <img
              src={item.img}
              alt={item.name}
            />
          </GridItem>
        ))}
      </div>

      <div className="bookies-buttons">
        <Button color="blue">Save changes</Button>
        <Button color="gray">Reset</Button>
      </div>
    </div>
  );
};

