import React from "react"
import "./ChartCard.scss"
import { Chart } from "./Chart/Chart"

export const ChartCard = ({ descriptiveStats }) => {
  return (
    <div className="chart__card">
      <div className="chart__card-item">
        <h3 className="chart__card-item-title">
          Goals scored in 10-minute intervals
        </h3>
        <Chart percents={descriptiveStats?.tenMinsPerc} interval={10} />
      </div>
      <div className="chart__card-item">
        <h3 className="chart__card-item-title">
          Goals scored in 15-minute intervals
        </h3>
        <Chart percents={descriptiveStats?.fifteenMinsPerc} interval={15} />
      </div>
    </div>
  )
}
