import { useState } from "react";
import "./TimeFIlter.scss";

export const TimeFilter = ({
  filters,
  setFilter,
  styles,
  child,
  activeFilter,
  timerangeValues,
  withRange = false,
}) => {
  const initialState = { start: 0, end: 0 };
  const [timerange, setTimerage] = useState(initialState);

  return (
    <div style={{ display: "flex" }}>
      <div className="time-filter" style={styles}>
        {Array.isArray(filters || [])
          ? filters?.map((key) => (
              <div
                style={child}
                key={key}
                onClick={() => {
                  setTimerage(initialState)
                  setFilter(key)
                }}
                className={`time-filter__item ${
                  key === activeFilter || filters[key]
                    ? "time-filter__item_active"
                    : ""
                }`}
              >
                {key}
              </div>
            ))
          : Object.keys(filters || {}).map((key) => (
              <div
                style={child}
                key={key}
                onClick={() => {
                  setTimerage(initialState)
                  setFilter(key)
                }}
                className={`time-filter__item ${
                  filters[key] ? "time-filter__item_active" : ""
                }`}
              >
                {key}
              </div>
            ))}
        {withRange && (
          <>
            <div
              className={`time-filter__item
              time-filter__item-disabled ${
                timerange.start !== initialState.start ||
                timerange.end !== initialState.end
                  ? "time-filter__item_active"
                  : ""
              }`}
            >
              Time range from
            </div>
            <input
              className="time-filter__input"
              type="number"
              onChange={(e) => {
                setTimerage((prev) => {
                  return { ...prev, start: +e.target.value };
                });
                setFilter({
                  ...timerange,
                  start: +e.target.value,
                });
              }}
              value={timerangeValues?.start}
              min={0}
              max={90}
            />
            <div
              className={`time-filter__item
              time-filter__item-disabled ${
                timerange.start !== initialState.start ||
                timerange.end !== initialState.end
                  ? "time-filter__item_active"
                  : ""
              }`}
            >
              to
            </div>
            <input
              className="time-filter__input time-filter__input-second"
              type="number"
              onChange={(e) => {
                setTimerage((prev) => {
                  return { ...prev, end: +e.target.value };
                });
                setFilter({
                  ...timerange,
                  end: +e.target.value,
                });
              }}
              value={timerangeValues?.end}
              min={0}
              max={90}
            />
          </>
        )}
      </div>
    </div>
  );
};
