import $api from "../../../http";

export const getLeague = async (id) => {
  return await $api
    .get(`/league/header?id=${id}`)
    .then((response) => response.data);
};

export const getLeagueTeams = async (stat_type, count, id, place, filters) => {
  return await $api
    .post(
      `/league/teams?stat_type=${stat_type}&count=${count}&id=${id}&place=${place}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getLeagueReferees = async (stat_type, count, id, filters) => {
  return await $api
    .post(
      `/league/referees?stat_type=${stat_type}&count=${count}&id=${id}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getLeagueStandings = async (id, season) => {
  return await $api
    .get(`/league/standings?id=${id}&season=${season}`)
    .then((response) => response.data);
};

export const getLeagueStats = async (id, stat_type, time) => {
  return await $api
    .get(`/league/stats/season?id=${id}&stat_type=${stat_type}&time=${time}`)
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getDescriptiveStats = async (id, season_name) => {
  return await $api
    .get(`/league/stats/descriptive?id=${id}&season=${season_name}`)
    .then((response) => response.data);
};

export const getNextFixtures = async (page, id) => {
  return await $api
    .get(
      `/league/fixtures/next?page=${page}&per_page=9&sort=date&order=asc&league_id=${id}`
    )
    .then((response) => response.data);
};

// /league/fixtures/next?id=${id}&offset=${offset}&limit=${limit}

export const getPreviousFixtures = async (page, id) => {
  return await $api
    .get(
      `/league/fixtures/previous?page=${page}&per_page=9&sort=date&order=desc&league_id=${id}`
    )
    .then((response) => response.data);
};

export const getLeaguesAndCups = async (page, id) => {
  return await $api
    .get(
      `/league/other?page=${page}&per_page=4&sort=id&order=desc&league_id=${id}`
    )
    .then((response) => response.data);
};
