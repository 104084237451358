import { useEffect, useState } from "react";
import {
  getFixtures,
  getLeagueStats,
  getNextFixtures,
  getOddsByTotals,
  getPreviousFixtures,
  getSeasonStats,
  getTeam,
} from "../http";
import { prepareSeasonTable } from "../utils/prerareSeasonTable";
import { prepareStatsTime } from "../../../utils/prepareStatsTime";
import { prepareTournamentsTable } from "../utils/prepareTournamentsTable";
import { prepareFilters } from "../utils/prepareFilters";
import { catchTotals } from "../utils/catchTotals";
import { prepareFixturesTable } from "../../../utils/prepareFixturesTable";
import { prepareOddsTable } from "../../../utils/prepareOddsTable";
import { prepareTeamHeader } from "../../../utils/prepareTeamHeader";
import { useStats } from "../../League/hooks/useStats";
import { prepareTable } from "../../../utils/prepareTable";
import { verticalTable } from "../../../utils/verticalTable";
import { sleep } from "../../../utils/sleep";

export const useTeamStats = (id) => {
  const [team, setTeam] = useState();
  const [leagueId, setLeagueId] = useState();
  const [filters, setFilters] = useState();
  const [seasonFilters, setSeasonFilters] = useState();
  const [tournamentsFilters, setTournamentsFilters] = useState();
  const [matches, setMatches] = useState();
  const [tableFixtures, setTableFixtures] = useState();
  const [tableSeason, setTableSeason] = useState();
  const [tableTournaments, setTableTournaments] = useState();
  const [tableOdds, setTableOdds] = useState();
  const [currentLeagues, setCurrentLeagues] = useState();
  const [timerange, setTimerange] = useState({});

  const [tableTeamManual, setTableTeamManual] = useState({
    table: [],
    sums: {},
  });

  const [nameTournamet, setNameTournament] = useState();

  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [loadingTournament, setLoadingTournament] = useState(false);
  const [statisticsStatus, setStatisticsStatus] = useState(null);
  const [seasonStatus, setSeasonStatus] = useState(null);
  const [tournamentsStatus, setTournamentsStatus] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let headerResponse = {};
      await getTeam(id).then((data) => {
        headerResponse = data;
        setTeam(prepareTeamHeader(data));
        setLeagueId(data.last_tournament.id);
      });

      const seasons = [...headerResponse.params.seasons];
      // for (const key of headerResponse.params.seasons) {
      //   if (headerResponse.params.fixtures.default.seasons.includes(key))
      //     seasons[key] = true;
      //   else seasons[key] = false;
      // }

      const times = {};
      for (const key of headerResponse.params.fixtures.stats.time) {
        if (typeof key === "string") {
          if (headerResponse.params.fixtures.default.time === key)
            times[key] = true;
          else times[key] = false;
        }
      }

      const newLeagues = prepareTeamHeader(headerResponse).leagues;
      setCurrentLeagues(headerResponse.params.leagues);

      for (const league of headerResponse.params.leagues) {
        for (const leag of headerResponse.params.fixtures.default.leagues) {
          if (leag === league.id) newLeagues[league.name] = true;
        }
      }

      setFilters({
        count: headerResponse.params.fixtures.default.count,
        time: times,
        stat_type: headerResponse.params.fixtures.default.stat_type,
        seasons: [...headerResponse.params.fixtures.default.seasons],
        place:
          headerResponse.params.fixtures.default.place.charAt(0).toUpperCase() +
          headerResponse.params.fixtures.default.place.slice(1),
        leagues: newLeagues,
        under_active_coach:
          headerResponse.params.fixtures.default.under_active_coach,
        against_teams: headerResponse.params.fixtures.default.against_teams,
      });

      try {
        setLoadingStatistics(true);
        await sleep(500);
        const fixturesResponse = await getFixtures(
          headerResponse.params.fixtures.default.stat_type,
          headerResponse.params.fixtures.default.count,
          id,
          headerResponse.params.fixtures.default.place.charAt(0).toUpperCase() +
            headerResponse.params.fixtures.default.place.slice(1),
          headerResponse.params.fixtures.default.under_active_coach,
          prepareFilters(
            {
              time: times,
              seasons: seasons,
              leagues: newLeagues,
              against_teams:
                headerResponse.params.fixtures.default.against_teams,
            },
            currentLeagues
          )
        );

        setTableFixtures(prepareTable(fixturesResponse.data));
        setStatisticsStatus(fixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingStatistics(false);
      }

      try {
        setLoadingSeason(true);
        await sleep(500);
        const seasonResponse = await getSeasonStats(
          id,
          headerResponse.params.fixtures.default.seasons[0],
          headerResponse.params.stats.default.stat_type,
          headerResponse.params.stats.default.place,
          headerResponse.params.stats.default.time
        );

        setTableSeason(prepareSeasonTable(seasonResponse.data));
        setSeasonStatus(seasonResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingSeason(false);
      }

      setSeasonFilters({
        id: id,
        season_name: headerResponse.params.fixtures.default.seasons[0],
        time: times,
        stat_type: headerResponse.params.stats.default.stat_type,
        place:
          headerResponse.params.stats.default.place.charAt(0).toUpperCase() +
          headerResponse.params.stats.default.place.slice(1),
      });

      try {
        setLoadingTournament(true);
        await sleep(500);
        const tournamentsResponse = await getLeagueStats(
          id,
          headerResponse.params.leagues[0].id,
          headerResponse.params.stats.default.stat_type,
          headerResponse.params.stats.default.place,
          headerResponse.params.stats.default.time
        );
        setTableTournaments(prepareTournamentsTable(tournamentsResponse.data));
        setTournamentsStatus(tournamentsResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingTournament(false);
      }

      setTournamentsFilters({
        id: id,
        league_id: headerResponse.params.leagues[0].id,
        stat_type: headerResponse.params.stats.default.stat_type,
        place:
          headerResponse.params.stats.default.place.charAt(0).toUpperCase() +
          headerResponse.params.stats.default.place.slice(1),
        time: times,
      });

      setNameTournament({ name: headerResponse.params.leagues[0].name });

      const previousResponse = await getPreviousFixtures(1, id);
      setMatches((prev) => ({
        ...prev,
        previous: previousResponse,
      }));

      const nextResponse = await getNextFixtures(1, id);
      setMatches((prev) => ({ ...prev, next: nextResponse }));

      // const oddsResponse = await getOddsByTotals(
      //   catchTotals(prepareFixturesTable(fixturesResponse))
      // );
      // setTableOdds(prepareOddsTable(oddsResponse));
    };
    fetch();
  }, []);

  const { header } = useStats(leagueId);

  // функция для расчета over/under таблицы снизу
  const overUnderTable = (arr) => {
    const amountRows = Math.max.apply(
      Math,
      arr.map((i) => i.t)
    );
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el.t >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета T10/T1U и T2O/T2U таблиц снизу
  const secondAndThirdOverUnderTable = (arr) => {
    const amountRows = Math.max.apply(Math, arr);
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета H1 и H2 таблиц снизу
  const fourthAndFifthTable = (arr) => {
    let negativeMax = -1000;
    let positiveMax = -1000;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] > arr[i][1]) {
        if (arr[i][0] - arr[i][1] > negativeMax) {
          negativeMax = arr[i][0] - arr[i][1];
        }
      } else {
        if (arr[i][1] - arr[i][0] > positiveMax) {
          positiveMax = arr[i][1] - arr[i][0];
        }
      }
    }
    let res1 = Array.from({ length: negativeMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] > i).length;
      return { total: `${-0.5 - i}`, handicap: `${overValue}/${arr.length}` };
    });
    let res2 = Array.from({ length: positiveMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] >= -i).length;
      return { total: `${i + 0.5}`, handicap: `${overValue}/${arr.length}` };
    });
    let res = res1.concat(res2);

    return res;
  };

  const setTableTotalsManual = (activeArr) => {
    if (activeArr?.length) {
      setTableOdds({
        table: [
          { t: overUnderTable(activeArr) },
          { t1: secondAndThirdOverUnderTable(verticalTable(activeArr).t1Ot1U) },
          { t2: secondAndThirdOverUnderTable(verticalTable(activeArr).t2Ot2U) },
          { f1: fourthAndFifthTable(verticalTable(activeArr).h1) },
          { f2: fourthAndFifthTable(verticalTable(activeArr).h2) },
        ],
        sums: {
          home_wins: verticalTable(activeArr).winDrawLoseTable.w,
          draws: verticalTable(activeArr).winDrawLoseTable.d,
          away_wins: verticalTable(activeArr).winDrawLoseTable.l,
        },
      });
      setTableTeamManual({
        table: [],
        sums: verticalTable(activeArr).verticalTable,
      });
    } else {
      setTableOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableTeamManual({ table: [], sums: {} });
    }
  };

  const setStatType = (activeKey) => {
    setFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setSeasonsInFilters = (season) => {
    if (filters.seasons.indexOf(season) !== -1) {
      const newSeasons = filters.seasons.filter((item) => item !== season);
      setFilters((prev) => ({ ...prev, seasons: newSeasons }));
    } else {
      const newSeasons = [...filters.seasons, season];
      setFilters((prev) => ({ ...prev, seasons: newSeasons }));
    }
  };

  const setSeasonStatType = (activeKey) => {
    setSeasonFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setTournamentsStatType = (activeKey) => {
    setTournamentsFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setTournamentsLeagues = (activeLeague) => {
    const leagues = team?.params?.leagues;
    for (const league of leagues) {
      if (league.name === activeLeague)
        setTournamentsFilters((prev) => ({ ...prev, league_id: league.id }));
    }
    setNameTournament({ name: activeLeague });
  };

  const setTime = (activeKey) => {
    const time = filters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setSeasonTime = (activeKey) => {
    const time = seasonFilters.time;
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setSeasonFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setTournamentsTime = (activeKey) => {
    const time = tournamentsFilters.time;
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setTournamentsFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setPlace = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      place: activeKey,
    }));
  };

  const setCount = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      count: Number(activeKey),
    }));
  };

  const setTeamsRanked = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      against_teams: activeKey,
    }));
  };

  const setCoach = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      under_active_coach: activeKey === "All" ? false : true,
    }));
  };

  const setSeasonPlace = (activeKey) => {
    setSeasonFilters((prev) => ({
      ...prev,
      place: activeKey,
    }));
  };

  const setTournamentsPlace = (activeKey) => {
    setTournamentsFilters((prev) => ({
      ...prev,
      place: activeKey,
    }));
  };

  const setSeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = filters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAllSeasons = (selectAll, key) => {
    if (selectAll) setFilters((prev) => ({ ...prev, seasons: team?.params?.seasons }));
    else setFilters((prev) => ({ ...prev, seasons: [key] }));
  };

  const setAllLeagues = (selectAll, val) => {
    const leagues = filters?.leagues;

    var newLeagues = {};
    for (const [key] of Object.entries(leagues)) {
      newLeagues[key] = selectAll;
    }
    if (val) newLeagues[val] = true;
    setFilters((prev) => ({ ...prev, leagues: newLeagues }));
  };

  const setLeagues = (activeLeague) => {
    const league = filters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1) setFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setSeasonSeasons = (activeSeason) => {
    setSeasonFilters((prev) => ({ ...prev, season_name: activeSeason }));
  };

  const onLoadMoreClick = async (type, page) => {
    if (type === "previous") {
      if (
        page <= Math.ceil(matches.previous.total / matches.previous.per_page)
      ) {
        const previousResponse = await getPreviousFixtures(page, id);
        setMatches((prev) => ({
          ...prev,
          previous: ((previousPrev) => ({
            ...previousPrev,
            page: previousResponse.page,
            results: prev.previous.results.concat(previousResponse.results),
          }))(prev.previous),
        }));
      }
    } else {
      if (page <= Math.ceil(matches.next.total / matches.next.per_page)) {
        const nextResponse = await getNextFixtures(page, id);
        setMatches((prev) => ({
          ...prev,
          next: ((previousNext) => ({
            ...previousNext,
            page: nextResponse.page,
            results: prev.next.results.concat(nextResponse.results),
          }))(prev.next),
        }));
      }
    }
  };

  const onSeasonApplyClick = async () => {
    try {
      setLoadingSeason(true);
      await sleep(500);
      const response = await getSeasonStats(
        seasonFilters.id,
        seasonFilters.season_name,
        seasonFilters.stat_type,
        seasonFilters.place,
        prepareStatsTime(seasonFilters.time)
      );

      setTableSeason(prepareSeasonTable(response.data));
      setSeasonStatus(response.status)
    } catch (error) {
      if (error.response) {
        console.error("Status", error.response.status);
      } else if (error.request) {
        console.error("Error of request:", error.request);
      } else {
        console.error("Simle error is:", error);
      }
    } finally {
      setLoadingSeason(false);
    }
  };

  const onTournamentsApplyClick = async () => {
    try {
      setLoadingTournament(true);
      await sleep(500);

      const response = await getLeagueStats(
        tournamentsFilters.id,
        tournamentsFilters.league_id,
        tournamentsFilters.stat_type,
        tournamentsFilters.place,
        prepareStatsTime(tournamentsFilters.time)
      );
      setTableTournaments(prepareTournamentsTable(response.data));
      setTournamentsStatus(response.status)
    } catch (error) {
      if (error.response) {
        console.error("Status", error.response.status);
      } else if (error.request) {
        console.error("Error of request:", error.request);
      } else {
        console.error("Simle error is:", error);
      }
    } finally {
      setLoadingTournament(false);
    }
  };

  const onFixturesApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoadingStatistics(true);
        await sleep(500);
        const response = await getFixtures(
          filters.stat_type,
          filters.count,
          id,
          filters.place,
          filters.under_active_coach,
          prepareFilters(
            {
              time: filters.time,
              leagues: filters.leagues,
              seasons: filters.seasons,
              against_teams: filters.against_teams,
            },
            currentLeagues
          )
        );

        setTableFixtures(prepareTable(response.data));
        setStatisticsStatus(response.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingStatistics(false);
      }
    } else {
      try {
        setLoadingStatistics(true);
        await sleep(500);

        const response = await getFixtures(
          filters.stat_type,
          filters.count,
          id,
          filters.place,
          filters.under_active_coach,
          prepareFilters(
            {
              time: timerange,
              leagues: filters.leagues,
              seasons: filters.seasons,
              against_teams: filters.against_teams,
            },
            currentLeagues
          )
        );
        setTableFixtures(prepareTable(response.data));
        setStatisticsStatus(response.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingStatistics(false);
      }
    }
  };

  return {
    header,
    leagueId,
    filters,
    seasonFilters,
    tournamentsFilters,
    setLeagues,
    setTime,
    setCount,
    setSeasonTime,
    setTournamentsTime,
    setPlace,
    setTeamsRanked,
    setCoach,
    setSeasonPlace,
    setTournamentsPlace,
    setSeasonsInFilters,
    tableFixtures,
    tableSeason,
    tableTournaments,
    tableOdds,
    team,
    matches,
    timerange,
    tableTeamManual,
    nameTournamet,
    loadingStatistics,
    loadingSeason,
    loadingTournament,
    statisticsStatus,
    seasonStatus,
    tournamentsStatus,
    setTableTotalsManual,
    setStatType,
    setSeasonStatType,
    setTournamentsStatType,
    setSeasons,
    setAllSeasons,
    setAllLeagues,
    setSeasonSeasons,
    setTournamentsLeagues,
    onLoadMoreClick,
    onSeasonApplyClick,
    onTournamentsApplyClick,
    onFixturesApplyClick,
  };
};
