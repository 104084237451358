import { useEffect, useState } from "react"
import { getListLeagues } from "../http"

export const useLeaguesStats = () => {
  const [filters, setFilters] = useState({
    region: {
      'Africa': true,
      'Asia': false,
      'America': false,
      'Europe': false,
      "North America": false,
      "National teams": false
    }
  })
  const [leagues, setLeagues] = useState([])

  const getRegion = (obj) => {
    return Object.entries(obj ).filter(item => item[1] === true)[0][0]
  }

  const onApplyClick = async () => {
    const leagueResponse = await getListLeagues(getRegion(filters.region))
    setLeagues(leagueResponse.results)
  }

  useEffect(() => {
    ;(async function fetch() {
      const res = await getListLeagues(getRegion(filters.region))
      // let arrTotal = []
      // let n = Math.ceil(res.total / res.per_page)
      // for (let i = 1; i <= n; i++) {
      //   const extraRes = await getListLeagues(i, getRegion(filters.region))
      //   arrTotal.push(...extraRes.results)
      // }
      // console.log(arrTotal)
      setLeagues(res.results)
    })()
  }, [])

  const setRegion = (activeKey) => {
    const region = filters.region
    const newRegion = {}
    for (const key of Object.keys(region)) {
      newRegion[key] = key === activeKey ? true : false
    }
    setFilters((prev) => ({ ...prev, region: newRegion }))
  }

  return {
    filters,
    setFilters,
    leagues,
    setRegion,
    onApplyClick,
  }
}
