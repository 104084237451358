import "./TeamBar.scss"
import quesiton from "../../../../assets/svg/question.svg"
import { PROD_URL } from "../../../../http"

export const TeamBar = ({ team, league }) => {
  return (
    <div className="team-bar">
      <img
        src={PROD_URL + team?.team?.image_path || quesiton}
        alt="team"
        className="team-bar__img"
      />
      <p className="team-bar__name">{team?.team?.name}</p>
      <p className="team-bar__coach">Coach: {team?.team?.coach?.name}</p>
      {team?.fixtures &&
        team?.fixtures.map((result, index) => (
          <div
            key={index}
            className={`team-bar-row__item team-bar-row__item-${
              result ? "win" : "lose"
            }`}
          ></div>
        ))}
      <p className="team-bar__coach" style={{ marginLeft: 20 }}>
        {league?.league?.name}
      </p>
    </div>
  )
}
