import { Button } from "../../UI/buttons/Button";
import ReactDOM from "react-dom";
import ok from "../../assets/svg/ok-success.svg";
import "./Success.scss";

export const Success = ({
  show,
  check,
  onButtonClick,
  contentText,
  buttonText,
}) => {
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="custom-modal-wrapper">
      <div className="custom-modal success-modal">
        <div className="success-modal-top">
          {check ? (
            <img
              src={ok}
              alt="ok"
            />
          ) : null}
          <p className="success-modal__text">{contentText}</p>
        </div>
        <Button
          color="blue"
          onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>,
    document.body
  );
};
