import { BillingCard } from "./BillingCard/BillingCard";
import { BillingModal } from "./BillingModal/BillingModal";
import { useState } from "react";
import "./Billing.scss";

export const Billing = () => {
  const [showModal, setShowModal] = useState(false);

  const onCancelClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <BillingModal
        show={showModal}
        onCloseClick={closeModal}
      />
      <BillingCard onCancelClick={onCancelClick} />
    </div>
  );
};
