export const prepareStatsTable = (data) => {
  const sumResult = {
    season: "Total",
    matches: 0,
    avg_total: 0,
    avg_total_home: 0,
    avg_total_away: 0,
    winHome: 0,
    draws: 0,
    awayWins: 0,
  };

  const tableResult = Object.entries(data).map(([key, value]) => {
    sumResult.matches += value.count;
    sumResult.avg_total += value.avg_totals.t;
    sumResult.avg_total_home += value.avg_totals.t1;
    sumResult.avg_total_away += value.avg_totals.t2;
    sumResult.winHome += value.fixture_results.win;
    sumResult.draws += value.fixture_results.draw;
    sumResult.awayWins += value.fixture_results.lose;

    const winPercents =
      value.fixture_results.win +
        value.fixture_results.draw +
        value.fixture_results.lose !==
      0
        ? Math.ceil(
            (value.fixture_results.win /
              (value.fixture_results.win +
                value.fixture_results.draw +
                value.fixture_results.lose)) *
              100
          )
        : 0;

    const drawPercents =
      value.fixture_results.win +
        value.fixture_results.draw +
        value.fixture_results.lose !==
      0
        ? Math.ceil(
            (value.fixture_results.draw /
              (value.fixture_results.win +
                value.fixture_results.draw +
                value.fixture_results.lose)) *
              100
          )
        : 0;

    const losePercents =
      value.fixture_results.win +
        value.fixture_results.draw +
        value.fixture_results.lose !==
      0
        ? Math.ceil(
            (value.fixture_results.lose /
              (value.fixture_results.win +
                value.fixture_results.draw +
                value.fixture_results.lose)) *
              100
          )
        : 0;

    return {
      season: value.name,
      matches: value.count,
      avg_total: value.avg_totals.t.toFixed(2),
      avg_total_home: value.avg_totals.t1.toFixed(2),
      avg_total_away: value.avg_totals.t2.toFixed(2),
      winHome: `${value.fixture_results.win} (${winPercents}%)`,
      draws: `${value.fixture_results.draw} (${drawPercents}%)`,
      awayWins: `${value.fixture_results.lose} (${losePercents}%)`,
    };
  });

  sumResult.avg_total = (sumResult.avg_total / tableResult.length).toFixed(2);
  sumResult.avg_total_home = (
    sumResult.avg_total_home / tableResult.length
  ).toFixed(2);
  sumResult.avg_total_away = (
    sumResult.avg_total_away / tableResult.length
  ).toFixed(2);

  return { table: tableResult, sums: sumResult };
};
