import { useSelector } from "react-redux"
import { Networks } from "../../UI/Networks/Networks"
import { themeSelector } from "../../store/themeSlice"
import logo from "../../assets/svg/statola.svg"
import logoDark from "../../assets/svg/statola-dark.svg"
import "./Footer.scss"
import { Link, useNavigate } from "react-router-dom"

export const Footer = () => {
  const theme = useSelector(themeSelector)
  const navigate = useNavigate()

  const navigateToPage = (link) => {
    navigate(`/${link}`)
  }

  return (
    <div className={`container footer footer_${theme}`}>
      <div className="footer-row">
        <div className="footer-column">
          <img
            src={theme === "light" ? logo : logoDark}
            className="footer-logo"
            alt="statola"
          />
          <Networks />
        </div>
        <div className="footer-column">
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Fixtures
          </p>
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Value bets
          </p>
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Betting glossary
          </p>
          <p
            className="footer-column__text"
            onClick={() => navigate("/trends")}
          >
            Trends
          </p>
        </div>
        <div className="footer-column">
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("leagues")}
            to="/leagues"
          >
            Leagues
          </Link>
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("teams-stats")}
            to="/teams-stats"
          >
            Team stats
          </Link>
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("blog")}
            to="/blog"
          >
            Blog
          </Link>
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("subscription")}
            to="/subscription"
          >
            Subscription
          </Link>
        </div>
        <div className="footer-column">
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("referee-stats")}
            to="/referee-stats"
          >
            Referee stats
          </Link>
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("learning")}
            to="/learning"
          >
            Learning center
          </Link>
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Partners
          </p>
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            API
          </p>
        </div>
        <div className="footer-column">
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Roadmap
          </p>
          <p className="footer-column__text" onClick={() => navigateToPage("")}>
            Odds converter
          </p>
          <Link
            className="footer-column__text"
            // onClick={() => navigateToPage("contact-us")}
            to="/contact-us"
          >
            Contact us
          </Link>
          <span className="footer-column__text footer-column__text_sale">
            Affiliates - 50% recurring lifetime
          </span>
        </div>
      </div>
      <div className="footer-content">
        <p className="footer-text">
          Statoola is a valuable resource for people who are interested in
          sports betting and looking for statistical data on a variety of
          metrics, including goals, corners, cards, throw-ins, goal kicks,
          offsides, tackles, shots, fouls, and more. If you have any questions,
          thoughts, or feedback about the site, feel free to contact us.
        </p>
        <p className="footer-text">
          However, it's important to note that Statoola's content is intended
          for an audience that is 18 years of age or older, and should not be
          considered as advice. Rather, it should be used as a reference tool to
          inform your betting decisions. Please keep in mind that we are not
          responsible for the outcomes of your bets. Additionally, any
          trademarks, brands, images, logos, or names appearing on the website
          belong to their respective owners.
        </p>
        <div className="footer-info">
          <div className="footer-info__item">
            Email: <span>hi@statoola.com</span>
          </div>
          <div className="footer-info__item">
            Gamble Responsibly <span> https://www.begambleaware.org/</span>
          </div>
          <div className="footer-info__item"><Link className="footer-column__text" to="/tos">Terms & Conditions</Link></div>
          <div className="footer-info__item"><Link className="footer-column__text" to="/policy">Privacy Policy</Link></div>
        </div>
      </div>
      <p className="footer-copyright">
        Copyright © 2023 Statoola | All Rights Reserved
      </p>
    </div>
  )
}
