import { HorizontalTableCol } from "./HorizontalTableCol"
import "./HorizontalTable.scss"

export const HorizontalTable = ({ headerItems, data, colsWidth }) => {
  return (
    <>
      {data?.table && (
        <div className="horizontal-table">
          <div
            className="horizontal-table-header"
            style={{ width: colsWidth?.split(" ")[0] }}
          >
            {headerItems.map((item, index) => (
              <p key={index}>
                <span>{item.text}</span>
              </p>
            ))}
          </div>
          <div className="horizontal-table-body">
            {Object.values(data?.table).map((item, index) => {
              return (
                <HorizontalTableCol
                  item={item}
                  key={index}
                  colsWidth={colsWidth}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
