import "./LeaguesCard.scss"
import { PROD_URL } from "../../../../http"

import { Link } from "react-router-dom"

export const LeaguesCard = ({ data }) => {
  return (
    <div className="leagues-card">
      {data?.map(item => (
          <Link className="leagues-card__item" to={`/league/${item.id}`}> 
            <img
              src={PROD_URL + item.image_path}
              alt="country"
              className="leagues-card__img"
            />
            <p className="leagues-card__text">{item.country}, {item.region} - {item.name}</p>
          </Link>
      ))}
     
    </div>
  )
}
