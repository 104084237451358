import { numToPerc } from "../../../utils/numToPerc"

export const prepareTournamentsTable = (data) => {
  const sumResult = {
    season: "All",
    matches: 0,
    avg_total: 0,
    avg_total_home: 0,
    avg_total_away: 0,
    win: 0,
    draw: 0,
    lose: 0,
  }

  const tableResult = Object.entries(data).map(([key, value]) => {
    sumResult.matches += value.count
    sumResult.avg_total += value.totals.t / value.count
    sumResult.avg_total_home += value.totals.t1 / value.count
    sumResult.avg_total_away += value.totals.t2 / value.count
    sumResult.win += value.fixture_results.win
    sumResult.draw += value.fixture_results.draw
    sumResult.lose += value.fixture_results.lose

    return {
      season: value?.name,
      matches: value.count,
      avg_total: (value.totals.t / value.count).toFixed(2),
      avg_total_home: (value.totals.t1 / value.count).toFixed(2),
      avg_total_away: (value.totals.t2 / value.count).toFixed(2),
      win: value.fixture_results.win.toFixed(0),
      draw: value.fixture_results.draw.toFixed(0),
      lose: value.fixture_results.lose.toFixed(0),
    }
  })

  sumResult.avg_total = (sumResult.avg_total / tableResult.length).toFixed(2)
  sumResult.avg_total_home = (
    sumResult.avg_total_home / tableResult.length
  ).toFixed(2)
  sumResult.avg_total_away = (
    sumResult.avg_total_away / tableResult.length
  ).toFixed(2)
  sumResult.win = numToPerc(sumResult.win, sumResult.matches).toFixed(2) + "%"
  sumResult.draw = numToPerc(sumResult.draw, sumResult.matches).toFixed(2) + "%"
  sumResult.lose = numToPerc(sumResult.lose, sumResult.matches).toFixed(2) + "%"

  return { table: tableResult, sums: sumResult }
}
