import "./input.scss";

export const Input = ({ children, placeHolder }) => {
  return (
    <div className="contact-input">
      <label className="contact-input__label">{children}</label>
      <input
        placeholder={placeHolder}
        className="contact-input__input"
        type={"text"}></input>
    </div>
  );
};
