export const prepareStandingsTable = (data) => {
  const tableResult = data
    ?.map((team) => {
      return {
        id: team.position,
        name: team.name,
        matches: team.count,
        win: team.win,
        draw: team.draw,
        lose: team.lose,
        goals: team.scores_for,
        points: team.points,
        promotion: team.promotion,
      }
    })
    .sort((a, b) => a?.id - b?.id)
  return {
    table: tableResult,
  }
}
