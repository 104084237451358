import "./OtherFilter.scss"
import arrow from "../../assets/svg/angle-down.svg"
import { useState } from "react"

export const OtherFilter = ({ filters, setFilter, activeFilter, alone }) => {
  const [isActive, setIsActive] = useState(false)

  const toggleActive = () => {
    setIsActive((prev) => !prev)
  }

  const setInactive = () => {
    setIsActive(false)
  }
  return (
    <div className="other-filter">
      <div className="other-filter-header" onClick={toggleActive}>
        <span>Other</span>
        <img src={arrow} alt="" className="previos-filter__arrow" />
      </div>
      {isActive && (
        <div className="other-filter-body">
          <div className="other-filter-grid">
            {Array.isArray(filters)
              ? filters?.map((key) => (
                  <div
                    className={`other-filter__item ${
                      key === activeFilter ? "other-filter__item_active" : ""
                    }`}
                    onClick={() => setFilter(key)}
                  >
                    {key}
                  </div>
                ))
              : Object.keys(filters).map((filter) => (
                  <div
                    className={`other-filter__item ${
                      filters[filter] ? "other-filter__item_active" : ""
                    }`}
                    onClick={() => setFilter(filter)}
                  >
                    {filter}
                  </div>
                ))}
          </div>
          <button className="other-filter__button" onClick={setInactive}>
            Ok
          </button>
        </div>
      )}
    </div>
  )
}
