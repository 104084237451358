export const h2hResForTables = (arr) => {
    let win1 = 0;
    let draw = 0;
    let win2 = 0;

    let team1Totals = [];
    let team2Totals = [];

    let maxTotal = -1000;
    let minTotal = 1000;

    let sumTeam1Totals = 0;
    let sumTeam2Totals = 0;

    let avg_team1 = 0;
    let avg_team2 = 0;
    let avg_sum = 0;

    let h1 = [];
    let h2 = [];

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].t1.home_id === arr[i].need_id) {
            if (arr[i].t1.home_total > arr[i].t2.away_total) {
                win1 += 1
            } else if (arr[i].t1.home_total < arr[i].t2.away_total) {
                win2 += 1
            } else {
                draw += 1
            }
            sumTeam1Totals += arr[i].t1.home_total
            sumTeam2Totals += arr[i].t2.away_total

            team1Totals.push(arr[i].t1.home_total)
            team2Totals.push(arr[i].t2.away_total)
        } else {
            if (arr[i].t1.home_total > arr[i].t2.away_total) {
                win2 += 1
            } else if (arr[i].t1.home_total < arr[i].t2.away_total) {
                win1 += 1
            } else {
                draw += 1
            }
            sumTeam1Totals += arr[i].t2.away_total
            sumTeam2Totals += arr[i].t1.home_total

            team1Totals.push(arr[i].t2.away_total)
            team2Totals.push(arr[i].t1.home_total)
        }

        let localTotal = arr[i].t

        maxTotal = maxTotal < localTotal ? localTotal : maxTotal;
        minTotal = minTotal > localTotal ? localTotal : minTotal;
    }

    avg_team1 = sumTeam1Totals / arr.length
    avg_team2 = sumTeam2Totals / arr.length
    avg_sum = avg_team1 + avg_team2

    for (let i = 0; i < team1Totals.length; i++) {
        h1.push([team1Totals[i], team2Totals[i]])
        h2.push([team2Totals[i], team1Totals[i]])
    }

    return {
        winDrawWin: {
            win1: `${win1}/${arr.length}`,
            draw: `${draw}/${arr.length}`,
            win2: `${win2}/${arr.length}`
        },
        verticalTable: {
            w: win1,
            d: draw,
            l: win2,
            max: maxTotal,
            min: minTotal,
            avg_t1: avg_team1.toFixed(2),
            avg_t2: avg_team2.toFixed(2),
            avg_sum: avg_sum.toFixed(2)
        },
        t1Ot1U: team1Totals,
        t2Ot2U: team2Totals,
        h1: h1,
        h2: h2,
    }
}