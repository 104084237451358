import "./Minimum.scss";

export const MinimumFilter = ({ activeFilter, setFilter }) => {
  return (
    <div className="minimum-filter ">
      <div className="minimum-filter__item">Minimum</div>
      <input
        className="minimum-filter__input"
        type="number"
        onChange={setFilter}
        value={activeFilter}
        min={0}
      />
      <div className="minimum-filter__item">matches</div>
    </div>
  );
};
