import ReactDOM from "react-dom";
import close from "../../assets/svg/closeModal.svg";
import "./Modal.scss";

export const Modal = ({ show, onCloseClick, title, children }) => {
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="custom-modal-wrapper">
      <div className="custom-modal">
        <img
          src={close}
          alt="close"
          onClick={onCloseClick}
          className="custom-modal__close"
        />
        <p className="custom-modal__title">{title}</p>
        {children}
      </div>
    </div>,
    document.body
  );
};
