import axios from "axios"
import { getCookie, setCookie } from "../utils/cookie"

const API_URL = `${process.env.REACT_APP_HOST_URL}`
export const PROD_URL = `${process.env.REACT_APP_PROD_URL}`

export const $api = axios.create({
  baseURL: API_URL,
})

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getCookie("access_token")}`
  config.headers = {
    "Content-Type": "application/json",
    ...config.headers,
  }
  return config
})

$api.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert("You are not authorized")
    }
    return response
  },
  (error) => {
    if (error.response && error.response.status === 404) {
      return Promise.reject(error.response.status)
    }
    if (error.response.status == "401" && getCookie("access_token")) {
      setCookie("access_token", "")
      window.location.reload()
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  }
)
export default $api
