import React from "react"
import "./Paginator"
import { useDispatch, useSelector } from "react-redux"
import { paginatorSelector, setPage } from "../../store/paginatorSlice"
import { useSearchParams } from "react-router-dom"

export const PaginatorText = ({ children }) => {
  const dispatch = useDispatch()
  const page = useSelector(paginatorSelector)
  const [, setSearchParams] = useSearchParams()

  return (
    <p
      key={children}
      onClick={() => {
        dispatch(setPage(children))
        setSearchParams(`?page=${children}`)
      }}
      className={`paginator-text ${
        page === children ? "paginator-text__chosen" : ""
      }`}
    >
      {children}
    </p>
  )
}
