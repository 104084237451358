import { timestampToDate } from "../../utils/timestampToDate"
import quesiton from "../../assets/svg/question.svg"
import { PROD_URL } from "../../http"
import "./ResultBar.scss"

export const ResultBar = ({ result }) => {
  return (
    <div className="result-bar">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", width: 390 }}>
          <img
            src={PROD_URL + result?.home?.image_path || quesiton}
            alt="result"
            className="result-bar__img"
          />
          <div style={{ marginTop: 20 }}>
            <h2 className="result-bar__name">{result?.home?.name}</h2>
            <p className="result-bar__coach">
              {result?.home?.coach?.name ? "Coach: " + result?.home?.coach?.name : ""}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
            width: 390,
          }}
        >
          <h2 className="result-bar__score">
            {result?.score?.home}:{result?.score?.away}
          </h2>
          <p className="result-bar__coach">{timestampToDate(result?.date)}</p>
          <p className="result-bar__coach">{result?.state}</p> 
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: 390,
            gap: 10,
          }}
        >
          <div style={{ marginTop: 20 }}>
            <h2 className="result-bar__name">{result?.away?.name}</h2>
            <p className="result-bar__coach">
              {" "}
              {result?.away?.coach?.name ? "Coach: " + result?.away?.coach?.name : ""}
            </p>
          </div>
          <img
            src={PROD_URL + result?.away?.image_path || quesiton}
            alt="result"
            className="result-bar__img"
          />
        </div>
      </div>
    </div>
  )
}
