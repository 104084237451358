import $api from "../../../http";

export const getTeam = async (id) => {
  try {
    const response = await $api.get(`/team/header?id=${id}`);
    return response.data;
  } catch (error) {
    if (error === 404) window.location.href = "/404";
    console.error(error);
  }
};

export const getNextFixtures = async (page, id) => {
  return await $api
    .get(
      `/team/fixtures/next?page=${page}&per_page=10&sort=date&order=asc&id=${id}`
    )
    .then((response) => response.data);
};

export const getPreviousFixtures = async (page, id) => {
  return await $api
    .get(
      `/team/fixtures/previous?page=${page}&per_page=10&sort=date&order=asc&id=${id}`
    )
    .then((response) => response.data);
};

export const getTeams = async (offset, limit, filters) => {
  return await $api
    .post(`/team/team/list?offset=${offset}&limit=${limit}`, filters)
    .then((response) => response.data);
};

export const getLeagueStats = async (id, league_id, stat_type, place, time) => {
  return await $api
    .get(
      `/team/stats/league?id=${id}&league_id=${league_id}&stat_type=${stat_type}&place=${place}&time=${time}`
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getFixtures = async (
  stat_type,
  count,
  id,
  place,
  under_active_coach,
  filters
) => {
  return await $api
    .post(
      `/team/fixtures?stat_type=${stat_type}&count=${count}&id=${id}&place=${place}&under_active_coach=${under_active_coach}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getOddsByTotals = async (totals) => {
  return await $api
    .post(`/utils/odds`, totals)
    .then((response) => response.data);
};

export const getSeasonStats = async (
  id,
  season_name,
  stat_type,
  place,
  time
) => {
  return await $api
    .get(
      `/team/stats/season?id=${id}&season=${season_name}&stat_type=${stat_type}&place=${place}&time=${time}`
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};
