import { useState, useEffect } from "react"
import arrow from "../../../assets/svg/header-arrow.svg"
import "./HeaderDropDown.scss"
import { useDispatch } from "react-redux"
import { logout } from "../../../store/authSlice"
import { useNavigate } from "react-router-dom"

export const HeaderDropDown = (isMobile) => {
  const [isMobileScss, setIsMobileScss] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScss(window.innerWidth <= 768)
    }

    window.addEventListener("resize", handleResize)

    // Убираем слушателя событий при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const [active, setActive] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  const onLogoutClick = () => {
    dispatch(logout())
  }

  const onAccountClick = () => {
    navigate("/profile")
  }

  return (
    <div
      className={`header-dropdown ${
        isMobileScss ? "header-dropdown--mobile" : ""
      }`}
    >
      <div className="header-dropdown-top" onClick={toggleActive}>
        My Account{" "}
        <img
          src={arrow}
          className={`header-dropdown-top__img ${
            active ? "header-dropdown-top__img_active" : ""
          }`}
        />
      </div>
      {active && (
        <div className="header-dropdown-body">
          <div className="header-dropdown__item" onClick={onAccountClick}>
            Settings
          </div>
          <div className="header-dropdown__item" onClick={onAccountClick}>
            Favorites
          </div>
          <div className="header-dropdown__item" onClick={onAccountClick}>
            Change timezone
          </div>
          <div className="header-dropdown__item" onClick={onLogoutClick}>
            Log out
          </div>
        </div>
      )}
    </div>
  )
}
