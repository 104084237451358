import searchIcon from "../../assets/svg/search.svg";
import "./Search.scss";

export const Search = () => {
  return (
    <div className="search">
      <input
        type="text"
        className="search__input"
        placeholder="Search team or referee"
      />
      <img
        className="search__icon"
        alt="search"
        src={searchIcon}
      />
    </div>
  );
};
