import google from "../../assets/svg/google.svg";
import "./Google.scss";

export const Google = () => (
  <div className="google">
    <img
      src={google}
      alt="google"
      className="google__icon"
    />
    <p className="google__text">Continue with Google</p>
  </div>
);
