import { useQuery } from "react-query";
import { Button } from "../../../../../UI/buttons/Button";
import { Card } from "../../../UI/Card/Card";
import { Input } from "../../../UI/Input/input";
import { getUser, updateUserData } from "../../../http";
import { useState } from "react";
import { Error } from "../../../../../UI/Error/Error";
import { useDispatch } from "react-redux";
import { logout, setToken } from "../../../../../store/authSlice";
import { DeleteModal } from "./DeleteModal/DeleteModal";
import "./Account.scss";

export const Account = () => {
  const [user, setUser] = useState();
  const [showModal, setShowModal] = useState(false);
  const [keys, setKeys] = useState({
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
  });
  const [email, setEmail] = useState();
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const {} = useQuery("account", getUser, {
    onSuccess: (data) => {
      setUser(data);
      setEmail(data.email);
    },
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const onUserChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onLogoutClick = () => {
    dispatch(logout());
  };

  const saveUserData = async () => {
    var data = { name: user.name };
    if (email !== user.email) {
      data = {
        name: user.name,
        email: email,
      };
    }
    const repponse = await updateUserData(data);
    dispatch(setToken(repponse.token));
  };

  const onKeyChange = (e) => {
    setKeys((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onClickKeySave = async () => {
    if (keys.newPassword === keys.repeatPassword) {
      const response = await updateUserData({ password: keys.newPassword });
    } else {
      setError("The password confirmation does not match.");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };
  return (
    <div className="account">
      <DeleteModal
        user={user}
        show={showModal}
        onCloseClick={() => setShowModal(false)}
      />
      <div className="account-cards">
        <div className="account-cards-left">
          <Card>
            <p>Profile</p>
            <Input
              onChange={onUserChange}
              name={"name"}
              value={user?.name}
              label={"Name"}
              placeHolder={"John"}
            />
            <Input
              name={"email"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              defaultValue={user?.email}
              label={"Email"}
              placeHolder={"John@gmail.com"}
            />
            <Button
              color={"blue"}
              onClick={saveUserData}
              className={"account__button"}>
              Save
            </Button>
          </Card>
          <div className="account-cards-bottom">
            <p className="account-cards__warning">Be carefull</p>
            <p
              className="account-cards__delete"
              onClick={() => setShowModal(true)}>
              Delete account
            </p>
          </div>
        </div>
        <div className="account-cards-right">
          <Card>
            <p>Password</p>
            <Input
              label={"Current Password"}
              name={"currentPassword"}
              onChange={onKeyChange}
              placeHolder={"************"}
            />
            <Input
              label={"New Password"}
              name={"newPassword"}
              onChange={onKeyChange}
            />
            <Input
              label={"Confirm Password"}
              name={"repeatPassword"}
              onChange={onKeyChange}
            />
            <Error error={error} />
            <Button
              color={"blue"}
              className={"account__button"}
              onClick={onClickKeySave}>
              Save
            </Button>
          </Card>
        </div>
      </div>
      <div className="account-cards__logout">
        <p onClick={onLogoutClick}>Log out</p>
      </div>
    </div>
  );
};
