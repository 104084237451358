import React from "react"
import { useStats } from "../../../League/hooks/useStats"
import { LineFilter } from "../../../../components/LineFilter/LineFilter"
import { NumberFilter } from "../../../../components/NumberFilter/NumberFilter"
import { Table } from "../../../../components/Table/Table"
import { StatsCards } from "../../../../components/StatsCards/StatsCards"
import { StatsPercentage } from "../../../../components/StatsPercentage/StatsPercentage"
import { ChartCard } from "../../../../components/ChartCard/ChartCard"
import { AverageStatsCard } from "../../../../components/AverageStatsCard/AverageStatsCard"
import { TimeFilter } from "../../../../components/TimeFilter/TimeFIlter"

export const LeagueTable = ({ league_id }) => {
  const {
    descriptiveStats,
    teams,
    tableTeams,
    filters,
    timerange,
    hasTimerange,
    loadingTeams,
    setStatType,
    setCount,
    setSeasons,
    setTime,
    setPlace,
    onTeamsApplyClick,
  } = useStats(league_id)

  return (
    <div className="league-container">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <LineFilter
          styles={{
            borderRadius: "100px 0 0 100px",
          }}
          filters={teams?.stats?.stats.stats?.main || []}
          setFilter={setStatType}
          activeFilter={filters?.stat_type || teams?.default.stat_type}
          others={teams?.stats?.stats.stats?.other || []}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <NumberFilter
          activeFilter={filters?.count || teams?.default.count}
          filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
          setFilter={setCount}
        />
        <LineFilter
          styles={{
            borderRadius: "100px",
          }}
          filters={filters?.seasons || {}}
          setFilter={setSeasons}
          activeFilter={filters?.seasons || teams?.default.seasons}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <TimeFilter
            activeFilter={filters?.time || teams?.default.time}
            filters={filters?.time || {}}
            setFilter={setTime}
            timerangeValues={timerange}
            withRange={teams?.stat_types_minute?.main?.includes(
              filters?.stat_type
            )}
          />
          <LineFilter
            activeFilter={filters?.place || teams?.default.place}
            filters={["Home", "Away", "All"]}
            setFilter={setPlace}
          />
          <button className="stats__button" onClick={onTeamsApplyClick}>
            Apply
          </button>
        </div>
        
      </div>
      <Table
        headerItems={[
          { text: "№" },
          { text: "Team" },
          { text: "MP" },
          { text: "W" },
          { text: "D" },
          { text: "L" },
          { text: "Goals" },
          { text: "S/C" },
          { text: "PTS" },
          { text: "Last 5" },
          { text: "P/M" },
          { text: "AT" },
          { text: "AIT" },
          { text: "AITO" },
          { text: "BTTS" },
          { text: "CSW" },
          { text: "Over 1.5" },
          { text: "Over 2.5" },
          { text: "Over 3.5" },
        ]}
        colsWidth="28px 200px 50px 50px 50px 50px 60px 60px 60px 80px 60px 60px 60px 70px 70px 70px 70px 70px 70px"
        data={tableTeams}
        summaried
        loading={loadingTeams}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 60,
          width: 850,
        }}
      >
        <StatsCards descriptiveStats={descriptiveStats} />
        <StatsPercentage descriptiveStats={descriptiveStats} />
        <ChartCard descriptiveStats={descriptiveStats} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AverageStatsCard
            descriptiveStats={descriptiveStats}
            side="left"
            first_categ="fouls"
            second_categ="shots"
            third_categ="offsides"
            fourth_categ="throw-ins"
          />
          <AverageStatsCard
            descriptiveStats={descriptiveStats}
            side="right"
            first_categ="ball possessions"
            second_categ="goal kicks"
            third_categ="corners"
            fourth_categ="yellow cards"
          />
        </div>
      </div>
    </div>
  )
}
