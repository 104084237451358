import { useState } from "react";
import { Modal } from "../../../../../../components/Modal/Modal";
import { Button } from "../../../../../../UI/buttons/Button";
import { Input } from "../../../../UI/Input/input";
import { deleteAccount } from "../../../../http";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../../store/authSlice";
import "./DeleteModal.scss";

export const DeleteModal = ({ show, onCloseClick, user }) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ password: "", message: "" });
  const dispatch = useDispatch();

  const onDataChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const close = () => {
    setStep(0);
    onCloseClick();
  };

  const onDelete = async () => {
    const response = await deleteAccount(data);
    setStep((prev) => prev + 1);
    setTimeout(() => {
      dispatch(logout());
    }, 1500);
  };

  const nodesContent = [
    {
      title: "Confirm Account Deletion",
      child: (
        <div className="delete-modal">
          <p
            className="delete-modal__text"
            style={{ marginBottom: "20px" }}>
            Please enter your password to confirm your action. Please leave us a
            message why you are leaving Pirsch.
          </p>
          <Input
            name="password"
            label="Password"
            onChange={onDataChange}
            placeHolder={"************"}
            type={"password"}
          />
          <Input
            name="message"
            onChange={onDataChange}
            placeHolder={"Jonh@gmail.com"}
            type="text"
            label="Message (optional)"
          />
          <Button
            color="blue"
            onClick={() => setStep((prev) => (prev += 1))}>
            Deletion
          </Button>
        </div>
      ),
    },
    {
      title: "Delete your account?",
      child: (
        <div className="delete-modal">
          <p
            className="delete-modal__text"
            style={{ marginBottom: "20px" }}>
            Are you sure you want to delete the account linked to
            <span> {user?.email}</span>?
          </p>
          <p
            className="delete-modal__text"
            style={{ marginBottom: "30px" }}>
            You will not be able to recover your account.
          </p>
          <div className="delete-modal-buttons">
            <Button
              color={"blue"}
              onClick={onDelete}>
              Delete
            </Button>
            <Button
              color={"gray"}
              onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Your Account Is Being Deleted",
      child: (
        <div className="delete-modal">
          <p
            className="delete-modal__text"
            style={{ marginBottom: "0px" }}>
            The request to delete your Statoola account has been confirmed. An
            email will be sent with more information regarding the deletion
            process. We are sorry to see you go.
          </p>
        </div>
      ),
    },
  ];

  return (
    <Modal
      onCloseClick={close}
      title={nodesContent[step].title}
      show={show}>
      {nodesContent[step].child}
    </Modal>
  );
};
