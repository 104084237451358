import "./TeamRankedFilter.scss"

export const TeamRankedFilter = ({
  setFilter,
  styles,
  child,
  activeFilter,
}) => {
  return (
    <div className="team-ranked-filter" style={styles}>
      <div className="team-ranked-filter__item team-ranked-filter__item-disabled">
        Against teams ranked from
      </div>
      <input
        className="team-ranked-filter__input"
        type="number"
        onChange={(e) =>
          setFilter({start: Number(e.target.value), end: activeFilter?.end ? activeFilter?.end : 15})
        }
        defaultValue={0}
        // value={typeof activeFilter === "object" ? activeFilter.start : 0}
      />
      <div className="team-ranked-filter__item team-ranked-filter__item-disabled">
        to
      </div>
      <input
        className="team-ranked-filter__input"
        type="number"
        onChange={(e) =>
          setFilter({start: activeFilter?.start ? activeFilter?.start : 0, end: Number(e.target.value)})
        }
        // value={typeof activeFilter === "object" ? activeFilter.end : 15}
        defaultValue={15}
      />
      <div
        style={child}
        onClick={() => setFilter("all")}
        className={`team-ranked-filter__item ${
          activeFilter === "all" ? "team-ranked-filter__item_active" : ""
        }`}
      >
        All
      </div>
    </div>
  )
}
