import { useEffect, useState } from "react";
import { getDates } from "../../Utils/getDates";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../../store/themeSlice";
import "./DatePicker.scss";

export const DatePicker = ({ activeDate, onSetDate }) => {
  const [siblingDates, setSiblingDates] = useState("");
  const theme = useSelector(themeSelector);

  const handleChangeDate = (e) => {
    if (e.target.value !== "") {
      onSetDate(e.target.value);
      setSiblingDates(getDates(e.target.value));
    }
  };

  useEffect(() => {
    setSiblingDates(getDates(activeDate));
    const handleResize = () => {
      setIsAdaptive({ 768: window.innerWidth <= 768 });
    };

    window.addEventListener('resize', handleResize);

    // Убираем слушателя событий при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isAdaptive, setIsAdaptive] = useState({ 768: window.innerWidth <= 768 });

  const datePickerPrint = (length) => {
    const itemsPerRow = length;
    const dateGroups = [];

    for (let index = 0; index < siblingDates.length; index += itemsPerRow) {
      const groupItems = siblingDates.slice(index, index + itemsPerRow);

      const datePickerItems = groupItems.map((date, subIndex) => (
        <div
          key={index + subIndex}
          className={`date-picker__item ${activeDate === date ? "date-picker__item_active" : ""} date-picker__item_${theme}`}
          onClick={(e) => onSetDate(e.target.innerText)}
        >
          <p className={`date-picker__text date-picker__text_${theme}`}>
            {date}
          </p>
        </div>
      ));

      dateGroups.push(
        <div className="date-picker" key={index / itemsPerRow}>
          {datePickerItems}
        </div>
      );
    }

    return <div className="date-pickers">{dateGroups}</div>;
  };


  return (
    <div className="dates">
      {isAdaptive["768"] ?
        datePickerPrint(2) : datePickerPrint(siblingDates.length)}
      <div className="date-input-wrapper">
        <input
          className={`date-input date-input_${theme}`}
          type="date"
          value={activeDate}
          onChange={handleChangeDate}
        />
      </div>
    </div>
  );
};
