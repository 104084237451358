import { GridItem } from "../../../UI/GridItem/GridItem";
import { useState } from "react";
import { Button } from "../../../../../UI/buttons/Button";
import "./Customization.scss";

export const Customization = () => {
  const [acitveItems, setActiveItems] = useState([]);
  const items = [
    "Goals",
    "Attacks",
    "Corners",
    "Yellow cards",
    "Fouls",
    "Red cards",
    "Shots on target",
    "Offsides",
    "Crosses",
    "Goalkeeper saves",
    "Throw-ins",
    "Goal kicks",
    "Shots",
    "Passes",
    "Cards (Pinnacle, Bet365)",
    "Interceptions",
    "Shots off target",
    "Duels won",
    "Accurate passes",
    "Successful Dribbles",
    "Substitutions",
    "Aerial won",
    "Aerial won %",
    "Tackles",
    "Dangerous Attacks",
    "Shots outside the box",
    "Clearances",
    "Cards (Bwin, Parimatch)",
    "Cards (William Hill)",
    "Penalties",
    "Blocked shots",
    "Ball Possession",
    "Free-kicks",
    "Shots inside the box",
    "Long balls",
  ];
  return (
    <div className="customization">
      <p className="customization__title">
        Here you can configure the statistics panel. Select the items that will
        be displayed on the panel.
      </p>
      <div className="customization-grid">
        {items.map((item, index) => (
          <GridItem key={index}>
            <div className="grid-item-checkbox"></div>
            {item}
          </GridItem>
        ))}
      </div>
      <div className="customization-buttons">
        <Button color="blue">Save changes</Button>
        <Button color="gray">Reset</Button>
      </div>
    </div>
  );
};
