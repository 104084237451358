import "./Tooltip.scss"
import refereeIcon from "../../assets/svg/referee.svg"
import noRefereeIcon from "../../assets/svg/noReferee.svg"
import { useState } from "react"
import { Link } from "react-router-dom"

export const Tooltip = (data) => {
  const [show, setShow] = useState(false)

  return (
    (data?.data?.id !== undefined ? <Link to={`/referee/${data.data.id}`}>
      <div style={{ position: "relative" }}>
        <img
          style={{ cursor: "pointer" }}
          src={refereeIcon}
          alt="referee"
          onMouseEnter={() => {
            setShow((prev) => !prev)
          }}
          onMouseLeave={() => {
            setShow((prev) => !prev)
          }}
        />

        {show && (
          <div className="tooltip">
            <div className="tooltip_item">
              <span className="tooltip_item__key">Referee</span>:{" "}
              {data?.data?.name}
            </div>
            <div className="tooltip_item">
              <span className="tooltip_item__key">Fouls</span>:{" "}
              {data?.data?.stats["fouls"] > 0 ? data?.data?.stats["fouls"].toFixed(2) : 0}
            </div>
            <div className="tooltip_item">
              <span className="tooltip_item__key">Red Cards</span>:{" "}
              {data?.data?.stats["red_cards"] > 0 ? data?.data?.stats["red_cards"].toFixed(2) : 0}
            </div>
            <div className="tooltip_item">
              <span className="tooltip_item__key">Yellow Cards</span>:{" "}
              {data?.data?.stats["yellow_cards"] > 0 ? data?.data?.stats["yellow_cards"].toFixed(2) : 0}
            </div>
          </div>
        )}
      </div>
    </Link> : <img src={noRefereeIcon} alt="referee"/>)
  )
}
 