import React from "react"
import "./MatchesCard.scss"
import { msToFullDate } from "../../utils/msToDate"

import { Link } from "react-router-dom"

export const MatchesCard = ({ type, title, matches, onLoadMoreClick }) => {
  const checkType = (type) => {
    if ( type === "next" ) {
      return "fixture"
    }
    return "game-result"
  }
  return (
    <>
      {matches?.results?.length > 0 && (
        <div className="matches__card">
          <h3 className="matches__card-title">{title}</h3>
          {matches?.results.map((match, index) => (
            <div key={index} className="matches__card-match">
              <Link className="matches__card-teams" to={`/${checkType(type)}/${match.id}`}>{match?.name} {type !== "next" && <span className="matches__card-teams_result">({match.score.home}:{match.score.away})</span>}</Link>
              {match?.date && <span className="matches__card-date">
                {msToFullDate(match?.date)}
              </span>}
            </div>
          ))}
          {matches.page !== matches.max_page && <p
            className="matches__card-loadmore"
            onClick={() => onLoadMoreClick(type, matches.page + 1)}
          >
            Load more
          </p>}
        </div>
      )}
    </>
  )
}
