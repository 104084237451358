import { useEffect, useState } from "react";
import { OtherFilter } from "../OtherFilter/OtherFilter";
import { useWindowResize } from "../../utils/useWindowResize";
import "./LineFilter.scss";

export const LineFilter = ({
  filters,
  setFilter,
  styles,
  child,
  activeFilter,
  setAll,
  others,
  withAll = false,
  isLeagues = false,
  isSeasons = false,
}) => {
  const [firstArr, setFirstArr] = useState([]);
  const [secondArr, setSecondArr] = useState([]);
  const windowWidth = useWindowResize();

  const keys = Array.isArray(filters) ? filters : Object.keys(filters);
  const [allClicked, setAllClicked] = useState(false);

  useEffect(() => {
    if (!isLeagues) {
      if (windowWidth < 1292 && windowWidth > 1092) {
        if (keys.length > 8) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 8)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 8), keys?.length)
          );
        }
      } else if (windowWidth < 1092 && windowWidth > 892) {
        if (keys.length > 7) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 7)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 7), keys?.length)
          );
        }
      } else if (windowWidth < 892 && windowWidth > 692) {
        if (keys.length > 6) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 6)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 6), keys?.length)
          );
        }
      } else if (windowWidth < 692 && windowWidth > 492) {
        if (keys.length > 5) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 5)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 5), keys?.length)
          );
        }
      } else {
        setFirstArr([]);
        setSecondArr([]);
      }
    } else {
      if (windowWidth < 1292 && windowWidth > 1092) {
        if (keys.length > 5) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 5)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 5), keys?.length)
          );
        }
      } else if (windowWidth < 1092 && windowWidth > 892) {
        if (keys.length > 4) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 4)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 4), keys?.length)
          );
        }
      } else if (windowWidth < 892 && windowWidth > 692) {
        if (keys.length > 3) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 3)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 3), keys?.length)
          );
        }
      } else if (windowWidth < 692 && windowWidth > 492) {
        if (keys.length > 2) {
          setFirstArr(keys?.slice(0, keys?.length - (keys?.length - 2)));
          setSecondArr(
            keys?.slice(keys?.length - (keys?.length - 2), keys?.length)
          );
        }
      } else {
        setFirstArr([]);
        setSecondArr([]);
      }
    }
  }, [windowWidth]);

  return (
    <div className="line-filter__container">
      <div style={{ ...styles, display: "flex" }}>
        <div className="line-filter" style={styles}>
          {(filters?.length > 0 || Object.keys(filters || {}).length > 0) &&
            firstArr?.length === 0 && (
              <>
                {Array.isArray(filters)
                  ? filters?.map((key, index) => (
                      <div
                        style={child}
                        key={index}
                        onClick={() => {
                          if (allClicked) {
                            setAllClicked(false);
                            setAll(false, key);
                          } else {
                            setFilter(key);
                          }
                        }}
                        className={`line-filter__item ${
                          isSeasons
                            ? activeFilter?.includes(key) && !allClicked
                              ? "line-filter__item_active"
                              : ""
                            : key === activeFilter && !allClicked
                            ? "line-filter__item_active"
                            : ""
                        }`}
                      >
                        {key}
                      </div>
                    ))
                  : Object.keys(filters).map((key, index) => (
                      <div
                        style={child}
                        key={index}
                        onClick={() => {
                          if (allClicked) {
                            setAllClicked(false);
                            setAll(false, key);
                          } else {
                            setFilter(key);
                          }
                        }}
                        className={`line-filter__item ${
                          (filters[key] || (activeFilter || {})[key]) &&
                          !allClicked
                            ? "line-filter__item_active"
                            : ""
                        }`}
                      >
                        {key}
                      </div>
                    ))}
                {withAll && (
                  <div
                    style={child}
                    onClick={() => {
                      setAll(true);
                      setAllClicked(true);
                    }}
                    className={`line-filter__item ${
                      allClicked ? "line-filter__item_active" : ""
                    }`}
                  >
                    All
                  </div>
                )}
              </>
            )}
        </div>
        {firstArr?.length === 0 &&
          others &&
          (others.length !== 1 ? (
            <OtherFilter
              filters={others}
              setFilter={setFilter}
              activeFilter={activeFilter}
            />
          ) : (
            <div className="other-filter">
              <div
                className={`other-filter-header ${
                  others[0] === activeFilter && !allClicked
                    ? "other-filter-header_active"
                    : ""
                }`}
                onClick={() => {
                  if (allClicked) {
                    setAllClicked(false);
                    setAll(false, others[0]);
                  } else {
                    setFilter(others[0]);
                  }
                }}
              >
                {others[0]}
              </div>
            </div>
          ))}
      </div>

      {firstArr?.length > 0 && (
        <div className="line-filter" style={{ marginBottom: "20px" }}>
          {firstArr?.length > 0 && (
            <>
              {firstArr?.map((key, index) => (
                <div
                  style={child}
                  key={index}
                  onClick={() => {
                    if (allClicked) {
                      setAllClicked(false);
                      setAll(false, key);
                    } else {
                      setFilter(key);
                    }
                  }}
                  className={`line-filter__item ${
                    isSeasons
                      ? activeFilter?.includes(key) && !allClicked
                        ? "line-filter__item_active"
                        : ""
                      : key === activeFilter && !allClicked
                      ? "line-filter__item_active"
                      : ""
                  }`}
                >
                  {key}
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {secondArr?.length > 0 && (
        <div style={{ ...styles, display: "flex" }}>
          <div className="line-filter" style={styles}>
            {secondArr?.length > 0 && (
              <>
                {secondArr?.map((key, index) => (
                  <div
                    style={child}
                    key={index}
                    onClick={() => {
                      if (allClicked) {
                        setAllClicked(false);
                        setAll(false, key);
                      } else {
                        setFilter(key);
                      }
                    }}
                    className={`line-filter__item ${
                      isSeasons
                        ? activeFilter?.includes(key) && !allClicked
                          ? "line-filter__item_active"
                          : ""
                        : key === (activeFilter || "") && !allClicked
                        ? "line-filter__item_active"
                        : ""
                    }`}
                  >
                    {key}
                  </div>
                ))}
                {withAll && (
                  <div
                    style={child}
                    onClick={() => {
                      setAll(true);
                      setAllClicked(true);
                    }}
                    className={`line-filter__item ${
                      activeFilter ? "line-filter__item_active" : ""
                    }`}
                  >
                    All
                  </div>
                )}
              </>
            )}
          </div>
          {others &&
            (others.length !== 1 ? (
              <OtherFilter
                filters={others}
                setFilter={setFilter}
                activeFilter={activeFilter}
              />
            ) : (
              <div className="other-filter">
                <div
                  className={`other-filter-header ${
                    others[0] === activeFilter && !allClicked
                      ? "other-filter-header_active"
                      : ""
                  }`}
                  onClick={() => {
                    if (allClicked) {
                      setAllClicked(false);
                      setAll(false, others[0]);
                    } else {
                      setFilter(others[0]);
                    }
                  }}
                >
                  {others[0]}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
