import { Layout } from "../../layouts/Layout";
import "../../assets/styles/policy.scss";
import { themeSelector } from "../../store/themeSlice";
import { useSelector } from "react-redux";

export const Policy = () => {
  const theme = useSelector(themeSelector);
  const points = [
    {
      title: "Длительность 2-4 недели",
    },
    {
      title: "Работает дизайнер & аналитик & менеджер",
    },
    {
      title: "Постоянный контакт с Заказчиком",
    },
    {
      title: "Обычно требуется 1-2 итерации",
    },
    {
      title: "Результат графический прототип",
    },
    {
      title: "Готовим описание функционала",
    },
  ];
  return (
    <Layout>
      <div className="container">
        <div className={`policy__block policy__block_${theme}`}>
          <h1>Privacy Policy</h1>
          <p>
            This privacy policy is crafted to ensure transparency regarding the
            usage of Personally Identifiable Information (PII) online. PII, as
            defined in US privacy law and information security, pertains to
            information that can identify, contact, or locate an individual,
            either on its own or in conjunction with other data, within a
            specific context. We urge you to carefully review our privacy policy
            to understand how we collect, use, protect, or handle your
            Personally Identifiable Information in accordance with our website.
          </p>
          <h3>
            What personal information do we collect from individuals visiting
            our blog, website, or app?
          </h3>
          <p>
            We do not collect information from visitors to our site, ensuring
            privacy and a seamless browsing experience.
          </p>
          <h3>How can I delete my information?</h3>
          <p>
            You can request the deletion of your information through the Data
            Deletion Request Page. Kindly submit your account email for
            deletion.
          </p>
          <h3>When do we collect information?</h3>
          <p>
            We collect information when you provide details on our site, such as
            during account creation.
          </p>
          <h3>How do we use your information?</h3>
          <p>
            The information collected may be used to personalize your experience
            and provide content and product offerings tailored to your
            interests. Additionally, it helps us enhance our website to better
            serve you.
          </p>
          <h3>How do we protect your information?</h3>
          <p>
            We prioritize the security of your information and employ measures
            such as regular Malware Scanning. We do not use vulnerability
            scanning and/or scanning to PCI standards. Moreover, we do not
            request or store sensitive information like credit card numbers.
          </p>
          <h3>Do we use 'cookies'?</h3>
          <p>
            Yes, we use cookies to enhance your browsing experience and
            understand user preferences for future visits. Cookies help us
            remember and process items in your shopping cart, as well as compile
            aggregate data about site traffic and interaction to improve our
            services.
          </p>
          <h3>Third-party disclosure</h3>
          <p>
            We do not sell, trade, or transfer Personally Identifiable
            Information to outside parties without prior notice. Exceptions
            include trusted third parties who assist in website operation,
            business conduct, or serving users, provided they agree to maintain
            confidentiality. We may also release information when deemed
            appropriate to comply with the law or protect rights, property, or
            safety.
          </p>
          <h3>Third-party links</h3>
          <p>
            Occasionally, we may include third-party products or services on our
            website. These third-party sites have separate and independent
            privacy policies. Therefore, we hold no responsibility for their
            content or activities.
          </p>
          <h3>Google</h3>
          <p>
            We utilize Google AdSense Advertising on our website. Google, as a
            third-party vendor, utilizes cookies to serve ads based on user
            visits. Users may opt out of personalized advertising through Ads
            Settings or{" "}
            <a href="https://www.aboutads.info/">www.aboutads.info</a>.
          </p>
          <h3>California Online Privacy Protection Act (CalOPPA)</h3>
          <p>
            In compliance with CalOPPA, we allow anonymous site visits and
            prominently display our Privacy Policy link for easy access.
          </p>
          <h3>Changes to Privacy Policy</h3>
          <p>
            Any changes to our Privacy Policy will be posted on our Privacy
            Policy Page.
          </p>
          <h3>Contacting Us</h3>
          <p>
            For inquiries regarding this privacy policy, please reach out to us
            at: <br />
            KRONX LLC <br />
            30 North Gould Street, Sheridan, WY 82801, US <br />
          </p>
          <p>
            <a href="mailto:help@statoola.com">help@statoola.com</a>
          </p>
          <h3>California Consumer Privacy Act (“CCPA”)</h3>
          <p>
            Under CCPA, Californian residents retain rights regarding data sales
            for advertising and marketing. To adjust preferences, visit our
            preference portal. We utilize a third-party for site monetization.
            Review their privacy and cookie policy here.
          </p>
        </div>
      </div>
    </Layout>
  );
};
