import { Layout } from "../../layouts/Layout";
import { Google } from "../../UI/Google/Google";
import { Button } from "../../UI/buttons/Button";
import { useState } from "react";
import { auth } from "./http";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import "../../assets/styles/login.scss";

export const Login = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onSignupClick = () => {
    navigate("/registration");
  };

  const dispatch = useDispatch();

  const onInputChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onLoginClick = async () => {
    try {
      const response = await auth(user);
      dispatch(setToken(response.access_token));
      navigate("/profile");
    } catch (e) {
      setError(
        "Whoops! Email and/or password don't match with any of our records."
      );
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };
  return (
    <Layout>
      <div className="container login-content">
        <div className="login-form">
          <h1 className="login__title">Welcome back</h1>
          <Google />
          <span className="with-email">or Sign up with email</span>
          <div className="auth-input">
            <label className="auth-input__label">Email</label>
            <input
              name="username"
              onChange={onInputChange}
              type="email"
              placeholder=""
              className="auth-input__input"
            />
          </div>
          <div className="auth-input">
            <label className="auth-input__label">Password</label>
            <input
              name="password"
              onChange={onInputChange}
              type="password"
              placeholder=""
              className={`auth-input__input ${
                error ? "auth-input__input_error" : ""
              }`}
            />
          </div>
          {error && (
            <p className="login__error">
              Whoops! Something went wrong. The password confirmation does not
              match.
            </p>
          )}

          <Button
            onClick={onLoginClick}
            color="blue"
            className="login__button">
            Log in
          </Button>
          <p className="login-form__account">
            I don’t have an account <span onClick={onSignupClick}>Sign up</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};
