import star from "../../../../assets/svg/star.svg";
import arrow from "../../../../assets/svg/arrow.svg";
import xbet from "../../../../assets/bookies/1xbet.svg";
import bet from "../../../../assets/bookies/bet365.svg";
import eye from "../../../../assets/svg/eye.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../../store/themeSlice";
import { convertDate } from "../../Utils/convertDate";
import "./League.scss";
import { Link, useNavigate } from "react-router-dom";
import { PROD_URL } from "../../../../http";

export const League = ({ name, matches }) => {
  const [active, setAcitve] = useState(false);
  const theme = useSelector(themeSelector);

  const navigate = useNavigate();

  // const renderOdds = (odds) => {
  //   const oddsObj = {};
  //   const bookie = odds["1xbet"].length ? "1xbet" : "bet365";
  //   const isRender = odds["1xbet"].length || odds["bet365"].length;

  //   odds[bookie].forEach((odd) => {
  //     if (Object.keys(odd).length > 0) {
  //       const pair = Object.entries(odd)[0];
  //       oddsObj[pair[0]] = pair[1];
  //     }
  //   });

  //   return (
  //     <>
  //       {isRender ? (
  //         <div className="league-odds">
  //           <img src={bookie === "1xbet" ? xbet : bet} alt={bookie} />
  //           <p className="league-odds__item">{oddsObj.home}</p>
  //           <p className="league-odds__item">{oddsObj.draw}</p>
  //           <p className="league-odds__item">{oddsObj.away}</p>
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };

  const renderStatus = (state, id) => {
    if (state === "Pre-match") {
      return <p className="league-state league-state_pre">Pre-match</p>;
    }

    if (state === "Ended") {
      return (
        <Link style={{ textDecoration: "none" }} to={`/game-result/${id}`}>
          <p className="league-state league-state_fin">finished</p>
        </Link>
      );
    }

    return <p className="league-state league-state_live">Live</p>;
  };

  const toggleActive = () => {
    setAcitve((prev) => !prev);
  };

  useEffect(() => {
    setAcitve(false);
  }, [matches]);

  return (
    <div className={`league ${active ? "league_active" : ""} league_${theme}`}>
      <div
        className={`league-header ${
          active ? "league-header_active" : ""
        } league-header_${theme}`}
        onClick={toggleActive}
      >
        <div className="league-name">
          <img
            src={star}
            alt="add/remove to/from favorite"
            className="league__favorite"
          />
          <img
            src={PROD_URL + matches[0].league.image_path}
            alt="country"
            className="league__icon"
          />
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/league/${matches[0].league.id}`}
          >
            <p className="league__title">{name}</p>
          </Link>
        </div>
        <img
          src={arrow}
          alt="arrow"
          className={`league__arrow ${active ? "league__arrow_active" : ""}`}
        />
      </div>
      <div className={`league-body ${active ? "league-body_active" : ""}`}>
        {matches?.map((match, index) => (
          <div key={index} className={`league-match league-match_${theme}`}>
            <div className="league-match-name">
              <img src={star} alt="" className={`legue-match__favorite`} />
              <p className="league-match__time">{convertDate(match.date)}</p>
              <div className={`league-match__name league-match__name_${theme}`}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/team/${match.teams.home}`}
                >
                  <span>{match.name.split("vs")[0]}</span>
                </Link>

                <span>vs</span>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/team/${match.teams.away}`}
                >
                  <span>{match.name.split("vs")[1]}</span>
                </Link>
              </div>
              <p className="league-score">{`${match.score.home} : ${match.score.away}`}</p>
              {/* {match?.odds && renderOdds(match.odds)} */}
              {match.referee !== null ? (<Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/referee/${match.referee.id}`}
              >
                <p className="league-match__referee">
                  {match.referee?.name ? match.referee.name : ""}
                </p>
              </Link>) : (<p className="league-match__referee">NULL</p>)}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {renderStatus(match.state, match.id)}
              <Link
                style={{ textDecoration: "none" }}
                to={`/fixture/${match.id}`}
              >
                <img src={eye} alt="eye" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
