import { msToHoursMins } from "./msToHoursMins";

export const prepareTable = (data, team_name) => {
  const redCards = [];
  const sumsResult = {
    w: 0,
    d: 0,
    l: 0,
    avg_diff: 0,
    max: 0,
    min: 0,
    max_t1: 0,
    max_t2: 0,
    min_t1: 0,
    min_t2: 0,
    avg_t1: 0,
    avg_t2: 0,
    avg_t: 0,
  };
  const tableResult = data?.map((value) => {
    redCards.push({ home: value.home.redcards, away: value.away.redcards });
    if (value.home.name === team_name) {
      if (value.score.home > value.score.away) sumsResult.w += 1;
      else if (value.score.home < value.score.away) sumsResult.l += 1;
      else sumsResult.d += 1;
      if (sumsResult.max < value.score.home) sumsResult.max = value.score.home;
      if (sumsResult.min > value.score.home) sumsResult.min = value.score.home;
      if (sumsResult.max_t1 < value.totals.t1)
        sumsResult.max_t1 = value.totals.t1;
      if (sumsResult.max_t2 < value.totals.t2)
        sumsResult.max_t2 = value.totals.t2;
      if (sumsResult.min_t1 > value.totals.t1)
        sumsResult.min_t1 = value.totals.t1;
      if (sumsResult.min_t2 > value.totals.t2)
        sumsResult.min_t2 = value.totals.t2;
      sumsResult.avg_t1 += value.totals.t1;
      sumsResult.avg_t2 += value.totals.t2;
      sumsResult.avg_t += value.totals.t;
    } else {
      if (value.score.away > value.score.home) sumsResult.w += 1;
      else if (value.score.away < value.score.home) sumsResult.l += 1;
      else sumsResult.d += 1;
      if (sumsResult.max < value.score.away) sumsResult.max = value.score.away;
      if (sumsResult.min > value.score.away) sumsResult.min = value.score.away;
      if (sumsResult.max_t1 < value.totals.t2)
        sumsResult.max_t1 = value.totals.t2;
      if (sumsResult.max_t2 < value.totals.t1)
        sumsResult.max_t2 = value.totals.t1;
      if (sumsResult.min_t1 > value.totals.t2)
        sumsResult.min_t1 = value.totals.t2;
      if (sumsResult.min_t2 > value.totals.t1)
        sumsResult.min_t2 = value.totals.t1;
      sumsResult.avg_t1 += value.totals.t1;
      sumsResult.avg_t2 += value.totals.t2;
      sumsResult.avg_t += value.totals.t;
    }

    sumsResult.avg_diff += Math.abs(value.score.home - value.score.away);
    return {
      season: value.season.name,
      date: msToHoursMins(value.date),
      home: value.home.name,
      t1: value.totals.t1,
      t2: value.totals.t2,
      away: value.away.name,
      t: value.totals.t,
      ref: value.referee,
      score: value.score.home + ":" + value.score.away,
      home_stats: {
        home_id: value.home.id,
        home_total: value.totals.t1,
        coach: { id: value.home.coach?.id, name: value.home.coach?.name },
      },
      away_stats: {
        away_id: value.away.id,
        away_total: value.totals.t2,
        coach: { id: value.away.coach?.id, name: value.away.coach?.name },
      },
    };
  });
  sumsResult.avg_diff = (sumsResult.avg_diff / data.length).toFixed(2);
  sumsResult.avg_t1 = (sumsResult.avg_t1 / data.length).toFixed(2);
  sumsResult.avg_t2 = (sumsResult.avg_t2 / data.length).toFixed(2);
  sumsResult.avg_t = (sumsResult.avg_t / data.length).toFixed(2);
  return { table: tableResult, sums: sumsResult, redCards };
};
