import { Layout } from "../../layouts/Layout";
import { TeamBar } from "./components/TeamBar/TeamBar";
import "../../assets/styles/team.scss";
import { useTeamStats } from "./hooks/useTeamStats";
import { useLocation } from "react-router-dom";
import { LineFilter } from "../../components/LineFilter/LineFilter";
import { NumberFilter } from "../../components/NumberFilter/NumberFilter";
import { MatchesCard } from "../../components/MatchesCard/MatchesCard";
import { LeagueTable } from "./components/LeagueTable/LeagueTable";
import { SelectorFilter } from "../../components/SelectorFilter/SelectorFilter";
import { Table } from "../../components/Table/Table";
import { TeamRankedFilter } from "../../components/TeamRankedFilter/TeamRankedFilter";
import { HorizontalTable } from "../../components/HorizontalTable/HorizontalTable";
import { TimeFilter } from "../../components/TimeFilter/TimeFIlter";

export const Team = () => {
  const id =
    +useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const {
    header,
    leagueId,
    filters,
    seasonFilters,
    tournamentsFilters,
    setLeagues,
    setCount,
    setTime,
    setSeasonTime,
    setTournamentsTime,
    setCoach,
    setTeamsRanked,
    setSeasonPlace,
    setTournamentsPlace,
    setSeasonsInFilters,
    tableFixtures,
    tableSeason,
    tableTournaments,
    tableOdds,
    team,
    matches,
    timerange,
    tableTeamManual,
    nameTournamet,
    loadingStatistics,
    loadingSeason,
    loadingTournament,
    statisticsStatus,
    seasonStatus,
    tournamentsStatus,
    setStatType,
    setTableTotalsManual,
    setSeasonStatType,
    setTournamentsStatType,
    setSeasons,
    setAllSeasons,
    setAllLeagues,
    setSeasonSeasons,
    setTournamentsLeagues,
    onLoadMoreClick,
    onSeasonApplyClick,
    onTournamentsApplyClick,
    onFixturesApplyClick,
  } = useTeamStats(id);

  return (
    <Layout>
      <div className="container">
        <TeamBar team={team} league={header} />
        <div className="team">
          <p className="title team__title">
            Statistics of the team and outcomes of recent games
          </p>
          <div style={{ display: "flex", flexDirection: "column", gap: 21 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                filters={team?.params?.fixtures?.stats?.stats?.main || []}
                setFilter={setStatType}
                activeFilter={
                  filters?.stat_type ||
                  team?.params?.fixtures?.default?.stat_type
                }
                others={team?.params?.fixtures?.stats?.stats?.other || []}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={team?.params?.seasons || []}
                setFilter={setSeasonsInFilters}
                activeFilter={
                  filters?.seasons || team?.params?.fixtures?.default?.seasons
                }
                setAll={setAllSeasons}
                withAll
                isSeasons
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <LineFilter
                  activeFilter={filters?.leagues || {}}
                  filters={filters?.leagues || {}}
                  setFilter={setLeagues}
                  isLeagues
                  setAll={setAllLeagues}
                  withAll
                />
                <NumberFilter
                  activeFilter={
                    filters?.count || team?.params?.fixtures?.default?.count
                  }
                  filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                  setFilter={setCount}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <TimeFilter
                activeFilter={
                  filters?.time || team?.params?.fixtures?.default?.time
                }
                filters={filters?.time || {}}
                setFilter={setTime}
                timerangeValues={timerange}
                withRange={
                  team?.params?.fixtures?.stats_minute?.stats?.main?.includes(
                    filters?.stat_type
                  ) ||
                  team?.params?.fixtures?.stats_minute?.stats?.other?.includes(
                    filters?.stat_type
                  )
                }
              />
              <LineFilter
                activeFilter={
                  (filters?.under_active_coach
                    ? "Under current manager"
                    : "All") ||
                  (team?.params?.fixtures?.default?.under_active_coach
                    ? "Under current manager"
                    : "All")
                }
                filters={["Under current manager", "All"]}
                setFilter={setCoach}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <TeamRankedFilter
                activeFilter={
                  filters?.against_teams ||
                  team?.params?.fixtures?.default?.against_teams
                }
                filters={filters?.against_teams || {}}
                setFilter={setTeamsRanked}
              />
              <button className="stats__button" onClick={onFixturesApplyClick}>
                Apply
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              gap: 20,
              marginTop: 40,
              marginBottom: 60,
            }}
          >
            {!tableFixtures?.table.length && !loadingStatistics ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : statisticsStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <Table
                  headerItems={[
                    { text: "W" },
                    { text: "D" },
                    { text: "L" },
                    { text: "Avg. difference" },
                    { text: "Max" },
                    { text: "Min" },
                    { text: "Max individual total" },
                    { text: "Max total of the opponent" },
                    { text: "Min individual total" },
                    { text: "Min total of the opponent" },
                    { text: "Avg. individual total" },
                    { text: "Avg. total of the opponent" },
                    { text: "Avg. Total" },
                  ]}
                  colsWidth="28px 28px 28px 90px 40px 40px 80px 80px 110px 80px 80px 100px 90px"
                  data={tableTeamManual}
                  summaried
                />
                <Table
                  headerItems={[
                    { text: "Season" },
                    { text: "Date" },
                    { text: "Home" },
                    { text: "T 1" },
                    { text: "T 2" },
                    { text: "Away" },
                    { text: "T" },
                    { text: "R" },
                    { text: "Score" },
                  ]}
                  colsWidth="32px 190px 70px 180px 40px 40px 180px 40px 40px 60px"
                  data={{ table: tableFixtures?.table }}
                  redCards={tableFixtures?.redCards}
                  showWinner
                  withCheckbox
                  setTableTotals={setTableTotalsManual}
                  teamName={team?.team.name}
                  teamId={team?.team.id}
                  loading={loadingStatistics}
                />
                <div style={{ marginTop: 60, marginBottom: 20 }}>
                  <h2
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      marginBottom: 20,
                    }}
                  >
                    Betting tables
                  </h2>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <HorizontalTable
                      headerItems={[
                        { text: "Win" },
                        { text: "Draw" },
                        { text: "Lost" },
                      ]}
                      colsWidth="120px 70px"
                      data={{ table: tableOdds?.sums }}
                    />
                  </div>
                  <div style={{ display: "flex", gap: 20 }}>
                    <Table
                      headerItems={[
                        { text: "" },
                        { text: "Over" },
                        { text: "Under" },
                      ]}
                      colsWidth="40px 61px 61px"
                      data={{ table: tableOdds?.table[0]["t"] }}
                      showGreen
                    />
                    <Table
                      headerItems={[
                        { text: "" },
                        { text: "T1O" },
                        { text: "T1U" },
                      ]}
                      colsWidth="40px 61px 61px"
                      data={{ table: tableOdds?.table[1]["t1"] }}
                      showGreen
                    />
                    <Table
                      headerItems={[
                        { text: "" },
                        { text: "T2O" },
                        { text: "T2U" },
                      ]}
                      colsWidth="40px 61px 61px"
                      data={{ table: tableOdds?.table[2]["t2"] }}
                      showGreen
                    />
                    <Table
                      headerItems={[{ text: "" }, { text: "H1" }]}
                      colsWidth="40px 61px"
                      data={{ table: tableOdds?.table[3]["f1"] }}
                      showGreen
                    />
                    <Table
                      headerItems={[{ text: "" }, { text: "H2" }]}
                      colsWidth="40px 61px"
                      data={{ table: tableOdds?.table[4]["f2"] }}
                      showGreen
                    />
                  </div>
                </div>
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
              <MatchesCard
                title="Next mathces"
                type="next"
                matches={matches?.next}
                onLoadMoreClick={onLoadMoreClick}
              />
              <MatchesCard
                title="Last matches"
                type="previous"
                matches={matches?.previous}
                onLoadMoreClick={onLoadMoreClick}
              />
            </div>
          </div>

          <LeagueTable league_id={leagueId} />
          <div style={{ marginBottom: 43 }}>
            <h2 style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
              Stats by seasons
            </h2>
            <SelectorFilter
              filters={team?.params?.seasons || []}
              setFilter={setSeasonSeasons}
              activeFilter={
                seasonFilters?.season_name ||
                team?.params?.fixtures?.default?.seasons[0]
              }
            />
            <div
              style={{
                display: "flex",
                gap: "20px",
                margin: "22px 0",
                flexWrap: "wrap",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={team?.params?.stats?.stat_types || []}
                setFilter={setSeasonStatType}
                activeFilter={
                  seasonFilters?.stat_type ||
                  team?.params?.stats?.default?.stat_type
                }
              />
              <LineFilter
                activeFilter={
                  seasonFilters?.place || team?.params?.stats?.default?.place
                }
                filters={["Home", "Away", "All"]}
                setFilter={setSeasonPlace}
              />
            </div>
            <div style={{ display: "flex", gap: 20, marginBottom: 60 }}>
              <TimeFilter
                activeFilter={
                  seasonFilters?.time || team?.params?.stats?.default?.time
                }
                filters={seasonFilters?.time || {}}
                setFilter={setSeasonTime}
              />
              <button className="stats__button" onClick={onSeasonApplyClick}>
                Apply
              </button>
            </div>
            {!tableSeason?.table.length && !loadingSeason ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : seasonStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <Table
              headerItems={[
                { text: "Season" },
                { text: "Matches" },
                { text: "Avg. total" },
                { text: "Avg. individual total" },
                { text: "Avg. total of the opponent" },
                { text: "W" },
                { text: "D" },
                { text: "L" },
              ]}
              colsWidth="300px 140px 140px 140px 140px 140px 140px 149px"
              data={tableSeason}
              summaried
              loading={loadingSeason}
            />
            )}
            
          </div>
          <div>
            <h2 style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
              Stats by tournaments
            </h2>
            <SelectorFilter
              activeFilter={
                nameTournamet?.name || team?.params?.leagues[0]?.name
              }
              filters={team?.leagues || []}
              setFilter={setTournamentsLeagues}
            />
            <div
              style={{
                display: "flex",
                gap: "20px",
                margin: "22px 0",
                flexWrap: "wrap",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={team?.params?.stats?.stat_types || []}
                setFilter={setTournamentsStatType}
                activeFilter={
                  tournamentsFilters?.stat_type ||
                  team?.params?.stats?.default?.stat_type
                }
              />
              <LineFilter
                activeFilter={
                  tournamentsFilters?.place ||
                  team?.params?.stats?.default?.place
                }
                filters={["Home", "Away", "All"]}
                setFilter={setTournamentsPlace}
              />
            </div>
            <div style={{ display: "flex", gap: 20, marginBottom: 60 }}>
              <TimeFilter
                activeFilter={
                  tournamentsFilters?.time || team?.params?.stats?.default?.time
                }
                filters={tournamentsFilters?.time || {}}
                setFilter={setTournamentsTime}
              />
              <button
                className="stats__button"
                onClick={onTournamentsApplyClick}
              >
                Apply
              </button>
            </div>
            {!tableSeason?.table.length && !loadingTournament ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : tournamentsStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <Table
              headerItems={[
                { text: "Tournaments" },
                { text: "Matches" },
                { text: "Avg. total" },
                { text: "Avg. individual total" },
                { text: "Avg. total of the opponent" },
                { text: "W" },
                { text: "D" },
                { text: "L" },
              ]}
              colsWidth="300px 140px 140px 140px 140px 140px 140px 149px"
              data={tableTournaments}
              summaried
              loading={loadingTournament}
            />
            )}
            
          </div>
        </div>
      </div>
    </Layout>
  );
};
