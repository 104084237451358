import React from "react"
import { PaginatorText } from "./PaginatorText"
import "./Paginator.scss"

export const Paginator = ({ pages }) => {
  return (
    <div className="paginator">
      {pages.map((name) => (
        <PaginatorText key={name}>{name}</PaginatorText>
      ))}
    </div>
  )
}
