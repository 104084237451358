import "./SupportInput.scss";

export const SupportInput = (props) => {
  return (
    <div className="support-input">
      <label className="support-input__label">{props.label}</label>
      <input
        className="support-input__input"
        type="text"
        placeholder="John"
        onChange={e => props.setSubject(e.target.value)}
      />
    </div>
  );
};
