import { Layout } from "../../layouts/Layout";
import { Google } from "../../UI/Google/Google";
import { Button } from "../../UI/buttons/Button";
import { Modal } from "../../components/Modal/Modal";
import { registration } from "./http";
import { useState } from "react";
import "../../assets/styles/registration.scss";
import { useNavigate } from "react-router-dom";

export const Registration = () => {
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onLoginClick = () => {
    navigate("/login");
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const onInputChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onRegisterClick = async () => {
    if (user.password === user.repeatPassword) {
      try {
        const response = await registration({
          name: user.name,
          password: user.password,
          email: user.email,
        });
        setShowModal(true);
      } catch (e) {}
    } else {
      setError(
        "Whoops! Something went wrong. The password confirmation does not match."
      );

      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };
  return (
    <Layout>
      <Modal
        title={"Verify your email"}
        show={showModal}
        onCloseClick={hideModal}>
        <div className="registration-modal">
          <p className="registration-modal__inbox">Please check your inbox</p>
          <p className="registration-modal__email">
            We sent a verification email to <span>{user?.email}</span> Click the
            link inside to get started!
          </p>
          <p className="registration-modal__resend">
            Email didn’t arrive? <span>Resend</span>
          </p>
          <Button
            color="blue"
            onClick={hideModal}>
            Go back to sign up
          </Button>
        </div>
      </Modal>
      <div className="container registration-content">
        <div className="registration-form">
          <h1 className="registration__title">Hi, let’s get familiar</h1>
          <Google />
          <span className="with-email">or Sign up with email</span>

          <div className="auth-input">
            <label className="auth-input__label">Name</label>
            <input
              onChange={onInputChange}
              name="name"
              type="text"
              placeholder=""
              className="auth-input__input"
            />
          </div>
          <div className="auth-input">
            <label className="auth-input__label">Email</label>
            <input
              onChange={onInputChange}
              type="email"
              name="email"
              placeholder=""
              className="auth-input__input"
            />
          </div>
          <div className="auth-input">
            <label className="auth-input__label">Password</label>
            <input
              onChange={onInputChange}
              name="password"
              type="password"
              placeholder=""
              className={`auth-input__input ${
                error ? "auth-input__input_error" : ""
              }`}
            />
          </div>
          {error && (
            <p className="registration__error">
              Whoops! Something went wrong. The password confirmation does not
              match.
            </p>
          )}
          <div className="auth-input">
            <label className="auth-input__label">Repeat Password</label>
            <input
              onChange={onInputChange}
              name="repeatPassword"
              type="password"
              placeholder=""
              className="auth-input__input"
            />
          </div>
          <Button
            onClick={onRegisterClick}
            color="blue"
            className="registration__button">
            Sign up
          </Button>
          <p className="registration-form__account">
            Already have an account? <span onClick={onLoginClick}>Log in</span>
          </p>
          <p className="registration-form__policy">
            By signing up you accept the contetn of <span>Regulations</span> and
            <span> Privacy Policy</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};
