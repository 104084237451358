export const prepareFiltersStats = (params, seasons, leagues, page) => {
  let newTime = ""
  let newStatType = ""
  const finalLeagues = [];

  Object.entries(leagues).forEach(([key, value]) => {
    if (value) {
      finalLeagues.push(Number(key))
    }
  })

  Object.entries(params.time).forEach(([key, value]) => {
    if (value) {
      newTime = key
    }
  })

  Object.entries(params.stat_type).forEach(([key, value]) => {
    if (value) {
      newStatType = key
    }
  })

  return {
    time: newTime,
    stat_type: newStatType,
    count: params.count,
    page: page ? page : params.page,
    per_page: params.per_page,
    sort: params.sort,
    order: params.order,
    min_count: params.min_count,
    leagues: finalLeagues,
    seasons: seasons,
  };
};
