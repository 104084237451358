import "./RefereesCard.scss";
import { TooltipReferee } from "../../../../components/TooltipReferee/TooltipReferee";
import { Link } from "react-router-dom"

export const RefereesCard = ({ data, onLoadMoreClick }) => {
  const maxPage = Math.ceil(data.total / data.per_page)
  return (
    <div className="referees-card">
      <h2 className="referees-card__subtitle">All referees</h2>
      <div className="referees-card__pointers">
        <TooltipReferee data={{ text: "M", desc: "Matches" }} />
        <TooltipReferee data={{ text: "YC", desc: "YellowCards" }} />
      </div>
      {data?.results?.map((item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div onClick={() => window.location.reload()}><Link style={{textDecoration: "none"}} to={`/referee/${item.id}`}><p className="referees-card__name">{item.name}</p></Link></div>
          <p className="referees-card__params">{item.count}</p>
          <p className="referees-card__params">{(item.stats.yellow_cards.value / item.count).toFixed(2)}</p>
        </div>
      ))}
      <p
        className="matches__card-loadmore"
        onClick={async () => await onLoadMoreClick(data.page + 1, maxPage)}
      >
        Load more
      </p>
    </div>
  );
};
