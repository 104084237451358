import { Networks } from "../../UI/Networks/Networks"
import { Search } from "../../modules/Search"
import { Button } from "../../UI/buttons/Button"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { switchTheme, themeSelector } from "../../store/themeSlice"
import { useNavigate } from "react-router-dom"
import { authSelector } from "../../store/authSlice"
import { HeaderDropDown } from "./HeaderDropDown/HeaderDropDown"
import light from "../../assets/svg/light.svg"
import lightDark from "../../assets/svg/light-dark.svg"
import logo from "../../assets/svg/statola.svg"
import logoDark from "../../assets/svg/statola-dark.svg"
import "./Header.scss"

export const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener("resize", handleResize)

    // Убираем слушателя событий при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const navigate = useNavigate()
  const theme = useSelector(themeSelector)
  const isAuth = useSelector(authSelector)
  const dispatch = useDispatch()

  const toggleTheme = () => {
    dispatch(switchTheme())
  }

  const navigateToMain = () => {
    navigate("/")
  }

  const navigateToLogin = () => {
    navigate("/login")
  }

  const navigateToReg = () => {
    navigate("/registration")
  }

  return (
    <div className="container header">
      <div className="header-top">
        <img
          src={theme === "light" ? logo : logoDark}
          alt="statola"
          onClick={navigateToMain}
        />
        <Search />
        <div className="header-icons">
          <Networks />
          <img
            onClick={toggleTheme}
            src={theme === "light" ? light : lightDark}
            alt="switch theme"
          />
        </div>
      </div>
      <div className="header-bottom">
        <nav className="header-nav">
          <ul className="nav-list">
            <li className="nav-list__link">Leagues</li>
            <li className="nav-list__link">Products</li>
            <li className="nav-list__link">Blog</li>
            <li className="nav-list__link">Subscription</li>
            <li className="nav-list__link">Resources</li>
            <li className="nav-list__link">Fixtures</li>
          </ul>
        </nav>
        {!isMobile ? (
          <>
            {isAuth ? (
              <HeaderDropDown />
            ) : (
              <div className="header-buttons">
                <Button color="blue" onClick={navigateToLogin}>
                  Log in
                </Button>
                <Button color="black" onClick={navigateToReg}>
                  Sign up
                </Button>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
      {isMobile ? (
        <>
          {isAuth ? (
            <HeaderDropDown isMobile={true} />
          ) : (
            <div className="header-buttons">
              <Button color="blue" onClick={navigateToLogin}>
                Log in
              </Button>
              <Button color="black" onClick={navigateToReg}>
                Sign up
              </Button>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  )
}
