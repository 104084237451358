import { themeSelector } from "../store/themeSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import logo from "../assets/svg/statola.svg"
import logoDark from "../assets/svg/statola-dark.svg"
import "./ErrorLayout.scss";

export const ErrorLayout = ({ children }) => {
  const theme = useSelector(themeSelector);

  const navigate = useNavigate()

  const navigateToMain = () => {
    navigate("/")
  }

  return (
    <div className={`errorLayout errorLayout_${theme}`}>
      <div className="container errorLayout_header">
      <img
          src={theme === "light" ? logo : logoDark}
          alt="statola"
          onClick={navigateToMain}
        />
      </div>
      <div className="content">{children}</div>
    </div>
  );
};
