import React from "react";
import { themeSelector } from "../../store/themeSlice";
import { ErrorLayout } from "../../errorLayout/ErrorLayout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/svg/404.svg";
import whiteErrorImg from "../../assets/svg/404white.svg";
import goalkeeper from "../../assets/svg/goalkeeper.svg";
import "./ErrorPage.scss";

const ErrorPage = () => {
  const theme = useSelector(themeSelector);

  const navigate = useNavigate();

  const navigateToMain = () => {
    navigate("/");
  };
  return (
    <ErrorLayout>
      <div className="container">
        <div className="errorPage__block">
          <div className="errorPage__block__info">
            <h2
              className={`errorPage__block__sms errorPage__block__sms_${theme}`}
            >
              OOPS!
            </h2>
            <img src={theme === "light" ? errorImg : whiteErrorImg} alt="404" />
            <p
              className={`errorPage__block__text errorPage__block__text_${theme}`}
            >
              Unfortunately, we can't find the page you're looking for
            </p>
            <button
              className={`errorPage__block__button errorPage__block__button_${theme}`}
              onClick={navigateToMain}
            >
              Main page
            </button>
          </div>
          <div className="errorPage__block__img">
            <img src={goalkeeper} alt="goalkeeper" />
          </div>
        </div>
      </div>
    </ErrorLayout>
  );
};

export default ErrorPage;
