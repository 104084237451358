export const getInfoAboutMatches = (arr) => {
    let win = 0;
    let draw = 0;
    let lose = 0;
    let sum_totals = 0;
    let avg_totals = 0;

    let sum_opposite_totals = 0;
    let avg_opposite_totals = 0;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].need_id === arr[i].t1.home_id) {
            if (arr[i].t1.home_total > arr[i].t2.away_total) {
                win += 1
            } else if (arr[i].t1.home_total < arr[i].t2.away_total) {
                lose += 1
            } else {
                draw += 1
            }
            sum_totals += arr[i].t1.home_total;
            sum_opposite_totals += arr[i].t2.away_total;
        } else {
            if (arr[i].t1.home_total < arr[i].t2.away_total) {
                win += 1
            } else if (arr[i].t1.home_total > arr[i].t2.away_total) {
                lose += 1
            } else {
                draw += 1
            }
            sum_totals += arr[i].t2.away_total;
            sum_opposite_totals += arr[i].t1.home_total;
        }
    }

    avg_totals = sum_totals / arr.length
    avg_opposite_totals = sum_opposite_totals / arr.length

    return {
        home: {
            length: arr.length,
            wins: win,
            draw: draw,
            loses: lose,
            avg_totals: avg_totals.toFixed(1),
            avg_opposite_totals: avg_opposite_totals.toFixed(1)
        },
        away: {
            length: arr.length,
            wins: win,
            draw: draw,
            loses: lose,
            avg_totals: avg_totals.toFixed(1),
            avg_opposite_totals: avg_opposite_totals.toFixed(1)
        }
    }
}