import $api from "../../../http";

const options = {
  headers: {
    Accept: "application/x-www-form-urlencoded",
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const auth = async (user) => {
  return await $api
    .post("/user/login", user, options)
    .then((response) => response.data);
};
