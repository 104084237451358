import { useState } from "react";
import "./TooltipReferee.scss";

export const TooltipReferee = (data) => {
  const [show, setShow] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ cursor: "pointer" }}
        onMouseEnter={() => {
          setShow((prev) => !prev);
        }}
        onMouseLeave={() => {
          setShow((prev) => !prev);
        }}
      >{data?.data.text}</div>
      {show && (
        <div className="tooltipReferee">
          <span className="tooltipReferee_item">{data?.data.desc}</span>
        </div>
      )}
    </div>
  );
};
