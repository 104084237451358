export const prepareStatsTime = (time) => {
  let newTime = ""
  if (!Object.keys(time).includes("start")) {
    Object.entries(time).forEach(([key, value]) => {
      if (value) {
        newTime = key
      }
    })
  } else {
    newTime = time
  }
  return newTime
}
