import "./LeaguesAndCupsCard.scss";
import { PROD_URL } from "../../../../http";

import { Link } from "react-router-dom";

export const LeaguesAndCupsCard = ({ title, data, onLoadMoreClick }) => {
  return (
    <div className="leaguesAndCups__card">
      <h3 className="leaguesAndCups__card-title">{title}</h3>
      {data?.results?.map((item) => (
        <div className="leaguesAndCups__card__item">
          <img
            src={PROD_URL + item?.country.image_path}
            alt="country"
            className="leaguesAndCups__card__img"
          />
          <Link
            className="leaguesAndCups__card__text"
            to={`/league/${item.id}`}
          >
            {item?.country.name} {item.name}
          </Link>
        </div>
      ))}
      {data?.page !== data?.max_page && <p
        className="matches__card-loadmore"
        onClick={() => onLoadMoreClick(data?.page + 1)}
      >
        Load more
      </p>}
    </div>
  );
};
