export const verticalTable = (activeArr) => {
    let win = 0;
    let draw = 0;
    let lose = 0;
    let avg_diff = 0;

    let sumHomeTotals = 0;
    let sumOpponentTotals = 0;

    let maxTotal = -1000;
    let minTotal = 1000;

    let maxHomeIndividualTotal = -1000;
    let minHomeIndividualTotal = 1000;
    let maxOpponentIndividualTotal = -1000;
    let minOpponentIndividualTotal = 1000;

    let avg_home = 0;
    let avg_opponent = 0;
    let avg_sum = 0;

    let homeTotals = [];
    let opponentTotals = [];

    let h1 = []
    let h2 = []

    for (let i = 0; i < activeArr.length; i++) {
        if (activeArr[i].need_id === activeArr[i].t1.home_id) {
            if (activeArr[i].t1.home_total > activeArr[i].t2.away_total) {
                win += 1
            } else if (activeArr[i].t1.home_total < activeArr[i].t2.away_total) {
                lose += 1
            } else {
                draw += 1
            }

            sumHomeTotals += activeArr[i].t1.home_total
            sumOpponentTotals += activeArr[i].t2.away_total

            maxHomeIndividualTotal = maxHomeIndividualTotal < activeArr[i].t1.home_total ? activeArr[i].t1.home_total : maxHomeIndividualTotal;
            minHomeIndividualTotal = minHomeIndividualTotal > activeArr[i].t1.home_total ? activeArr[i].t1.home_total : minHomeIndividualTotal;
            maxOpponentIndividualTotal = maxOpponentIndividualTotal < activeArr[i].t2.away_total ? activeArr[i].t2.away_total : maxOpponentIndividualTotal;
            minOpponentIndividualTotal = minOpponentIndividualTotal > activeArr[i].t2.away_total ? activeArr[i].t2.away_total : minOpponentIndividualTotal;

            homeTotals.push(activeArr[i].t1.home_total)
            opponentTotals.push(activeArr[i].t2.away_total)
        } else {
            if (activeArr[i].t1.home_total < activeArr[i].t2.away_total) {
                win += 1
            } else if (activeArr[i].t1.home_total > activeArr[i].t2.away_total) {
                lose += 1
            } else {
                draw += 1
            }
            sumHomeTotals += activeArr[i].t2.away_total
            sumOpponentTotals += activeArr[i].t1.home_total

            maxHomeIndividualTotal = maxHomeIndividualTotal < activeArr[i].t2.away_total ? activeArr[i].t2.away_total : maxHomeIndividualTotal;
            minHomeIndividualTotal = minHomeIndividualTotal > activeArr[i].t2.away_total ? activeArr[i].t2.away_total : minHomeIndividualTotal;
            maxOpponentIndividualTotal = maxOpponentIndividualTotal < activeArr[i].t1.home_total ? activeArr[i].t1.home_total : maxOpponentIndividualTotal;
            minOpponentIndividualTotal = minOpponentIndividualTotal > activeArr[i].t1.home_total ? activeArr[i].t1.home_total : minOpponentIndividualTotal;

            homeTotals.push(activeArr[i].t2.away_total)
            opponentTotals.push(activeArr[i].t1.home_total)

        }

        let localTotal = activeArr[i].t

        maxTotal = maxTotal < localTotal ? localTotal : maxTotal;
        minTotal = minTotal > localTotal ? localTotal : minTotal;
    }

    avg_diff = (sumHomeTotals - sumOpponentTotals) / activeArr.length

    avg_home = sumHomeTotals / activeArr.length
    avg_opponent = sumOpponentTotals / activeArr.length
    avg_sum = (sumHomeTotals + sumOpponentTotals) / activeArr.length

    for (let i = 0; i < homeTotals.length; i++) {
        h1.push([homeTotals[i], opponentTotals[i]])
        h2.push([opponentTotals[i], homeTotals[i]])
    }

    return {
        winDrawLoseTable: {
            w: `${win}/${activeArr.length}`,
            d: `${draw}/${activeArr.length}`,
            l: `${lose}/${activeArr.length}`,
        },
        verticalTable: {
            w: `${win}`,
            d: `${draw}`,
            l: `${lose}`,
            avg_diff: avg_diff.toFixed(2),
            max: maxTotal,
            min: minTotal,
            max_t1: maxHomeIndividualTotal,
            max_t2: maxOpponentIndividualTotal,
            min_t1: minHomeIndividualTotal,
            min_t2: minOpponentIndividualTotal,
            avg_t1: avg_home.toFixed(2),
            avg_t2: avg_opponent.toFixed(2),
            avg_t: avg_sum.toFixed(2),
        },
        t1Ot1U: homeTotals,
        t2Ot2U: opponentTotals,
        h1: h1,
        h2: h2,
    }
}