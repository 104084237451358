import { Link } from "react-router-dom";
export const StatsRow = ({ team }) => {
  return (
    <div className="stats-table-row">
      <p>{team.name}</p>
      <p>{team.count}</p>
      <p>{team.t}</p>
      <p>{team.t1}</p>
      <p>{team.t2}</p>
      <p>{team.difference}</p>
      <p>{team.btts}%</p>
      <p>
        {team?.nearest_fixture && (
          <Link to={`/fixture/${team.nearest_fixture}`}>
            <button className="stats-table-row__button">Prematch</button>
          </Link>
        )}
      </p>
    </div>
  );
};
