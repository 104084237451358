import { numToPerc } from "../../../utils/numToPerc"


export const configureStats = (response) => {
  const sumMatches = response.venue.home_wins + response.venue.away_wins + response.venue.draws
  const sumMinutesArr = Object.values(response.goals.count_by_minutes).reduce(
    (acc, value) => [...acc, value],
    []
  )

  const needableTimeLine = [...sumMinutesArr, 0]

  const resultTen = []

  for (let i = 0; i <= 80; i += 10) {
    let sum = 0;
    for (let j = i + 1; j <= i + 10; j++) {
      sum += needableTimeLine[j];
    }
    resultTen.push((sum / response.goals.all * 100).toFixed(0));
  }

  const resultFifteen = []

  for (let i = 0; i <= 75; i += 15) {
    let sum = 0;
    for (let j = i + 1; j <= i + 15; j++) {
      sum += needableTimeLine[j];
    }
    resultFifteen.push((sum / response.goals.all * 100).toFixed(0));
  }

  return {
    goalsPerMatch: (response.goals.all / response.fixtures_count).toFixed(2),
    goalsPerMatchHome: (response.goals.home / response.fixtures_count).toFixed(
      2
    ),
    goalsPerMatchAway: (response.goals.away / response.fixtures_count).toFixed(
      2
    ),
    goalsPerFirst: (
      response.goals.first_half / response.fixtures_count
    ).toFixed(2),
    goalsPerSecond: (
      response.goals.second_half / response.fixtures_count
    ).toFixed(2),
    avgPerMinute: (response.goals.minutes / response.goals.all).toFixed(2),
    winHome: numToPerc(response.venue.home_wins, sumMatches).toFixed(),
    draws: numToPerc(response.venue.draws, sumMatches).toFixed(),
    winAway: numToPerc(response.venue.away_wins, sumMatches).toFixed(),
    zeroToOne: (
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key <= 1) return acc + value
          return acc
        },
        0
      ) / response.fixtures_count * 100
    ).toFixed(),
    twoToThree: numToPerc(
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key >= 2 && key <= 3) return acc + value
          return acc
        },
        0
      ),
      response.fixtures_count
    ).toFixed(),
    fourPlus: numToPerc(
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key > 3) return acc + value
          return acc
        },
        0
      ),
      response.fixtures_count
    ).toFixed(),
    tenMinsPerc: resultTen,
    fifteenMinsPerc: resultFifteen,
    ballPos: (
      (response.stats["Ball Possession %"].home_value + response.stats["Ball Possession %"].away_value) / response.stats["Ball Possession %"].count
    ).toFixed(2),
    ballPosHome: (response.stats["Ball Possession %"].home_value / response.stats["Ball Possession %"].count).toFixed(2),
    ballPosAway: (response.stats["Ball Possession %"].away_value / response.stats["Ball Possession %"].count).toFixed(2),
    shotsTotal: (
      (response.stats["Shots Total"].home_value + response.stats["Shots Total"].away_value) / response.stats["Shots Total"].count
    ).toFixed(2),
    shotsTotalHome: (response.stats["Shots Total"].home_value / response.stats["Shots Total"].count).toFixed(2),
    shotsTotalAway: (response.stats["Shots Total"].away_value / response.stats["Shots Total"].count).toFixed(2),
    corners: (
      (response.stats["Corners"].home_value + response.stats["Corners"].away_value) / response.stats["Corners"].count
    ).toFixed(2),
    cornersHome: (response.stats["Corners"].home_value / response.stats["Corners"].count).toFixed(2),
    cornersAway: (response.stats["Corners"].away_value / response.stats["Corners"].count).toFixed(2),
    yellowCards: (
      (response.stats["Yellow cards"].home_value + response.stats["Yellow cards"].away_value) / response.stats["Yellow cards"].count
    ).toFixed(2),
    yellowCardsHome: (response.stats["Yellow cards"].home_value / response.stats["Yellow cards"].count).toFixed(2),
    yellowCardsAway: (response.stats["Yellow cards"].away_value / response.stats["Yellow cards"].count).toFixed(2),
    throwins: (
      (response.stats["Throw-ins"].home_value + response.stats["Throw-ins"].away_value) / response.stats["Throw-ins"].count
    ).toFixed(2),
    throwinsHome: (response.stats["Throw-ins"].home_value / response.stats["Throw-ins"].count).toFixed(2),
    throwinsAway: (response.stats["Throw-ins"].away_value / response.stats["Throw-ins"].count).toFixed(2),
    fouls: (
      (response.stats["Fouls"].home_value + response.stats["Fouls"].away_value) / response.stats["Fouls"].count
    ).toFixed(2),
    foulsHome: (response.stats["Fouls"].home_value / response.stats["Fouls"].count).toFixed(2),
    foulsAway: (response.stats["Fouls"].away_value / response.stats["Fouls"].count).toFixed(2),
    goalKicks: (
      (response.stats["Goal kicks"].home_value + response.stats["Goal kicks"].away_value) / response.stats["Goal kicks"].count
    ).toFixed(2),
    goalKicksHome: (response.stats["Goal kicks"].home_value / response.stats["Goal kicks"].count).toFixed(2),
    goalKicksAway: (response.stats["Goal kicks"].away_value / response.stats["Goal kicks"].count).toFixed(2),
    offsides: (
      (response.stats["Offsides"].home_value + response.stats["Offsides"].away_value) / response.stats["Offsides"].count
    ).toFixed(2),
    offsidesHome: (response.stats["Offsides"].home_value / response.stats["Offsides"].count).toFixed(2),
    offsidesAway: (response.stats["Offsides"].away_value / response.stats["Offsides"].count).toFixed(2),
  }
}
