import { Premium } from "../../components/Premiun/Premium";
import { StatsTable } from "../../components/StatsTable/StatsTable";
import { Layout } from "../../layouts/Layout";
import { LineFilter } from "../../components/LineFilter/LineFilter";
import { NumberFilter } from "../../components/NumberFilter/NumberFilter";
import { MinimumFilter } from "../../components/MaxMinFilter/MaxMinFilter";
import { LeagueDropDown } from "../../components/LeagueDropDown/LeagueDropDown";
import { PreviousFilter } from "../../components/PreviousFilter/PreviousFilter";
import { FilterWithPrev } from "../../components/FilterWithPrev/FilterWithPrev";
import { useStats } from "./hooks/useStats";
import "../../assets/styles/teams-stats.scss";

export const TeamsStats = () => {
  const {
    filters,
    filtersSeasons,
    filtersLeagues,
    paginateFilters,
    setStatType,
    setSeason,
    setAllSeasons,
    setCount,
    setTime,
    setMin,
    teams,
    loading,
    teamsStatus,
    setLeague,
    setAllLeagues,
    onApplyClick,
    onLoadClick,
    onSortClick,
  } = useStats();

  return (
    <Layout>
      <div className="container">
        <div className="teams-stats">
          <div className="teams-stats-left">
            <h1 className="teams-stats__title">Teams stats</h1>
            <p className="teams-stats__desc">
              Full statistics of football teams. You can quickly find the teams
              with the highest passability by betting on total more or total
              less: goals, corners, yellow cards and many other parameters. As
              well as the statistics of the teams on the bet "both will score"
            </p>

            {filters && (
              <div className="teams-stats-filters">
                <div style={{ display: "flex", marginBottom: "21px" }}>
                  <LineFilter
                    filters={filters.stat_type}
                    setFilter={setStatType}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: "21px",
                  }}
                >
                  <FilterWithPrev
                    styles={{
                      borderLeft: "none",
                      borderRadius: "0px 100px 100px 0px",
                    }}
                    filters={filters.seasons}
                    activeFilter={filtersSeasons}
                    setFilter={setSeason}
                    maxLength={filters.seasons.length}
                    setAll={setAllSeasons}
                    withAll
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "21px" }}>
                  <NumberFilter
                    activeFilter={filters.count}
                    filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                    setFilter={setCount}
                  />
                  <div style={{ margin: "0px 20px" }}>
                    <LeagueDropDown
                      leagues={filtersLeagues}
                      allLeagues={filters.leagues}
                      setFilter={setLeague}
                      setAllFilters={setAllLeagues}
                    />
                  </div>
                  <LineFilter filters={filters.time} setFilter={setTime} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MinimumFilter
                    activeFilter={filters.min_count}
                    setFilter={setMin}
                  />
                  <button className="stats__button" onClick={onApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="teams-stats-right">
            <Premium />
          </div>
        </div>

        {!teams?.length && !loading ? (
          <div className="no-data">
            <div className="no-data__block">
              <h4>OOPS!</h4>
              <p>
                Unfortunately, don't have data on these statistical metrics.
                Perhaps we'll include them in the future!
              </p>
            </div>
          </div>
        ) : teamsStatus === 422 ? (
          <div className="no-data">
            <div className="no-data__block">
              <h4>OOPS!</h4>
              <p>
                The filters you've applied don't yield any results. Try a
                different combination to find the information you're looking
                for.
              </p>
            </div>
          </div>
        ) : (
          <StatsTable
            reverse={filters?.reverse}
            activeSort={filters?.sort}
            onSortClick={onSortClick}
            teams={teams}
            onLoadClick={onLoadClick}
            paginateInfo={paginateFilters}
            loading={loading}
          />
        )}
      </div>
    </Layout>
  );
};
