import "./StandingsCards.scss"

export const StandingsCards = () => {
  return (
    <div className="standings-cards">
      <div className="standings-cards__items">
        <div className="standings-cards__item" style={{ background: "#85e399"}}>
          Champions League
        </div>
        <div className="standings-cards__item" style={{ background: "#f59393" }}>
          Relegation
        </div>
        <div className="standings-cards__item" style={{ background: "#f9e0af" }}>
          UEFA Conference League Qualification
        </div>
        <div className="standings-cards__item" style={{ background: "#bfdac6" }}>
          UEFA Europe League
        </div>
      </div>
    </div>
  )
}
