export const prepareRefereeHeader = (data) => {
  const leagues = {}
  for (const key of data?.params?.leagues) {
    if (data?.params?.fixtures?.default?.leagues?.includes(key))
      leagues[key.name] = true
    else leagues[key.name] = false
  }

  return {
    ...data,
    leagues: leagues,
  }
}
