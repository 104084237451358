import React from "react"
import "./StatsCards.scss"

export const StatsCards = ({ descriptiveStats }) => {
  return (
    <div className="stats__cards">
      <div className="stats__cards-item">
        Goals / match:{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.goalsPerMatch}
        </span>
      </div>
      <div className="stats__cards-item stats__cards-item-w300">
        Goals / match (Home):{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.goalsPerMatchHome}
        </span>
      </div>
      <div className="stats__cards-item stats__cards-item-w300">
        Goals / match (Away):{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.goalsPerMatchAway}
        </span>
      </div>
      <div className="stats__cards-item">
        Goals / 1st half:{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.goalsPerFirst}
        </span>
      </div>
      <div className="stats__cards-item stats__cards-item-w300">
        Goals / 2nd half:{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.goalsPerSecond}
        </span>
      </div>
      <div className="stats__cards-item stats__cards-item-w300">
        Avg. goals per minute:{" "}
        <span style={{ color: "#5F8BFF" }}>
          {descriptiveStats?.avgPerMinute}
        </span>
      </div>
    </div>
  )
}
