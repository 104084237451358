import { useState } from "react";
import arrow from "../../assets/svg/angle-down.svg";
import "./LeagueDropDown.scss";

export const LeagueDropDown = ({
  leagues,
  allLeagues,
  setFilter,
  setAllFilters,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [query, setQuery] = useState("");
  const toggleActive = () => setIsActive((prev) => !prev);

  const onQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const hadnleAllClick = () => {
    setAllFilters(!isAllActive());
  };

  const isAllActive = () => {
    var count = 0;
    Object.keys(leagues)?.forEach((item) => {
      if (leagues[item]) {
        count += 1;
      }
    });

    return count === Object.keys(leagues).length;
  };

  return (
    <div className="league-dropdown">
      <div className="league-dropdown-head" onClick={toggleActive}>
        <p className="league-dropdown__title">League</p>
        <img src={arrow} alt="" className="league-dropwdown__arrow" />
      </div>

      {isActive && (
        <div className="league-dropdown-body">
          <div className="league-dropdown-header">
            <div className="league-dropdown__item" onClick={hadnleAllClick}>
              <div
                className={`league-dropdown__checkbox ${
                  isAllActive() ? "league-dropdown__checkbox_active" : ""
                }`}
              ></div>{" "}
              All
            </div>
          </div>
          <div className="league-dropdown-list">
            {Object.keys(leagues)
              .filter((value) =>
                allLeagues[value].toLowerCase().includes(query.toLowerCase())
              )
              .map((key, index) => (
                <div
                  className="league-dropdown__item"
                  key={index}
                  onClick={() => {
                    setFilter(key);
                  }}
                >
                  <div
                    className={`league-dropdown__checkbox ${
                      leagues[key] ? "league-dropdown__checkbox_active" : ""
                    }`}
                  />
                  {allLeagues[key]}
                </div>
              ))}
          </div>
          <div className="league-dropdown-search">
            <div className="league-dropdown-input">
              <input
                value={query}
                onChange={onQueryChange}
                placeholder="Search League"
                className="league-dropdown__input"
                type="text"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
