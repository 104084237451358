import { Layout } from "../../layouts/Layout";
import { BlogCard } from "./components/BlogCard/BlogCard";
import { Button } from "../../UI/buttons/Button";
import blog from "../../assets/img/blog.png";
import ball from "../../assets/svg/blog-ball.svg";
import star from "../../assets/svg/blog-star.svg";
import square from "../../assets/svg/blag-square.svg";
import "../../assets/styles/blog.scss";

export const Blog = () => {
  const posts = [
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Statistics",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Referee",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Match",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Statistics",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Important",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Important",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Match",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Statistics",
    },
    {
      time: "4 min",
      title: "Become AI Native: What It Means (& How to Do It)",
      desc: "The term “AI native” is a moving target, and, like everything else in the world of artificial intelligence (AI), the phrase is continuously evolving.",
      link: "",
      type: "Statistics",
    },
  ];
  return (
    <Layout>
      <div className="container">
        <h1 className="blog__title title">Blog</h1>
        <div className="blog-topic">
          <div className="blog-left">
            <ul className="blog-text">
              <li className="blog-text__item">
                <img
                  src={ball}
                  alt="ball"
                  className="blog-text__icon"
                />
                <p className="blog-text__desc">
                  Stay ahead of the game with our in-depth football analysis and
                  insights
                </p>
              </li>
              <li className="blog-text__item">
                <img
                  src={star}
                  alt="star"
                  className="blog-text__icon"
                />
                <p className="blog-text__desc">
                  Join our community of football enthusiasts and receive
                  exclusive access to our stats and strategies
                </p>
              </li>
              <li className="blog-text__item">
                <img
                  src={square}
                  alt="square"
                  className="blog-text__icon"
                />
                <p className="blog-text__desc">
                  Don't miss out on the latest football news and betting tips
                </p>
              </li>
            </ul>
            <div className="blog-subscribe">
              <p className="blog-subscribe__text">
                Subscribe to the newsletter and keep up to date with all the
                events of your favorite team
              </p>
              <div className="blog-subscribe-submit">
                <input
                  className="blog-input"
                  placeholder="john@mail.ru"></input>
                <Button color="blue">Subscribe</Button>
              </div>
            </div>
          </div>
          <img
            className="blog-img"
            src={blog}
            alt="football player"
          />
        </div>

        <div className="blog-posts">
          {posts.map((post, index) => (
            <BlogCard
              key={index}
              {...post}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
