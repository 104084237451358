import React, { useEffect, useMemo, useState } from "react";
import { TableRow } from "./TableRow";
import "./Table.scss";
import { TableFooter } from "./TableFooter";

import { OrbitProgress } from "react-loading-indicators";

export const Table = ({
  headerItems,
  data,
  colsWidth,
  redCards,
  setTableTotals,
  isReferee = false,
  summaried = false,
  withCheckbox = false,
  showWinner = false,
  showGreen = false,
  teamName,
  teamId,
  page = "",
  loading = false,
}) => {
  const homeId = useMemo(() => {
    return teamId;
  }, [teamId]);

  const [isCheckAll, setIsCheckAll] = useState(true);

  const list = useMemo(() => {
    return data?.table?.map((row, index) => {
      return {
        id: index,
        t: row?.t,
        t1: row?.t1,
        t2: row?.t2,
        home_stats: row?.home_stats,
        away_stats: row?.away_stats,
      };
    });
  }, [data?.table]);

  const [isCheck, setIsCheck] = useState(list);

  useEffect(() => {
    setIsCheck(list);
  }, [list]);

  useEffect(() => {
    if (withCheckbox) {
      setTableTotals(
        isCheck?.map((item) => {
          return {
            t: item?.t,
            t1: item?.home_stats,
            t2: item?.away_stats,
            need_id: homeId,
          };
        })
      );
    }
  }, [isCheck]);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    if (isCheck) setIsCheck([...(isCheck || []), list[+id]]);
    else setIsCheck(list);

    if (!checked) {
      setIsCheck(isCheck?.filter((item) => item?.id !== +id));
      setIsCheckAll(false);
    } else {
      if (isCheck?.length + 1 === list.length) setIsCheckAll(true);
      else setIsCheckAll(false);
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list?.map((item) => item));

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  if (loading) {
    return (
      <div style={{width: "100%", textAlign: "center"}}>
        <OrbitProgress
          variant="disc"
          color="#318acc"
          size="large"
          text=""
          textColor=""
        />
      </div>
    );
  }

  return (
    <>
      {(data?.table?.length > 0 || summaried) && (
        <div className="table">
          <div
            className="table-header"
            style={{ gridTemplateColumns: colsWidth }}
          >
            {withCheckbox && (
              <p>
                <input
                  type="checkbox"
                  checked={isCheckAll}
                  onChange={handleSelectAll}
                />
              </p>
            )}
            {headerItems.map((item, index) => (
              <p key={index}>
                <span>{item.text}</span>
              </p>
            ))}
          </div>
          <div className="table-body">
            {data?.table?.map((item, index) => {
              let value = false;
              for (const obj of isCheck || []) {
                if (obj.id === index) value = true;
              }

              return (
                <TableRow
                  colsWidth={colsWidth}
                  item={item}
                  key={index}
                  id={index}
                  handleClick={handleClick}
                  isChecked={value}
                  summaried={summaried}
                  withCheckbox={withCheckbox}
                  showWinner={showWinner}
                  redCards={redCards}
                  showGreen={showGreen}
                  teamName={teamName}
                  teamId={teamId}
                  isReferee={isReferee}
                  summary={data?.sums ? data?.sums?.total : 0}
                  page={page}
                  nextItem={data?.table[index + 1]}
                />
              );
            })}
          </div>
          {summaried && (
            <TableFooter colsWidth={colsWidth} summary={data?.sums} />
          )}
        </div>
      )}
    </>
  );
};
