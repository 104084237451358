import { Layout } from "../../layouts/Layout"
import { LineFilter } from "../../components/LineFilter/LineFilter"
import "../../assets/styles/leagues.scss"
import { useLeaguesStats } from "./hooks/useLeaguesStats"
import { LeaguesCard } from "./components/LeaguesCard/LeaguesCard"

export const Leagues = () => {
  const { filters, leagues, setRegion, onApplyClick } = useLeaguesStats()
  let leaguesCards = []
  for (let i = 0; i < leagues?.length; i+=16) {
    leaguesCards = [...leaguesCards, <LeaguesCard data={leagues?.slice(i, i + 16)} /> ]
    if (i + 16 > leagues?.length && leagues[i + 17]) {
      leaguesCards = [...leaguesCards, <LeaguesCard data={leagues?.slice(i + 16, i + 32)} /> ]
      break
    }
  }
 
  return (
    <Layout>
      <div className="container">
        <h1 className="title">Leagues</h1>
        <p className="leagues__desc">
          Here are collected terms from the world of sports and betting. We
          tried to tell you about each of them in simple words and share basic
          knowledge on working with sports statistics betting. We recommend
          reading to both experienced betting men and novice players.
        </p>
        <div style={{ width: 600, marginTop: 100 }}>
          <LineFilter
            styles={{
              borderRadius: "100px",
            }}
            filters={filters.region}
            activeFilter={"Africa"}
            setFilter={setRegion}
          />
        </div>
        <button
              className="stats__button"
              style={{ marginLeft: 0, marginTop: 40}}
              onClick={onApplyClick}
            >
              Apply
            </button>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 30}}>
        {leaguesCards}
        </div>
      </div>
    </Layout>
  )
}
