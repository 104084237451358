import { useState } from "react";
import { Button } from "../../../../../../UI/buttons/Button";
import wallet from "../../../../../../assets/svg/wallet.svg";
import { Modal } from "../../../../../../components/Modal/Modal";
import "./BillingCard.scss";

export const BillingCard = ({ onCancelClick }) => {
  return (
    <div className="billing-card">
      <div className="billing-card-header">
        <img
          src={wallet}
          alt="wallet"
          className="billing-card__img"
        />
        <p className="billing-card__text">Subscription</p>
      </div>
      <div className="billing-card-date">
        <p className="billing-card-date__key">Expires</p>
        <div className="billing-card-date__value">07/25/2025</div>
      </div>
      <div className="billing-card-area">
        <p className="billing-card-type">MasterCard</p>
        <p className="billing-card-area__value">**** **** **** 7890</p>
        <span className="billing-card-area__edit">Edit Card</span>
      </div>
      <Button
        color="blue"
        className="billing-card__button"
        onClick={onCancelClick}>
        Cancel subscription
      </Button>
    </div>
  );
};
