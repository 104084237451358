export const HorizontalTableCol = ({ item, colsWidth, handleClick }) => {
  return (
    <div
      className="horizontal-table-row"
      style={{ width: colsWidth.split(" ")[1] }}
    >
      <p>{item}</p>
    </div>
  )
}
