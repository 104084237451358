import { themeSelector } from "../../store/themeSlice";
import { useSelector } from "react-redux";
import twitter from "../../assets/svg/twitter.svg";
import telegram from "../../assets/svg/telegram.svg";
import youtube from "../../assets/svg/youtube.svg";
import discord from "../../assets/svg/discord.svg";
import twitterDark from "../../assets/svg/twitter-dark.svg";
import telegramDark from "../../assets/svg/telegram-dark.svg";
import youtubeDark from "../../assets/svg/youtube-dark.svg";
import discordDark from "../../assets/svg/discord-dark.svg";

import "./Networks.scss";

export const Networks = () => {
  const theme = useSelector(themeSelector);
  return (
    <div className="networks">
      <img
        src={theme === "light" ? twitter : twitterDark}
        alt="twitter"
      />
      <img
        src={theme === "light" ? telegram : telegramDark}
        alt="telegram"
      />
      <img
        src={theme === "light" ? youtube : youtubeDark}
        alt="youtube"
      />
      <img
        src={theme === "light" ? discord : discordDark}
        alt="discord"
      />
    </div>
  );
};
