import telegram from "../../assets/svg/telegram-white.svg";
import "./Premium.scss";

export const Premium = () => {
  return (
    <div className="premium">
      <p className="premium__title">Subscribe to Statoola Premium</p>
      <p className="premium__desc">
        Discover the best tools for analyzing football matches:
      </p>
      <button className="button button_blue premium__button">
        <img
          src={telegram}
          alt="telegram link button"
        />
        <span>JOIN PREMIUM TODAY</span>
      </button>
    </div>
  );
};
