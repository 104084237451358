import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { themeSelector } from "../store/themeSlice";
import { useSelector } from "react-redux";
import "./Layout.scss";

export const Layout = ({ children }) => {
  const theme = useSelector(themeSelector);

  return (
    <div className={`layout layout_${theme}`}>
      <Header />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};
