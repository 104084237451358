import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Main } from "../pages/Main/main.jsx";
import { Profile } from "../pages/Profile/profile.jsx";
import { Login } from "../pages/Login/login.jsx";
import { Registration } from "../pages/Registartion/Registration.jsx";
import { TeamsStats } from "../pages/TeamsStats/TeamsStats.jsx";
import { RefereeStats } from "../pages/RefereeStats/RefereeStats.jsx";
import { ContactUs } from "../pages/ContactUs/ContactUs.jsx";
import { Subscription } from "../pages/Subscription/Subscription.jsx";
import { Trends } from "../pages/Trends/Trends.jsx";
import { Team } from "../pages/Team/Team.jsx";
import { League } from "../pages/League/League.jsx";
import { Referee } from "../pages/Referee/Referee.jsx";
import { Blog } from "../pages/Blog/Blog.jsx";
import { Learning } from "../pages/Learning/Learning.jsx";
import { Confirm } from "../pages/Confirm/Confirm.jsx";
import { Leagues } from "../pages/Leagues/Leagues.jsx";
import { GameResult } from "../pages/GameResult/GameResult.jsx";
import { PreMatch } from "../pages/PreMatch/PreMatch.jsx";
import { ScrollTop } from "../pages/ScrollTop/ScrollTop.jsx";
import { Suspense } from "react";
import ErrorPage from "../pages/ErrorPage/ErrorPage.jsx";
import { Policy } from "../pages/Policy/Policy.jsx";
import { Terms } from "../pages/Terms/Terms.jsx";

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollTop>
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route loader={<>Loading...</>} path="/" element={<Main />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/teams-stats" element={<TeamsStats />} />
          <Route path="/referee-stats" element={<RefereeStats />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/trends" element={<Trends />} />
          <Route path="/team/:id" element={<Team />} />
          <Route path="/league/:id" element={<League />} />
          <Route path="/referee/:id" element={<Referee />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/game-result/:id" element={<GameResult />} />
          <Route
            loader={<>Loading...</>}
            path="/fixture/:id"
            element={<PreMatch />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/tos" element={<Terms />} />
        </Routes>
      </ScrollTop>
    </BrowserRouter>
  );
};
