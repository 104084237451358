import "./SelectorFilter.scss"
import arrow from "../../assets/svg/angle-down.svg"
import { useState } from "react"

export const SelectorFilter = ({ filters, setFilter, activeFilter }) => {
  const [isActive, setIsActive] = useState(false)

  const toggleActive = () => {
    setIsActive((prev) => !prev)
  }

  const setInactive = () => {
    setIsActive(false)
  }

  return (
    <div className="selector-filter">
      <div className="selector-filter-header" onClick={toggleActive}>
        <span>
          {typeof activeFilter === "string"
            ? activeFilter
            : Object.keys(activeFilter || {})[0]}
        </span>
        <img src={arrow} alt="" className="previos-filter__arrow" />
      </div>
      {isActive && (
        <div className="selector-filter-body">
          <div className="selector-filter-grid">
            {Array.isArray(filters)
              ? filters?.map((key) => (
                  <div
                    className={`selector-filter__item ${
                      key === activeFilter ? "selector-filter__item_active" : ""
                    }`}
                    onClick={() => setFilter(key)}
                  >
                    {key}
                  </div>
                ))
              : Object.keys(filters).map((filter) => {
                  return (
                    <div
                      className={`selector-filter__item ${
                        filters[filter] ||
                        activeFilter[filter] ||
                        filter === activeFilter
                          ? "selector-filter__item_active"
                          : ""
                      }`}
                      onClick={() => setFilter(filter)}
                    >
                      {filter}
                    </div>
                  )
                })}
          </div>
          <button className="selector-filter__button" onClick={setInactive}>
            Ok
          </button>
        </div>
      )}
    </div>
  )
}
