import { msToHoursMins } from "./msToHoursMins"

export const prepareFixturesTable = (data) => {
  const redCards = []
  const sumsResult = {
    w: 0,
    d: 0,
    l: 0,
    max: 0,
    min: 0,
    avg_t1: 0,
    avg_t2: 0,
    avg_t: 0,
  }
  const tableResult = Object.entries(data || {}).map(([_, value]) => {
    redCards.push({ home: value.home.redcards, away: value.away.redcards })
    if (value.score.home > value.score.away) sumsResult.w += 1
    else if (value.score.home < value.score.away) sumsResult.l += 1
    else sumsResult.d += 1

    if (sumsResult.max < value.score.home + value.score.away)
      sumsResult.max = value.score.home + value.score.away

    if (sumsResult.min > value.score.home + value.score.away)
      sumsResult.min = value.score.home + value.score.away
    sumsResult.avg_t1 += value.totals.t1
    sumsResult.avg_t2 += value.totals.t2
    sumsResult.avg_t += value.totals.t
    return {
      season: value.tournament ? value.tournament.name : value.season.name,
      date: msToHoursMins(value.date),
      home: value.home.name,
      t1: value.totals.t1,
      t2: value.totals.t2,
      away: value.away.name,
      t: value.totals.t,
      ref: {
        id: value?.referee?.id,
        name: value?.referee?.name,
        stats: {
          YellowCards: value?.referee?.avg_yellowcards,
          RedCards: value?.referee?.avg_redcards,
          Fouls: value?.referee?.fouls
        }
      },
      score: value.score.home + ":" + value.score.away,
      home_stats: {home_id: value.home.id, home_total: value.totals.t1}, // добавил статистику домашней команды
      away_stats: {away_id: value.away.id, away_total: value.totals.t2}, // добавил статистику выездной команды
    }
  })
  sumsResult.avg_t1 = (sumsResult.avg_t1 / data?.table?.length).toFixed(2)
  sumsResult.avg_t2 = (sumsResult.avg_t2 / data?.table?.length).toFixed(2)
  sumsResult.avg_t = (sumsResult.avg_t / data?.table?.length).toFixed(2)
  
  return { table: tableResult, sums: sumsResult || {}, redCards }
}
