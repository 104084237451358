export const refereeResFotTables = (arr) => {
    let home_wins = 0;
    let draws = 0;
    let away_wins = 0;

    let maxTotal = -1000;
    let minTotal = 1000;

    let sumHomeTotals = 0
    let sumAwayTotals = 0;
    let avg_home = 0;
    let avg_away = 0;
    let avg_sum = 0;

    let homeTotals = [];
    let awayTotals = [];

    let h1 = [];
    let h2 = [];

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].t1.home_total > arr[i].t2.away_total) {
            home_wins += 1;
        } else if (arr[i].t1.home_total < arr[i].t2.away_total) {
            away_wins += 1;
        } else {
            draws += 1;
        }

        let localTotal = arr[i].t

        maxTotal = maxTotal < localTotal ? localTotal : maxTotal;
        minTotal = minTotal > localTotal ? localTotal : minTotal;

        sumHomeTotals += arr[i].t1.home_total
        sumAwayTotals += arr[i].t2.away_total

        homeTotals.push(arr[i].t1.home_total)
        awayTotals.push(arr[i].t2.away_total)

        h1.push([arr[i].t1.home_total, arr[i].t2.away_total])
        h2.push([arr[i].t2.away_total, arr[i].t1.home_total])
    }

    avg_home = sumHomeTotals / arr.length
    avg_away = sumAwayTotals / arr.length
    avg_sum = avg_home + avg_away

    return {
        winDrawWinTable: {
            home_wins: `${home_wins}/${arr.length}`,
            draws: `${draws}/${arr.length}`,
            away_wins: `${away_wins}/${arr.length}`
        },
        verticalTable: {
            home_wins: `${home_wins}`,
            draws: `${draws}`,
            away_wins: `${away_wins}`,
            max: maxTotal,
            min: minTotal,
            avg_home: avg_home.toFixed(2),
            avg_away: avg_away.toFixed(2),
            avg_sum: avg_sum.toFixed(2)
        },
        t1Ot1U: homeTotals,
        t2Ot2U: awayTotals,
        h1: h1,
        h2: h2,
    }
}