import { Layout } from "../../layouts/Layout";
import { useLocation } from "react-router-dom";
import { ResultBar } from "../../components/ResultBar/ResultBar";
import { useStats } from "./hooks/useStats";
import "../../assets/styles/game-result.scss";
import { StatsCard } from "./components/StatsCard/StatsCard";
import { MatchesCard } from "../../components/MatchesCard/MatchesCard";
import { TimeFilter } from "../../components/TimeFilter/TimeFIlter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paginatorSelector, setPage } from "../../store/paginatorSlice";
import { Paginator } from "../../components/Paginator/Paginator";

export const GameResult = () => {
  const id =
    +useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];

  const {
    result,
    filters,
    stats,
    matches,
    setTime,
    onStatsApplyClick,
    onLoadMoreClick,
  } = useStats(id);

  const dispatch = useDispatch();

  const page = useSelector(paginatorSelector);

  const curPage = useLocation().search;

  const pages = ["Statistics", "Odds"];

  useEffect(() => {
    if (curPage) dispatch(setPage(curPage.split("=")[1]));
    else dispatch(setPage("Statistics"));
  }, []);

  return (
    <Layout>
      <div className="container">
        <ResultBar result={result} />
        <Paginator pages={pages} />
        <p className="title">Statistics of the last game</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <div>
            {/* <Paginator pages={["Statistics", "Odds"]} /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: "20px",
                marginBottom: 32,
              }}
            >
              <TimeFilter
                activeFilter={filters?.time}
                filters={filters?.time}
                setFilter={setTime}
              />
              <button
                style={{ marginLeft: 20 }}
                className="stats__button"
                onClick={onStatsApplyClick}
              >
                Apply
              </button>
            </div>
            <StatsCard data={stats} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 30,
            }}
          >
            <MatchesCard
              title="Fixtures"
              type="next"
              matches={matches?.next}
              onLoadMoreClick={onLoadMoreClick}
            />
            <MatchesCard
              title="Last matches"
              type="previous"
              matches={matches?.previous}
              onLoadMoreClick={onLoadMoreClick}
            />
          </div>
        </div>

        {/* <h3 style={{ marginTop: 60 }} className="title-stats">
          Hightlight chart
        </h3> */}
      </div>
    </Layout>
  );
};
