import "./RefereesCards.scss"

export const RefereesCards = () => {
  return (
    <div className="referees-cards">
      <div className="referees-cards__items">
        <div className="referees-cards__item" style={{ background: "#F59393" }}>
          The average total exceeds the league average by more than 25%
        </div>
        <div className="referees-cards__item" style={{ background: "#F9B9B9" }}>
          The average total exceeds the league average by more than 15% - 25%
        </div>
        <div className="referees-cards__item" style={{ background: "#FFEBEB" }}>
          The average total exceeds the league average by more than 5% - 15%
        </div>
        <div className="referees-cards__item" style={{ background: "#F9E0AF" }}>
          The average total deviates by no more than 5% from the league average
        </div>
      </div>
      <div className="referees-cards__items">
        <div className="referees-cards__item" style={{ background: "#CFE5D6" }}>
          The average total is 5% - 15% less than the league average
        </div>
        <div className="referees-cards__item" style={{ background: "#A3D8B2" }}>
          The average total is 15% - 25% less than the league average
        </div>
        <div className="referees-cards__item" style={{ background: "#94CC93" }}>
          The average total by 25%+ less than the league average
        </div>
        <div className="referees-cards__item" style={{ background: "#EBEBEB" }}>
          Insufficient games played (less than 4)
        </div>
      </div>
    </div>
  )
}
