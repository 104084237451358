export const refereePrepareFilters = (rawFilters, currentLeagues) => {
  const seasonsArr = [];
  let leaguesArr = [];
  let newTime = "";

  if (currentLeagues) {
    // const trueKeys = [];
    // for (const [key, value] of Object.entries(rawFilters.leagues)) {
    //   if (value) {
    //     trueKeys.push(key)
    //   }
    // }
    leaguesArr = Object.entries(rawFilters.leagues)
    .filter(([league, value]) => value)
    .map(([league, value]) => league)
    .map(league => currentLeagues.find(entry => entry.name === league))
    .map(league => league.id);
  } else {
    Object.entries(rawFilters.leagues).forEach(([key, value]) => {
      if (value) {
        leaguesArr.push(key);
      }
    });
  }
  Object.entries(rawFilters.seasons).forEach(([key, value]) => {
    if (value) {
      seasonsArr.push(key);
    }
  });

  if (!Object.keys(rawFilters.time).includes("start")) {
    Object.entries(rawFilters.time).forEach(([key, value]) => {
      if (value) {
        newTime = key;
      }
    });
  } else {
    newTime = rawFilters.time;
  }

  return {
    ...rawFilters,
    seasons: seasonsArr,
    leagues: leaguesArr,
    time: newTime,
  };
};
