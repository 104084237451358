import "./RefereeBar.scss";

export const RefereeBar = ({ data }) => {
  return (
    <>
      {Object.keys(data || {}).length > 0 && (
        <div className="referee-bar">
          <p className="referee-bar__league">Referee stats</p>
          <p className="referee-bar__name">{data?.name}</p>
          <div className="referee-bar-stats">
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              Cards: <span>{data.stats.yellow_cards.all_match + data.stats.red_cards.all_match}</span>
            </div>
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              Fouls: <span>{data.stats.fouls.all_match}</span>
            </div>
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              1st half cards: <span>{data.stats.yellow_cards.first_half + data.stats.red_cards.first_half}</span>
            </div>
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              2st half cards: <span>{data.stats.yellow_cards.second_half + data.stats.red_cards.second_half}</span>
            </div>
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              1st half fouls: <span>{data.stats.fouls.first_half}</span>
            </div>
            <div
              className="league-bar-stats__item"
              style={{display: "flex", height: 30}}
            >
              2st half fouls: <span>{data.stats.fouls.second_half}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
