import arrow from "../../../../assets/svg/blog-arrow.svg";
import "./BlogCard.scss";

export const BlogCard = ({ time, title, desc, link, type }) => {
  const getClassName = (type) => {
    switch (type) {
      case "Statistics":
        return "blog-card__type_red";
      case "Referee":
        return "blog-card__type_yellow";
      case "Match":
        return "blog-card__type_blue";
      case "Important":
        return "blog-card__type_green";
      default:
        return "type";
    }
  };
  return (
    <div className="blog-card">
      <div className="blog-card-header">
        <div className={`blog-card__type ${getClassName(type)}`}>{type}</div>
        <p className="blog-card__time">{time} red</p>
      </div>
      <div className="blog-card-body">
        <p className="blog-card__title">{title}</p>
        <div className="blog-card__desc">{desc}</div>
      </div>
      <div className="blog-card-footer">
        <p className="blog-card__read">Read</p>
        <img
          src={arrow}
          alt="arrow"
          className="blog-card__arrow"
        />
      </div>
    </div>
  );
};
