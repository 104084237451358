import { TimeZoneSelect } from "./TimeZoneSelect/TimeZoneSelect";
import { Button } from "../../../../../UI/buttons/Button";
import "./TimeZone.scss";

export const TimeZone = () => {
  return (
    <div className="timezone">
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          marginBottom: "30px",
        }}>
        <TimeZoneSelect
          label="Country"
          options={["United States", "UK", "Canada"]}
        />
        <TimeZoneSelect
          label="Time zone"
          options={["Moscow Time", "Georgia time"]}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TimeZoneSelect
          label="Date Format"
          options={["MM/DD/YYYY", "DD/MM/YYYY"]}
        />

        <TimeZoneSelect
          label="Time Format"
          options={["24h", "12h"]}
        />
      </div>
      <Button
        color="blue"
        className="timezone__button">
        Save changes
      </Button>
    </div>
  );
};
