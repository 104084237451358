import { useRef  } from "react";
import "./FileInput.scss";

export const FileInput = ({ label, onInputChange }) => {
  const inputRef = useRef();  

  return (
    <div className="file-input">
      <label className="support-input__label">{label}</label>
      <div
        className="file-input-element"
        onClick={() => inputRef?.current.click()}>
        <p>Choose files</p>
        <input
          type="file"
          className="file-input-element__input"
          ref={inputRef}
          onChange={onInputChange}
          // multiple
        />
        <button className="file-input-element__button">Browse</button>
      </div>
    </div>
  );
};
