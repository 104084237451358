import { useEffect, useState } from "react";
import {
  getDescriptiveStats,
  getLeague,
  getLeagueReferees,
  getLeagueStandings,
  getLeagueStats,
  getLeagueTeams,
  getNextFixtures,
  getPreviousFixtures,
  getLeaguesAndCups,
} from "../http";
import { configureStats } from "../utils/configureStats";
import { prepareFilters } from "../utils/prepareFilters";
import { prepareTeamsTable } from "../utils/prepareTeamsTable";
import { prepareStatsTable } from "../utils/prepareStatsTable";
import { prepareStatsTime } from "../../../utils/prepareStatsTime";
import { prepareRefereesTable } from "../utils/prepareRefereesTable";
import { prepareStandingsTable } from "../utils/prepareStandingsTable";
import { sleep } from "../../../utils/sleep";

export const useStats = (id) => {
  const [filters, setFilters] = useState();
  const [tableTeams, setTableTeams] = useState();
  const [tableStats, setTableStats] = useState();
  const [tableReferees, setTableReferees] = useState();
  const [tableStandings, setTableStandings] = useState();
  const [minutes, setMinutes] = useState();
  const [header, setHeader] = useState();
  const [statsBySeason, setStatsBySeason] = useState();
  const [teams, setTeams] = useState();
  const [descriptiveStats, setDescriptiveStats] = useState();
  const [matches, setMatches] = useState();
  const [timerange, setTimerange] = useState({});
  const [hasTimerange, setHasTimerange] = useState(false);

  const [leaguesAndCups, setLeaguesAndCups] = useState();

  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingReferees, setLoadingReferees] = useState(false);
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [tableWithColors, setTableWithColors] = useState(true);

  const [leagueStatus, setLeagueStatus] = useState(null);
  const [statsStatus, setStatsStatus] = useState(null);
  const [refereeStatus, setRefereeStatus] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      const fetch = async () => {
        const headerResponse = await getLeague(id);

        setHeader(headerResponse.header);
        setTeams(headerResponse.params.team);

        const seasons = {};
        for (const key of headerResponse.params.seasons) {
          if (headerResponse.params.team.seasons.includes(key))
            seasons[key] = true;
          else seasons[key] = false;
        }

        const times = {};
        for (const key of headerResponse.params.team.stats.stats.time) {
          if (typeof key === "string") {
            if (
              headerResponse.params.team.stats.stats.default.time.includes(key)
            )
              times[key] = true;
            else times[key] = false;
          } else setMinutes(key);
        }

        setFilters({
          count: headerResponse.params.team.count,
          time: times,
          stat_type: headerResponse.params.team.stat_type,
          seasons: seasons,
          place:
            headerResponse.params.team.place.charAt(0).toUpperCase() +
            headerResponse.params.team.place.slice(1),
        });
        setStatsBySeason({
          stat_type: headerResponse.params.team?.stat_type,
          time: times,
        });

        try {
          setLoadingTeams(true);
          await sleep(500);
          const tableTeamsResponse = await getLeagueTeams(
            headerResponse?.params?.team?.stat_type,
            headerResponse?.params?.team?.count,
            id,
            headerResponse?.params?.team?.place,
            prepareFilters({
              time: times,
              seasons: seasons,
            })
          );

          setTableTeams(prepareTeamsTable(tableTeamsResponse.data));
          setLeagueStatus(tableTeamsResponse.status);
        } catch (error) {
          if (error.response) {
            console.error("Status", error.response.status);
          } else if (error.request) {
            console.error("Error of request:", error.request);
          } else {
            console.error("Simle error is:", error);
          }
        } finally {
          setLoadingTeams(false);
        }

        const previousResponse = await getPreviousFixtures(1, id);
        setMatches((prev) => ({
          ...prev,
          previous: previousResponse,
        }));

        const nextResponse = await getNextFixtures(1, id);
        setMatches((prev) => ({ ...prev, next: nextResponse }));

        const leaguesResponse = await getLeaguesAndCups(1, id);
        setLeaguesAndCups(leaguesResponse);

        const descriptiveResponse = await getDescriptiveStats(
          id,
          headerResponse.params.last_season
        );

        setDescriptiveStats(configureStats(descriptiveResponse));

        try {
          setLoadingSeason(true);
          await sleep(500);
          const statsResponse = await getLeagueStats(
            id,
            headerResponse.params.league.stats.default.name,
            headerResponse.params.league.stats.default.time
          );

          setTableStats(prepareStatsTable(statsResponse.data));
          setStatsStatus(statsResponse.status);
        } catch (error) {
          if (error.response) {
            console.error("Status", error.response.status);
          } else if (error.request) {
            console.error("Error of request:", error.request);
          } else {
            console.error("Simle error is:", error);
          }
        } finally {
          setLoadingSeason(false);
        }

        try {
          setLoadingReferees(true);
          await sleep(500);
          const refereesResponse = await getLeagueReferees(
            headerResponse?.params?.team?.stat_type,
            headerResponse?.params?.team?.count,
            id,
            prepareFilters({
              time: times,
              seasons: seasons,
            })
          );

          setTableReferees(prepareRefereesTable(refereesResponse.data));
          setRefereeStatus(refereesResponse.status);
        } catch (error) {
          if (error.response) {
            console.error("Status", error.response.status);
          } else if (error.request) {
            console.error("Error of request:", error.request);
          } else {
            console.error("Simle error is:", error);
          }
        } finally {
          setLoadingReferees(false);
        }

        const standingsResponse = await getLeagueStandings(
          id,
          headerResponse.params.last_season
        );
        setTableStandings(prepareStandingsTable(standingsResponse));
      };
      fetch();
    }
  }, [id]);

  const setStatType = (activeKey) => {
    setFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setSeasonStatType = (activeKey) => {
    setStatsBySeason((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setSeasonTime = (activeKey) => {
    const time = statsBySeason?.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setStatsBySeason((prev) => ({ ...prev, time: newTime }));
  };

  const setCount = (activeKey) => {
    setFilters((prev) => ({ ...prev, count: activeKey }));
  };

  const setSeasons = (activeSeason) => {
    const league = filters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newSeason[key] =
        (key === activeSeason && !league[key]) ||
        (league[key] && activeSeason !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setTime = (activeKey) => {
    const time = filters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setPlace = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      place: activeKey.charAt(0).toUpperCase() + activeKey.slice(1),
    }));
  };

  const onLoadMoreClick = async (type, page) => {
    if (type === "previous") {
      if (
        page <= Math.ceil(matches.previous.total / matches.previous.per_page)
      ) {
        const previousResponse = await getPreviousFixtures(page, id);
        setMatches((prev) => ({
          ...prev,
          previous: ((previousPrev) => ({
            ...previousPrev,
            page: previousResponse.page,
            results: prev.previous.results.concat(previousResponse.results),
          }))(prev.previous),
        }));
      }
    } else {
      if (page <= Math.ceil(matches.next.total / matches.next.per_page)) {
        const nextResponse = await getNextFixtures(page, id);
        setMatches((prev) => ({
          ...prev,
          next: ((previousNext) => ({
            ...previousNext,
            page: nextResponse.page,
            results: prev.next.results.concat(nextResponse.results),
          }))(prev.next),
        }));
      }
    }
  };

  const onLoadMoreClickOther = async (page) => {
    if (page <= Math.ceil(leaguesAndCups.total / leaguesAndCups.per_page)) {
      const leaguesResponse = await getLeaguesAndCups(page, id);
      setLeaguesAndCups((prev) => ({
        ...prev,
        page: leaguesResponse.page,
        results: prev.results.concat(leaguesResponse.results),
      }));
    }
  };

  const onTeamsApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoadingTeams(true);
        await sleep(500);
        const response = await getLeagueTeams(
          filters.stat_type,
          filters.count,
          id,
          filters.place,
          prepareFilters({ seasons: filters.seasons, time: filters.time })
        );

        setTableTeams(prepareTeamsTable(response.data));
        setLeagueStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingTeams(false);
      }
    } else {
      try {
        setLoadingTeams(true);
        await sleep(500);
        const response = await getLeagueTeams(
          filters.stat_type,
          filters.count,
          id,
          filters.place,
          prepareFilters({ seasons: filters.seasons, time: timerange })
        );

        setTableTeams(prepareTeamsTable(response.data));
        setLeagueStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingTeams(false);
      }
    }
  };

  const onStatsApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoadingSeason(true);
        await sleep(500);
        const response = await getLeagueStats(
          id,
          statsBySeason.stat_type,
          prepareStatsTime(statsBySeason.time)
        );
        setTableStats(prepareStatsTable(response.data));
        setStatsStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingSeason(false);
      }
    } else {
      try {
        setLoadingSeason(true);
        await sleep(500);
        const response = await getLeagueStats(
          id,
          statsBySeason.stat_type,
          prepareStatsTime(timerange)
        );
        setTableStats(prepareStatsTable(response));
        setStatsStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingSeason(false);
      }
    }
  };

  const onRefereeApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoadingReferees(true);
        await sleep(500);
        const response = await getLeagueReferees(
          filters.stat_type,
          filters.count,
          id,
          prepareFilters({ seasons: filters.seasons, time: filters.time })
        );
        setTableReferees(prepareRefereesTable(response.data));
        setRefereeStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingReferees(false);
      }
    } else {
      try {
        setLoadingReferees(true);
        await sleep(500);
        const response = await getLeagueReferees(
          filters.stat_type,
          filters.count,
          id,
          prepareFilters({ seasons: filters.seasons, time: timerange })
        );
        setTableReferees(prepareRefereesTable(response));
        setRefereeStatus(response.status);
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingReferees(false);
      }
    }
  };

  const changeTableColors = () => {
    setTableWithColors(!tableWithColors);
  };

  return {
    header,
    teams,
    tableTeams,
    tableStats,
    tableReferees,
    tableStandings,
    minutes,
    matches,
    statsBySeason,
    descriptiveStats,
    filters,
    timerange,
    hasTimerange,
    leaguesAndCups,
    loadingTeams,
    loadingReferees,
    loadingSeason,
    tableWithColors,
    leagueStatus,
    statsStatus,
    refereeStatus,
    setStatType,
    setSeasonStatType,
    setSeasonTime,
    setCount,
    setSeasons,
    setTime,
    setPlace,
    onTeamsApplyClick,
    onStatsApplyClick,
    onLoadMoreClick,
    onRefereeApplyClick,
    onLoadMoreClickOther,
    changeTableColors,
  };
};
