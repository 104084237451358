import "./LeagueBar.scss"

import { PROD_URL } from "../../../../http"

export const LeagueBar = ({
  league,
  country,
  general_name,
  image_path,
  stats,
}) => {
  return (
    <div className="league-bar">
      <div style={{ display: "flex", alignItems: "center" }}>
        <p className="league-bar__league">{country}</p>
        <img src={PROD_URL + image_path} alt="country" className="league-bar__img" />
        <p className="league-bar__name">{league}</p>
        <p className="league-bar__status">Free</p>
      </div>
      <div className="league-bar-stats">
        {Object.entries(stats || {}).map(([key, value]) => (
          <p key={key} className="league-bar-stats__item">
            Avg. total of {key}: <span>{value.toFixed(2)}</span>
          </p>
        ))}
      </div>
    </div>
  )
}
