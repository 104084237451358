import { Layout } from "../../layouts/Layout";
import "../../assets/styles/trends.scss";

export const Trends = () => {
  const tiles = [
    {
      name: "BTTS",
      link: "",
    },
    {
      name: "BTTS - No",
      link: "",
    },
    {
      name: "Under 1,5",
      link: "",
    },
    {
      name: "Over 1,5",
      link: "",
    },
    {
      name: "Under 2,5",
      link: "",
    },
    {
      name: "Over 2,5",
      link: "",
    },
    {
      name: "2-3 goals",
      link: "",
    },
    {
      name: "1st Half and Match win",
      link: "",
    },
    {
      name: "BTTS + Win",
      link: "",
    },
    {
      name: "Clean Sheets + Win",
      link: "",
    },
    {
      name: "Over 2,5 + win",
      link: "",
    },
    {
      name: "Under 2,5 + Win",
      link: "",
    },
  ];
  return (
    <Layout>
      <div className="container">
        <h1 className="title trends__title">Trends</h1>
        <div className="trends">
          {tiles.map((tile) => (
            <div
              key={tile}
              className="trends__tile">
              {tile.name}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
