import { configureStore } from "@reduxjs/toolkit"
import { authSlice } from "./authSlice"
import { themeSlice } from "./themeSlice"
import { paginatorSlice } from "./paginatorSlice"

export const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
    auth: authSlice.reducer,
    paginator: paginatorSlice.reducer,
  },
})
