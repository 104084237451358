import "./OthersCard.scss";
import { Link } from "react-router-dom";

export const OthersCard = ({ data }) => {
  return (
    <div className="others-card">
      <div style={{ display: "flex", height: 14 }}>
        <p className="others-card__col">Referee</p>
        <p className="others-card__col">M</p>
        <p className="others-card__col">Avg. yellow cards</p>
      </div>
      {data?.map((item) => (
        <div
          key={item.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Link to={`/referee/${item?.id}`} className="others-card__text">
            {item?.name}
          </Link>
          <p className="others-card__text">{item?.m}</p>
          <p className="others-card__text">{item?.avg?.toFixed(2)}</p>
        </div>
      ))}
    </div>
  );
};
