import React from "react"
import "./AverageStatsCard.scss"

export const AverageStatsCard = ({
  descriptiveStats,
  side,
  first_categ,
  second_categ,
  third_categ,
  fourth_categ,
}) => {
  return (
    <div className="average-stats__card">
      <div className="average-stats__card-line">
        <span>Average number of {first_categ}:</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.fouls
            : descriptiveStats?.ballPos}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {first_categ} (Home team):</span>
        <span style={{ fontWeight: 500 }}>
          {" "}
          {side === "left"
            ? descriptiveStats?.foulsHome
            : descriptiveStats?.ballPosHome}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {first_categ} (Away team):</span>
        <span style={{ fontWeight: 500 }}>
          {" "}
          {side === "left"
            ? descriptiveStats?.foulsAway
            : descriptiveStats?.ballPosAway}
        </span>
      </div>
      <div className="line"></div>
      <div className="average-stats__card-line">
        <span>Average number of {second_categ}:</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.shotsTotal
            : descriptiveStats?.goalKicks}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {second_categ} (Home team):</span>
        <span style={{ fontWeight: 500 }}>
          {" "}
          {side === "left"
            ? descriptiveStats?.shotsTotalHome
            : descriptiveStats?.goalKicksHome}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {second_categ} (Away team):</span>
        <span style={{ fontWeight: 500 }}>
          {" "}
          {side === "left"
            ? descriptiveStats?.shotsTotalAway
            : descriptiveStats?.goalKicksAway}
        </span>
      </div>
      <div className="line"></div>
      <div className="average-stats__card-line">
        <span>Average number of {third_categ}:</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.offsides
            : descriptiveStats?.corners}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {third_categ} (Home team):</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.offsidesHome
            : descriptiveStats?.cornersHome}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {third_categ} (Away team):</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.offsidesAway
            : descriptiveStats?.cornersAway}
        </span>
      </div>
      <div className="line"></div>
      <div className="average-stats__card-line">
        <span>Average number of {fourth_categ}:</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.throwins
            : descriptiveStats?.yellowCards}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {fourth_categ} (Home team):</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.throwinsHome
            : descriptiveStats?.yellowCardsHome}
        </span>
      </div>
      <div className="average-stats__card-line">
        <span>Average number of {fourth_categ} (Away team):</span>
        <span style={{ fontWeight: 500 }}>
          {side === "left"
            ? descriptiveStats?.throwinsAway
            : descriptiveStats?.yellowCardsAway}
        </span>
      </div>
    </div>
  )
}
