import React from "react";
import { Layout } from "../../layouts/Layout";
import { useRefereeStats } from "./hooks/useRefereeStats";
import { useLocation } from "react-router-dom";
import { SelectorFilter } from "../../components/SelectorFilter/SelectorFilter";
import { Table } from "../../components/Table/Table";
import { LineFilter } from "../../components/LineFilter/LineFilter";
import { NumberFilter } from "../../components/NumberFilter/NumberFilter";
import { TimeFilter } from "../../components/TimeFilter/TimeFIlter";
import { TeamRankedFilter } from "../../components/TeamRankedFilter/TeamRankedFilter";
import { HorizontalTable } from "../../components/HorizontalTable/HorizontalTable";
import { RefereesCard } from "./components/RefereesCard/RefereesCard";

export const Referee = () => {
  const referee_id =
    +useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const {
    refsList,
    filters,
    refereeData,
    seasonFilters,
    tableSeason,
    tableTournaments,
    tournamentsFilters,
    timerange,
    tableRefereeFixtures,
    tableRefereeOdds,
    seasonsType,
    tournamentsType,
    tableRefereeManual,
    loading,
    loadingSeason,
    loadingTournament,
    refereeStatus,
    seasonStatus,
    tournamentStatus,
    setSeasonsSums,
    setTournamentsSums,
    setRefereeTableTotalsManual,
    setRanked,
    setOnlyWith,
    setTime,
    setAllLeagues,
    setStatType,
    setSeasons,
    setLeagues,
    setCount,
    setAllSeasons,
    setSeasonSeasons,
    setTournamentsLeagues,
    onSeasonApplyClick,
    onTournamentsApplyClick,
    onRefereeApplyClick,
    onLoadClick,
  } = useRefereeStats(referee_id);

  return (
    <Layout>
      <div className="container">
        <div className="team">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="title team__title">
                Statistics of the referee {refereeData?.referee?.name}
              </p>
              <div style={{ marginBottom: 43 }}>
                <h2 style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
                  Stats by seasons
                </h2>
                <div
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    gap: 20,
                  }}
                >
                  <SelectorFilter
                    filters={refereeData?.params?.seasons || []}
                    setFilter={setSeasonSeasons}
                    activeFilter={
                      seasonFilters?.season_name ||
                      refereeData?.params?.fixtures?.default?.seasons[0]
                    }
                  />
                  <LineFilter
                    styles={{
                      borderRadius: "100px",
                    }}
                    filters={["Average", "Summary"]}
                    setFilter={setSeasonsSums}
                    activeFilter={seasonsType === "sum" ? "Summary" : "Average"}
                  />
                  <button
                    className="stats__button"
                    onClick={onSeasonApplyClick}
                    style={{ marginLeft: 0 }}
                  >
                    Apply
                  </button>
                </div>
                {!tableSeason?.table.length && !loadingSeason ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        Unfortunately, don't have data on these statistical
                        metrics. Perhaps we'll include them in the future!
                      </p>
                    </div>
                  </div>
                ) : seasonStatus === 422 ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        The filters you've applied don't yield any results. Try
                        a different combination to find the information you're
                        looking for.
                      </p>
                    </div>
                  </div>
                ) : (
                  <Table
                    headerItems={[
                      { text: "Season" },
                      { text: "Matches" },
                      { text: "YC" },
                      { text: "RC" },
                      { text: "PN" },
                      { text: "F" },
                    ]}
                    colsWidth="230px 124px 124px 124px 124px 124px"
                    data={tableSeason}
                    summaried
                    loading={loadingSeason}
                  />
                )}
              </div>
              <div>
                <h2 style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
                  Stats by tournaments
                </h2>
                <div
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    gap: 20,
                  }}
                >
                  <SelectorFilter
                    activeFilter={
                      tournamentsFilters?.league_name ||
                      refereeData?.params?.leagues[0].name
                    }
                    filters={
                      refereeData?.params?.leagues.map(
                        (league) => league.name
                      ) || []
                    }
                    setFilter={setTournamentsLeagues}
                  />
                  <LineFilter
                    styles={{
                      borderRadius: "100px",
                    }}
                    filters={["Average", "Summary"]}
                    setFilter={setTournamentsSums}
                    activeFilter={
                      tournamentsType === "sum" ? "Summary" : "Average"
                    }
                  />
                  <button
                    className="stats__button"
                    onClick={onTournamentsApplyClick}
                    style={{ marginLeft: 0 }}
                  >
                    Apply
                  </button>
                </div>
                {!tableTournaments?.table.length && !loadingTournament ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        Unfortunately, don't have data on these statistical
                        metrics. Perhaps we'll include them in the future!
                      </p>
                    </div>
                  </div>
                ) : tournamentStatus === 422 ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        The filters you've applied don't yield any results. Try
                        a different combination to find the information you're
                        looking for.
                      </p>
                    </div>
                  </div>
                ) : (
                  <Table
                  headerItems={[
                    { text: "Season" },
                    { text: "Matches" },
                    { text: "YC" },
                    { text: "RC" },
                    { text: "PN" },
                    { text: "F" },
                  ]}
                  colsWidth="230px 124px 124px 124px 124px 124px"
                  data={tableTournaments}
                  summaried
                  loading={loadingTournament}
                />
                )}
                
              </div>
            </div>
            <RefereesCard data={refsList} onLoadMoreClick={onLoadClick} />
          </div>

          <div
            style={{
              display: "flex",
              gap: 20,
              flexWrap: "wrap",
              marginTop: 84,
            }}
          >
            <LineFilter
              styles={{
                borderRadius: "100px 0 0 100px",
              }}
              filters={refereeData?.params?.stat_types?.main || []}
              setFilter={setStatType}
              activeFilter={
                filters?.stat_type || refereeData?.params?.default?.stat_type
              }
              others={refereeData?.params?.stat_types?.other || []}
            />
            <LineFilter
              activeFilter={
                filters?.leagues || refereeData?.params?.default?.leagues
              }
              filters={filters?.leagues || []}
              setFilter={setLeagues}
              setAll={setAllLeagues}
              isLeagues
              withAll
            />
            <NumberFilter
              activeFilter={
                filters?.count || refereeData?.params?.default?.count
              }
              filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
              setFilter={setCount}
            />
            <LineFilter
              styles={{
                borderRadius: "100px",
              }}
              filters={filters?.seasons || []}
              setFilter={setSeasons}
              activeFilter={
                filters?.seasons || refereeData?.params?.default?.seasons[0]
              }
              setAll={setAllSeasons}
              withAll
            />
            <TimeFilter
              activeFilter={filters?.time || refereeData?.params?.default?.time}
              filters={filters?.time || {}}
              setFilter={setTime}
              timerangeValues={timerange}
              withRange={refereeData?.params?.stat_types_minute?.main?.includes(
                filters?.stat_type
              )}
            />
            <LineFilter
              styles={{
                borderRadius: "100px",
              }}
              filters={["All"]}
              setFilter={setOnlyWith}
              activeFilter={
                filters?.only_with?.charAt(0).toUpperCase() +
                  filters?.only_with?.slice(1) ||
                refereeData?.params?.default?.only_with
                  ?.charAt(0)
                  .toUpperCase() +
                  refereeData?.params?.default?.only_with?.slice(1)
              }
            />
            <TeamRankedFilter
              activeFilter={filters?.against_teams || ""}
              setFilter={setRanked}
            />
            <button className="stats__button" onClick={onRefereeApplyClick}>
              Apply
            </button>
          </div>

          {!tableRefereeFixtures?.table.length && !loading ? (
            <div className="no-data" style={{ marginTop: 100 }}>
              <div className="no-data__block">
                <h4>OOPS!</h4>
                <p>
                  Unfortunately, don't have data on these statistical metrics.
                  Perhaps we'll include them in the future!
                </p>
              </div>
            </div>
          ) : refereeStatus === 422 ? (
            <div className="no-data" style={{ marginTop: 100 }}>
              <div className="no-data__block">
                <h4>OOPS!</h4>
                <p>
                  The filters you've applied don't yield any results. Try a
                  different combination to find the information you're looking
                  for.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div style={{ marginTop: 100 }}>
                <Table
                  headerItems={[
                    { text: "Wins" },
                    { text: "Draws" },
                    { text: "Away wins" },
                    { text: "Max. Total" },
                    { text: "Min.Total" },
                    { text: "Avg. individual Total of the home team" },
                    { text: "Avg. individual Total of the away team" },
                    { text: "Avg. total" },
                  ]}
                  colsWidth="161px 161px 161px 161px 161px 161px 161px 161px"
                  data={tableRefereeManual}
                  summaried
                />
                <Table
                  headerItems={[
                    { text: "Season" },
                    { text: "Date" },
                    { text: "Home" },
                    { text: "T 1" },
                    { text: "T 2" },
                    { text: "Away" },
                    { text: "T" },
                    { text: "R" },
                    { text: "Score" },
                  ]}
                  colsWidth="32px 190px 70px 350px 40px 40px 350px 30px 30px 162px"
                  data={{ table: tableRefereeFixtures?.table }}
                  redCards={tableRefereeFixtures?.redCards}
                  showWinner
                  withCheckbox
                  setTableTotals={setRefereeTableTotalsManual}
                  loading={loading}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <h2
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 20,
                  }}
                >
                  Betting tables
                </h2>
                <div style={{ display: "flex", marginBottom: 20 }}>
                  <HorizontalTable
                    headerItems={[
                      { text: "Win" },
                      { text: "Draw" },
                      { text: "Lost" },
                    ]}
                    colsWidth="120px 70px"
                    data={{ table: tableRefereeOdds?.sums }}
                  />
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <Table
                    headerItems={[
                      { text: "" },
                      { text: "Over" },
                      { text: "Under" },
                    ]}
                    colsWidth="40px 98px 98px"
                    data={{ table: tableRefereeOdds?.table[0]["t"] }}
                    showGreen
                  />
                  <Table
                    headerItems={[
                      { text: "" },
                      { text: "T1O" },
                      { text: "T1U" },
                    ]}
                    colsWidth="40px 98px 98px"
                    data={{ table: tableRefereeOdds?.table[1]["t1"] }}
                    showGreen
                  />
                  <Table
                    headerItems={[
                      { text: "" },
                      { text: "T2O" },
                      { text: "T2U" },
                    ]}
                    colsWidth="40px 98px 98px"
                    data={{ table: tableRefereeOdds?.table[2]["t2"] }}
                    showGreen
                  />
                  <Table
                    headerItems={[{ text: "" }, { text: "H1" }]}
                    colsWidth="50px 185px"
                    data={{ table: tableRefereeOdds?.table[3]["f1"] }}
                    showGreen
                  />
                  <Table
                    headerItems={[{ text: "" }, { text: "H2" }]}
                    colsWidth="50px 185px"
                    data={{ table: tableRefereeOdds?.table[4]["f2"] }}
                    showGreen
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
