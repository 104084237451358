import { Layout } from "../../layouts/Layout"
import { Matches } from "./components/Matches/Matches"
import { OddsConverter } from "./components/OddsConverter/OddsConverter"
import { Premium } from "../../components/Premiun/Premium"
import { useState, useEffect } from "react"
import { LeaguePicker } from "./components/LeaguePicker/LeaguePicker"
import { DatePicker } from "./components/DatePicker/DatePicker"
import { League } from "./components/League/League"
import { useQuery } from "react-query"
import { getLeagues, getLeaguesLive } from "./http"
import "../../assets/styles/main.scss"
import "./main.scss"

export const Main = () => {
  useEffect(() => {
    document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + (1 / window.devicePixelRatio));

    const handleResize = () => {
      setIsAdaptive(window.innerWidth <= 1236);
    };

    window.addEventListener('resize', handleResize);

    // Убираем слушателя событий при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  const [activeTab, setActiveTab] = useState("By dates")
  const [activeDate, setActiveDate] = useState(
    new Date().toISOString().substring(0, 10)
  )
  const [isAdaptive, setIsAdaptive] = useState(window.innerWidth <= 1236);



  const { data } = useQuery([activeDate, activeTab], () =>
    getLeagues(activeDate, activeTab)
  )

  const onSetTab = (e) => {
    setActiveTab(e.target.innerText)
  }

  const onSetDate = (value) => {
    setActiveDate(value)
  }

  return (
    <Layout>
      <Matches />
      {!isAdaptive ? (
        <div className="container main-container">
          <LeaguePicker activeTab={activeTab} onSetTab={onSetTab} />
          <div className="main-content">
            <div className="leagues">
              {activeTab !== "Live" && <DatePicker activeDate={activeDate} onSetDate={onSetDate} />}
              {data &&
                Object.keys(data).map((league) => (
                  <League matches={data[league]} name={league} />
                ))}
            </div>
            <div className="main-content__top">
              <Premium />
              {/* <OddsConverter /> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="container main-container">
          <div className="main-content__top">
            <Premium isAdaptive={isAdaptive} />
            {/* <OddsConverter isAdaptive={isAdaptive} /> */}
          </div>
          <LeaguePicker activeTab={activeTab} onSetTab={onSetTab} />
          <div className="main-content">
            <div className="leagues">
              {activeTab !== "Live" && <DatePicker activeDate={activeDate} onSetDate={onSetDate} />}
              {data &&
                Object.keys(data).map((league) => (
                  <League matches={data[league]} name={league} />
                ))}
            </div>
          </div>
        </div>
      )}

    </Layout>
  )
}
