import $api from "../../../http";

export const getFixtureHeader = async (id) => {
  return await $api
    .get(`/fixture/header?id=${id}`)
    .then((response) => response.data);
};

export const getTeamHeader = async (id) => {
  return await $api
    .get(`/team/header?id=${id}`)
    .then((response) => response.data);
};

export const getRefereeHeader = async (id) => {
  return await $api
    .get(`/referee/header?id=${id}`)
    .then((response) => response.data);
};

export const getFixturesStats = async (id, time) => {
  return await $api
    .post(`/fixture/statistics?id=${id}&time=${time}`)
    .then((response) => response.data);
};

export const getTeamFixtures = async (
  stat_type,
  count,
  id,
  place,
  under_active_coach,
  filters
) => {
  return await $api
    .post(
      `/team/fixtures?stat_type=${stat_type}&count=${count}&id=${id}&place=${place}&under_active_coach=${under_active_coach}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getH2HFixtures = async (
  stat_type,
  count,
  first_team_id,
  second_team_id,
  filters
) => {
  return await $api
    .post(
      `/fixture/h2h?stat_type=${stat_type}&count=${count}&first_team_id=${first_team_id}&second_team_id=${second_team_id}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getOddsByTotals = async (totals) => {
  return await $api
    .post(`/utils/odds`, totals)
    .then((response) => response.data);
};

export const getRefereeFixtures = async (
  stat_type,
  count,
  id,
  only_with,
  filters
) => {
  return await $api
    .post(
      `/referee/fixtures?stat_type=${stat_type}&count=${count}&id=${id}&only_with=${only_with}`,
      filters
    )
    .then((response) => {
      return { data: response.data, status: response.status };
    });
};

export const getRefereeSeason = async (id, season_name) => {
  return await $api
    .get(`referee/stats/season?id=${id}&season=${season_name}`)
    .then((response) => response.data);
};

export const getRefereeTournaments = async (id, league_id) => {
  return await $api
    .get(`referee/stats/league?id=${id}&league_id=${league_id}`)
    .then((response) => response.data);
};

export const getRefereeOthers = async (id) => {
  return await $api
    .get(`/referee/other?page=1&per_page=0&sort=name&order=asc&id=${id}`)
    .then((response) => response.data);
};

export const getLiveFixtures = (id) => {
  // let socket = new WebSocket(
  //   `ws://45.12.74.114/backend/api/fixture/live/trends/${id}`
  // )
  // socket.onopen = function (e) {
  //   console.log(e)
  //   socket.send("Меня зовут Джон")
  // }
  // return socket
};
