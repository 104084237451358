import React from "react"
import "./StatsPercentage.scss"

export const StatsPercentage = ({ descriptiveStats }) => {
  return (
    <div className="stats__percentage">
      <div className="stats__percentage-items">
        <div className="stats__percentage-item">
          <p className="stats__percentage-item-text">Win Home</p>
          <div
            style={{ width: `${descriptiveStats?.winHome * 8.8}px` }}
            className="stats__percentage-item-line stats__percentage-item-line-home"
          >
            {descriptiveStats?.winHome}%
          </div>
        </div>
        <div className="stats__percentage-item">
          <p
            style={{ marginLeft: -40 }}
            className="stats__percentage-item-text"
          >
            Draw
          </p>
          <div
            style={{ width: `${descriptiveStats?.draws * 8.5}px` }}
            className="stats__percentage-item-line stats__percentage-item-line-draw"
          >
            {descriptiveStats?.draws}%
          </div>
        </div>
        <div className="stats__percentage-item">
          <p
            style={{ marginLeft: -40 }}
            className="stats__percentage-item-text"
          >
            Win Away
          </p>
          <div
            style={{ width: `${descriptiveStats?.winAway * 7.7}px` }}
            className="stats__percentage-item-line stats__percentage-item-line-away"
          >
            {descriptiveStats?.winAway}%
          </div>
        </div>
      </div>
      <h3 className="stats__percentage-title">Goals</h3>
      <div className="stats__percentage-items">
        <div className="stats__percentage-item">
          <p className="stats__percentage-item-text">0-1</p>
          <div
            style={{
              width: `${descriptiveStats?.zeroToOne * 8.8}px`,
              marginLeft: 0,
            }}
            className="stats__percentage-item-line stats__percentage-item-line-goal"
          >
            {descriptiveStats?.zeroToOne}%
          </div>
        </div>
        <div className="stats__percentage-item">
          <p
            style={{ marginLeft: -40 }}
            className="stats__percentage-item-text"
          >
            2-3
          </p>
          <div
            style={{ width: `${descriptiveStats?.twoToThree * 8.5}px` }}
            className="stats__percentage-item-line stats__percentage-item-line-goal"
          >
            {descriptiveStats?.twoToThree}%
          </div>
        </div>
        <div className="stats__percentage-item">
          <p
            style={{ marginLeft: -40 }}
            className="stats__percentage-item-text"
          >
            4+
          </p>
          <div
            style={{
              width: `${descriptiveStats?.fourPlus * 7.7}px`,
              borderRadius: 100,
            }}
            className="stats__percentage-item-line stats__percentage-item-line-goal"
          >
            {descriptiveStats?.fourPlus}%
          </div>
        </div>
      </div>
    </div>
  )
}
