import { useState, useRef } from "react";
import { FileInput } from "./components/FileInput/FileInput";
import { SupportInput } from "./components/SupportInput/SupportInput";
import { Button } from "../../../../../UI/buttons/Button";
import { SupportInfo } from "./components/SupportInfo/SupportInfo";
import { Success } from "../../../../../components/Success/Success";
import { addUserTicket } from "../../../http";
import del from "../../../../../assets/svg/Multiply/24/Solid.svg";
import "./Support.scss";

export const Support = () => {
  const [files, setFiles] = useState([]);
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false);

  const onInputChange = (event) => {
    setFiles([...event.target.files]);
    // setFiles(event.target.files)
  };

  const onDeleteClick = (e) => {
    const fileName = e.target.name;
    setFiles((prev) => prev.filter((item) => item.name !== fileName));
  };

  const handleSendData = async () => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("body", message);
    // formData.append("file", files);
    for (let i = 0; i < files.length; i++) {
      // formData.append("files", files[i]);
      formData.append("file", files[i]);
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    if (subject.trim() === "" || message.trim() === "") {
      alert("Please fill in both Subject and Message fields.");
      return;
    }

    if (files.length === 0) {
      alert("Please select at least one file.");
      return;
    }
    
    const response = await addUserTicket(formData)
    console.log(response);
  };

  return (
    <div className="support">
      <Success
        onButtonClick={() => setShowModal(false)}
        show={showModal}
        check={true}
        contentText="Your message has been sent successfully."
        buttonText="Ok"
      />
      <p className="support__title">
        Contact support to get answers to your questions quickly
      </p>
      <p className="support__description">
        Please Leave A Message Using The Form Below And We Will Get Back To.
        You As Soon As Possible
      </p>
      <div className="support-content">
        <div className="support-form">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}>
            <SupportInput label="Subject" setSubject = {setSubject}/>
            <FileInput
              onInputChange={onInputChange}
              label="Attachments"
              setFiles={setFiles}
              files={files}
            />
          </div>
          {files && (
            <div className="support-form-files">
              {files?.map((file, index) => (
                <div
                  className="support-form__file"
                  key={index}>
                  {file.name}
                  <img
                    src={del}
                    onClick={onDeleteClick}
                    name={file.name}
                    alt="delete"
                  />
                </div>
              ))}
            </div>
          )}
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <label className="support-input__label">Message</label>
            <textarea
              className="support-input__input"
              style={{
                width: "100%",
                mb: "30px",
                borderRadius: "20px",
                minHeight: "140px",
              }}
              onChange={e=>setMessage(e.target.value)}
            />
          </div>
          <Button
            onClick={() => (setShowModal(true), handleSendData())}
            color="blue"
            className="support-form__button">
            Send
          </Button>
        </div>
        <div className="support-text">
          <SupportInfo>
            <p className="support-info__title">Fast Customer Support</p>
            <p className="support-info__description">
              Email is the best way to reach our team and get a prompt answer to
              any questions that you have. To send it, please use the form on
              the left, or email us directly at <span>help@statoola.com</span>
            </p>
            <p className="support-info__description">
              Please note that the working hours for our Customer Success team
              are Monday through <b>Friday, 9am to 8pm (GMT+1)</b>.
            </p>
            <p className="support-info__description">
              We reply most of the inquiries within 1 business day, if your
              email is received on the weekend or outside of our regular working
              hours we will do our best to respond it the next working day.
            </p>
          </SupportInfo>
          <SupportInfo>
            <p className="support-info__title">We Value Your Feedback</p>
            <p className="support-info__description">
              Do you have a new feature in mind that you think would make
              Statoola better? Any other suggestions, comments, or critique? We
              would be really happy to hear it! Please use the contact form on
              this page to get in touch or email us at
              <span>feedback@statoola.com</span>
            </p>
          </SupportInfo>
        </div>
      </div>
    </div>
  );
};
