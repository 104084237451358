import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userConfirm } from "./http";
import success from "../../assets/svg/confirmArrow.svg";
import "../../assets/styles/confirm.scss";

export const Confirm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [successfully, setSuccessfully] = useState(false);
  const [params] = useSearchParams();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = params.get("token");

    const fetch = async () => {
      try {
        const response = await userConfirm(token);
        if (response.status === 200) {
          setSuccessfully(true);
        }

        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (e) {
        setError(e.details);
      }
    };

    if (token) {
      setIsLoading(true);
      fetch();
      setIsLoading(false);
    }
  }, [params]);

  return (
    <div className="layout">
      <div className="confirm">
        {!isLoading && (
          <div className="confirm-message">
            <img
              src={successfully ? success : ""}
              alt=""
              className="confirm__status"
            />
            <p className="confirm__text">
              {successfully
                ? "Verified! You have successfully verified an email."
                : error}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
