export const prepareWithPrev = (filters, currentLeagues) => {
    const leaguesArr = [];
    let newTime = "";

    Object.entries(filters.leagues).forEach(([key, value]) => {
        for (const league of currentLeagues || []) {
          if (league?.name === key && value) leaguesArr.push(league?.id)
        }
      })

    if (!Object.keys(filters.time).includes("start")) {
      Object.entries(filters.time).forEach(([key, value]) => {
        if (value) {
          newTime = key;
        }
      });
    } else {
      newTime = filters.time;
    }
  
    return {
      time: newTime,
      seasons: filters.seasons,
      leagues: leaguesArr,
    };
  };
  