export const prepareFilters = (rawFilters, currentLeagues) => {
  const leaguesArr = [];
  let newTime = "";
  Object.entries(rawFilters.leagues).forEach(([key, value]) => {
    for (const league of currentLeagues || []) {
      if (league?.name === key && value) leaguesArr.push(league?.id);
    }
  });
  if (!Object.keys(rawFilters.time).includes("start")) {
    Object.entries(rawFilters.time).forEach(([key, value]) => {
      if (value) {
        newTime = key;
      }
    });
  } else {
    newTime = rawFilters.time;
  }
  return {
    ...rawFilters,
    leagues: leaguesArr,
    time: newTime,
  };
};
