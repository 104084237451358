import $api from "../../../http";

export const getUser = async () => {
  return await $api.get("/user/cabinet").then((response) => response.data);
};

export const updateUserData = async (user) => {
  return await $api
    .put("/user/profile", user)
    .then((response) => response.data);
};

export const deleteAccount = async (data) => {
  return await $api.delete("/user/remove", { data: data });
};

export const addUserTicket = async (data) => {
  return await $api
    .post("/user/ticket", data)
    .then((response) => response.data)
}
