import React from "react"
import "./Chart.scss"

export const Chart = ({ percents, interval }) => {
  const periodArr = []
  for (let i = interval; i <= percents?.length * interval; i += interval) {
    periodArr.push(`${i - interval === 0 ? 0 : i - interval + 1}-${i}`)
    if (i === 90) {
      break
    }
  }

  return (
    <div className="chart">
      {percents?.map((percent, index) => (
        <div key={index}>
          <div
            className="chart__square"
            style={{
              background: interval === 10 ? "#97CEA7" : "#5F8BFF",
              height: percent * 7.5,
              width: 300 / percents?.length,
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: interval === 10 ? 0 : 5,
                width: "100%",
              }}
            >
              <p
                // style={{ fontSize: 24 - percents?.length }}
                className="chart__square-text"
              >
                {percent > 5 && percent + "%"}
              </p>
            </div>
          </div>
          <p className="chart__square-text-mins">{periodArr[index]}</p>
        </div>
      ))}
    </div>
  )
}
