import { Layout } from "../../layouts/Layout";
import "../../assets/styles/terms.scss";
import { themeSelector } from "../../store/themeSlice";
import { useSelector } from "react-redux";

export const Terms = () => {
  const theme = useSelector(themeSelector);

  const userRepresentations = [
    {
      title:
        "Any information you provide to us is accurate, current, and complete.",
    },
    {
      title:
        "You will maintain the accuracy of such information and promptly update it as necessary.",
    },
    {
      title:
        "You have the legal capacity to enter into these Terms and to comply with them.",
    },
    {
      title:
        "Your use of the Site does not violate any applicable law or regulation.",
    },
  ];

  const prohibitedActivities = [
    {
      title:
        "Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.",
    },
    {
      title:
        "Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.",
    },
    {
      title:
        "Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.",
    },
    {
      title:
        "Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.",
    },
    {
      title:
        "Use any information obtained from the Site in order to harass, abuse, or harm another person.",
    },
    {
      title:
        "Make improper use of our support services or submit false reports of abuse or misconduct.",
    },
    {
      title:
        "Use the Site in a manner inconsistent with any applicable laws or regulations.",
    },
    {
      title: "Engage in unauthorized framing of or linking to the Site.",
    },
    {
      title:
        "Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.",
    },
    {
      title:
        "Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.",
    },
    {
      title:
        "Delete the copyright or other proprietary rights notice from any Content.",
    },
    {
      title:
        "Attempt to impersonate another user or person or use the username of another user.",
    },

    {
      title:
        "Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).",
    },
    {
      title:
        "Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.",
    },
    {
      title:
        " Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.",
    },
    {
      title:
        "Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.",
    },
    {
      title:
        "Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.",
    },
    {
      title:
        "Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.",
    },
    {
      title:
        "Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.",
    },
    {
      title:
        "Use a buying agent or purchasing agent to make purchases on the Site.",
    },
    {
      title:
        "Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.",
    },
    {
      title:
        " Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.",
    },
    {
      title: " Use the Site to advertise or offer to sell goods and services.",
    },
    {
      title: " Sell or otherwise transfer your profile.",
    },
  ];

  const userGeneratedContributions = [
    {
      title:
        "The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.",
    },
    {
      title:
        "You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.",
    },
    {
      title:
        "You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.",
    },
    {
      title: "Your Contributions are not false, inaccurate, or misleading.",
    },
    {
      title:
        "Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.",
    },
    {
      title:
        "Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).",
    },
    {
      title:
        "Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.",
    },
    {
      title:
        "Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.",
    },
    {
      title:
        "Your Contributions do not violate any applicable law, regulation, or rule.",
    },
    {
      title:
        "Your Contributions do not violate the privacy or publicity rights of any third party.",
    },
    {
      title:
        "Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.",
    },
    {
      title:
        "Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.",
    },
    {
      title:
        "Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.",
    },
  ];

  const siteManagement = [
    {
      title: "monitor the Site for violations of these Terms of User;",
    },
    {
      title:
        "take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities;",
    },
    {
      title:
        "in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;",
    },
    {
      title:
        "in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;",
    },
    {
      title:
        "otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.",
    },
  ];

  const userGeneratedContributionsSecond = [
    {
      title: "Do not infringe upon the rights of any third party.",
    },
    {
      title:
        "Are original and owned by you or used with appropriate permissions.",
    },
    {
      title: "Do not violate any laws or regulations.",
    },
    {
      title: "Are not false, misleading, or objectionable.",
    },
    {
      title: "Do not harass, ridicule, or abuse others.",
    },
    {
      title: "Do not violate privacy or publicity rights.",
    },
    {
      title: "Do not contain offensive comments.",
    },
  ];

  const indemnification = [
    {
      title: "your Contributions;",
    },
    {
      title: "use of the Site;",
    },
    {
      title: "breach of these Terms of Use;",
    },
    {
      title:
        "any breach of your representations and warranties set forth in these Terms of Use;",
    },
    {
      title:
        "your violation of the rights of a third party, including but not limited to intellectual property rights;",
    },
    {
      title:
        "any overt harmful act toward any other user of the Site with whom you connected via the Site.",
    },
  ];

  return (
    <Layout>
      <div className="container">
        <div className={`terms__block terms__block_${theme}`}>
          <h1>TERMS OF SERVICE</h1>
          <h3>ACCEPTANCE OF TERMS</h3>
          <p>
            Welcome to Statoola.com ("Statoola", "we", "us", or "our"). These
            Terms of Service ("Terms") govern your access to and use of the
            Statoola.com website (the "Site") and any related services,
            applications, or platforms provided by KRONX LLC ("KRONX", "we",
            "us", or "our"). By accessing or using the Site, you agree to be
            bound by these Terms and our Privacy Policy. If you do not agree to
            these Terms or the Privacy Policy, you may not use the Site.
          </p>
          <p>
            We reserve the right to modify or replace these Terms at any time by
            posting the updated Terms on this page. It is your responsibility to
            review these Terms periodically for changes. Your continued use of
            the Site after any such changes constitutes your acceptance of the
            new Terms.
          </p>
          <h3>ACCESS AND USE OF THE SITE</h3>
          <p>
            The information provided on the Site is intended for individuals and
            entities located in the United States. By accessing the Site, you
            represent and warrant that you are not prohibited by law from using
            the Site and that you will comply with all applicable laws and
            regulations.
          </p>
          <p>
            The Site is not intended for use by individuals under the age of 13.
            If you are under 13 years old, you may not use the Site. If you are
            between the ages of 13 and 18, you may only use the Site with the
            consent and supervision of a parent or legal guardian.
          </p>
          <h3>INTELLECTUAL PROPERTY</h3>
          <p>
            The Site and all of its original content, features, and
            functionality are owned by KRONX LLC and are protected by
            international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws.
          </p>
          <p>
            Subject to these Terms, KRONX grants you a limited, non-exclusive,
            non-transferable, and revocable license to access and use the Site
            for your personal, non-commercial use.
          </p>
          <h3>USER REPRESENTATIONS</h3>
          <p>By using the Site, you represent and warrant that:</p>
          <ul>
            {userRepresentations.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  &#8226;
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <h3>USER REGISTRATION</h3>
          <p>
            You may be required to register an account with Statoola to access
            certain features of the Site. You are responsible for maintaining
            the confidentiality of your account credentials and for all
            activities that occur under your account. You agree to notify us
            immediately of any unauthorized use of your account or any other
            breach of security.
          </p>
          <h3>FEES AND PAYMENT</h3>
          <p>
            Certain features or services offered on the Site may require payment
            of fees. By purchasing such features or services, you agree to pay
            all applicable fees and charges. All payments are processed securely
            through our designated payment processors.
          </p>
          <h3>CANCELLATION AND REFUND POLICY</h3>
          <p>
            All purchases made on the Site are non-refundable. You may cancel
            your subscription at any time by accessing your account settings.
            Your cancellation will take effect at the end of the current billing
            cycle.
          </p>
          <h3>PROHIBITED ACTIVITIES</h3>
          <p>
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.
          </p>
          <p>As a user of the Site, you agree not to:</p>
          <ul>
            {prohibitedActivities.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  {index + 1}.
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <h3>USER GENERATED CONTRIBUTIONS</h3>
          <p>
            The Site may invite you to chat, contribute to, or participate in
            blogs, message boards, online forums, and other functionality, and
            may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Site, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, "Contributions"). Contributions may be viewable by
            other users of the Site and through third-party websites. As such,
            any Contributions you transmit may be treated as non-confidential
            and non-proprietary. When you create or make available any
            Contributions, you thereby represent and warrant that:
          </p>
          <ul>
            {userGeneratedContributions.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  {index + 1}.
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <p>
            Any use of the Site in violation of the foregoing violates these
            Terms of Use and may result in, among other things, termination or
            suspension of your rights to use the Site.
          </p>
          <h3>CONTRIBUTION LICENSE</h3>
          <p>
            By posting your Contributions to any part of the Site, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels.
          </p>
          <p>
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </p>
          <p>
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Site. You are solely responsible for your Contributions to the
            Site and you expressly agree to exonerate us from any and all
            responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </p>
          <p>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations on the
            Site; and (3) to pre-screen or delete any Contributions at any time
            and for any reason, without notice. We have no obligation to monitor
            your Contributions.
          </p>
          <h3>SUBMISSIONS</h3>
          <p>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>
          <h3>THIRD-PARTY WEBSITES AND CONTENT</h3>
          <p>
            The Site may contain (or you may be sent via the Site) links to
            other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices,
            of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
          <h3>ADVERTISERS</h3>
          <p>
            We allow advertisers to display their advertisements and other
            information in certain areas of the Site, such as sidebar
            advertisements or banner advertisements. If you are an advertiser,
            you shall take full responsibility for any advertisements you place
            on the Site and any services provided on the Site or products sold
            through those advertisements. Further, as an advertiser, you warrant
            and represent that you possess all rights and authority to place
            advertisements on the Site, including, but not limited to,
            intellectual property rights, publicity rights, and contractual
            rights. We simply provide the space to place such advertisements,
            and we have no other relationship with advertisers.
          </p>
          <h3>U.S. GOVERNMENT RIGHTS</h3>
          <p>
            Our services are “commercial items” as defined in Federal
            Acquisition Regulation (“FAR”) 2.101. If our services are acquired
            by or on behalf of any agency not within the Department of Defense
            (“DOD”), our services are subject to the terms of these Terms of Use
            in accordance with FAR 12.212 (for computer software) and FAR 12.211
            (for technical data). If our services are acquired by or on behalf
            of any agency within the Department of Defense, our services are
            subject to the terms of these Terms of Use in accordance with
            Defense Federal Acquisition Regulation (“DFARS”) 227.7202‑3. In
            addition, DFARS 252.227‑7015 applies to technical data acquired by
            the DOD. This U.S. Government Rights clause is in lieu of, and
            supersedes, any other FAR, DFARS, or other clause or provision that
            addresses government rights in computer software or technical data
            under these Terms of Use.
          </p>
          <h3>SITE MANAGEMENT</h3>
          <p>We reserve the right, but not the obligation, to:</p>
          <ul>
            {siteManagement.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  ({index + 1})
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <h3>PROHIBITED ACTIVITIES</h3>
          <p>
            Your use of the Statoola.com website ("Site") is subject to the
            following restrictions:
          </p>
          <p>
            Unauthorized Data Collection: You may not systematically retrieve
            data or content from the Site without our written permission.
          </p>
          <p>
            Fraudulent Activities: Do not attempt to trick, defraud, or mislead
            us or other users of the Site.
          </p>
          <p>
            Security Interference: Do not circumvent, disable, or disrupt any
            security features of the Site.
          </p>
          <p>
            Harm to Reputation: Do not engage in activities that could disparage
            or harm our reputation or the Site.
          </p>
          <p>
            Harassment: Do not use information obtained from the Site to harass,
            abuse, or harm others.
          </p>
          <p>
            Misuse of Support Services: Do not misuse our support services or
            submit false reports.
          </p>
          <p>
            Illegal Use: Your use of the Site must comply with all applicable
            laws and regulations.
          </p>
          <p>
            Unauthorized Linking: Do not engage in unauthorized framing or
            linking to the Site.
          </p>
          <p>
            Malicious Software: Do not upload viruses or other harmful material
            to the Site.
          </p>
          <p>
            Automated Use: Do not engage in automated use of the Site or data
            mining without authorization.
          </p>
          <p>
            Copyright Violation: Do not delete copyright notices or infringe
            upon intellectual property rights.
          </p>
          <p>Impersonation: Do not impersonate another user or person.</p>
          <p>
            Information Collection Mechanisms: Do not use the Site to transmit
            spyware or similar devices.
          </p>
          <p>
            Disruption: Do not interfere with the proper functioning of the
            Site.
          </p>
          <p>
            Employee Harassment: Do not harass, annoy, or threaten our employees
            or agents.
          </p>
          <p>
            Bypassing Measures: Do not attempt to bypass security measures of
            the Site.
          </p>
          <p>
            Software Manipulation: Do not copy or adapt the Site's software
            without authorization.
          </p>
          <p>
            Reverse Engineering: Do not attempt to reverse engineer the Site's
            software.
          </p>
          <p>
            Unauthorized Automation: Do not use unauthorized scripts or software
            to access the Site.
          </p>
          <p>
            Buying Agents: Do not use buying agents to make purchases on the
            Site.
          </p>
          <p>
            Unauthorized Use: Do not create user accounts by automated means or
            under false pretenses.
          </p>
          <p>
            Competitive Use: Do not use the Site to compete with us or for any
            commercial enterprise.
          </p>
          <p>
            Advertising: Do not use the Site to advertise goods or services.
          </p>
          <p>Profile Transfer: Do not sell or transfer your user profile.</p>
          <h3>USER GENERATED CONTRIBUTIONS</h3>
          <p>
            When you contribute to the Site, you agree that your contributions:
          </p>
          <ul>
            {userGeneratedContributionsSecond.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  &#8226;
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <h3>CONTRIBUTION LICENSE</h3>
          <p>
            By posting contributions to the Site, you grant us a license to use,
            reproduce, and distribute them for any lawful purpose.
          </p>
          <h3>MOBILE APPLICATION LICENSE</h3>
          <p>
            If you access the Site via a mobile application, you agree to use
            the application strictly in accordance with our terms.
          </p>
          <h3>SUBMISSIONS</h3>
          <p>
            Any submissions you provide to us become our property, and we may
            use them for any lawful purpose.
          </p>
          <h3>THIRD-PARTY WEBSITES AND CONTENT</h3>
          <p>
            We are not responsible for the content of third-party websites
            linked to the Site.
          </p>
          <h3>ADVERTISERS</h3>
          <p>
            Advertisers are solely responsible for the content of their
            advertisements on the Site.
          </p>
          <h3>U.S. GOVERNMENT RIGHTS</h3>
          <p>Our services are subject to U.S. government regulations.</p>
          <h3>SITE MANAGEMENT</h3>
          <p>
            We reserve the right to manage the Site to protect our rights and
            property.
          </p>
          <h3>TERM AND TERMINATION</h3>
          <p>
            These Terms of Use shall remain effective as long as you use the
            Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
            RESERVE THE RIGHT, AT OUR SOLE DISCRETION AND WITHOUT NOTICE OR
            LIABILITY, TO DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
            CERTAIN IP ADDRESSES) TO ANY INDIVIDUAL FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
            USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
          <h3>MODIFICATIONS AND INTERRUPTIONS</h3>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site.
          </p>
          <p>
            We cannot guarantee the Site will be available at all times. We may
            experience hardware, software, or other problems or need to perform
            maintenance related to the Site, resulting in interruptions, delays,
            or errors. We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.
          </p>
          <h3>GOVERNING LAW</h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the US. The operating company of https://statoola.com,
            and you hereby submit to the exclusive jurisdiction of the courts of
            the US to resolve any dispute arising under or relating to these
            terms.
          </p>
          <h3>DISPUTE RESOLUTION</h3>
          <h4>Informal Negotiations</h4>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Use (each "Dispute"
            and collectively, the “Disputes”) brought by either you or us
            (individually, a “Party” and collectively, the “Parties”), the
            Parties agree to first attempt to negotiate any Dispute informally
            for at least thirty (30) days before initiating arbitration. Such
            informal negotiations commence upon written notice from one Party to
            the other Party.
          </p>
          <h4>Restrictions</h4>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>
          <h4>Exceptions to Informal Negotiations and Arbitration</h4>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <h3>CORRECTIONS</h3>
          <p>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>
          <h3>DISCLAIMER</h3>
          <p>
            THE SITE IS PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. YOU
            AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
            SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>
          <h3>LIMITATIONS OF LIABILITY</h3>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE
            THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN
            US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
            ADDITIONAL RIGHTS.
          </p>
          <h3>INDEMNIFICATION</h3>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:
          </p>
          <ul>
            {indemnification.map((item, index) => (
              <li key={item.title}>
                <span className={index === 0 ? "terms__block__span" : ""}>
                  ({index + 1})
                </span>{" "}
                {item.title}
              </li>
            ))}
          </ul>
          <p>
            Notwithstanding the foregoing, we reserve the right, at your
            expense, to assume the exclusive defense and control of any matter
            for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
          <h3>USER DATA</h3>
          <p>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </p>
          <h3>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
          <h3>MISCELLANEOUS</h3>
          <p>
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment, or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
          </p>
          <h3>CONTACT US</h3>
          <div>
            <p>If you have any questions about these Terms, please contact us at</p>
            <a href="mailto:help@statoola.com">help@statoola.com</a>.
          </div>
        </div>
      </div>
    </Layout>
  );
};
