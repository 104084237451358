import "./Input.scss";

export const Input = (props) => {
  return (
    <div className="profile-input">
      <label className="profile-input__label">{props.label}</label>
      <input
        {...props}
        className="profile-input__input"
      />
    </div>
  );
};
