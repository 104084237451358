export const LeaguePicker = ({ activeTab, onSetTab }) => {
  return (
    <div className="leagues-picker">
      <p
        className={`leagues-picker__tab ${
          activeTab === "By dates" ? "leagues-picker__tab_active" : ""
        }`}
        onClick={onSetTab}>
        By dates
      </p>
      <p
        className={`leagues-picker__tab ${
          activeTab === "Live" ? "leagues-picker__tab_active" : ""
        }`}
        onClick={onSetTab}>
        Live
      </p>
      <p
        className={`leagues-picker__tab ${
          activeTab === "Favorites" ? "leagues-picker__tab_active" : ""
        }`}
        onClick={onSetTab}>
        Favorites
      </p>
    </div>
  );
};
