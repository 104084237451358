export const currentPrepareFilters = (rawFilters, currentLeagues) => {
  const seasonsArr = [];
  const leaguesArr = [];
  let newTime = "";
  Object.entries(rawFilters.seasons).forEach(([key, value]) => {
    if (value) {
      seasonsArr.push(key);
    }
  });
  Object.entries(rawFilters.leagues).forEach(([key, value]) => {
    for (const league of currentLeagues || []) {
      if (league?.name === key && value) leaguesArr.push(league?.id)
    }
  });
  if (!Object.keys(rawFilters.time).includes("start")) {
    Object.entries(rawFilters.time).forEach(([key, value]) => {
      if (value) {
        newTime = key;
      }
    });
  } else {
    newTime = rawFilters.time;
  }

  const ans = {
    body: {
      against_teams: rawFilters.against_teams,
      seasons: seasonsArr,
      leagues: leaguesArr,
      time: newTime,
    },
  };

  return {
    ...rawFilters,
    ans,
  };
};
