import { OrbitProgress } from "react-loading-indicators";
import { StatsRow } from "./StatsRow";
import "./StatsTable.scss";

export const StatsTable = ({
  teams,
  onLoadClick,
  onSortClick,
  activeSort,
  reverse,
  paginateInfo,
  loading = false,
}) => {
  const headerItems = [
    { text: "Total Matches", key: "count" },
    { text: "Avg. total", key: "t" },
    { text: "Avg. Individual Total", key: "t1" },
    { text: "Avg. total of the opponent", key: "t2" },
    { text: "Avg. difference", key: "difference" },
    { text: "BTTS", key: "btts" },
  ];

  if (loading) {
    return (
      <div style={{width: "100%", textAlign: "center"}}>
        <OrbitProgress
          variant="disc"
          color="#318acc"
          size="large"
          text=""
          textColor=""
        />
      </div>
    );
  }

  return (
    <>
      {teams && (
        <div className="stats-table">
          <div className="stats-table-header">
            <p>Team</p>
            {headerItems.map((item) => (
              <p
                onClick={() => onSortClick(item.key, reverse)}
                className={`${
                  activeSort === item.key
                    ? "stats-table-header__cell_active"
                    : ""
                }
                ${reverse ? "reverse" : ""}`}>
                {item.text}
              </p>
            ))}
            <p></p>
          </div>
          <div className="stats-table-body">
            {teams.map((team, index) => (
              <StatsRow
                team={team}
                key={index}
              />
            ))}
          </div>
          {paginateInfo?.page !== paginateInfo?.max_page && <p
            className="stats-table-load"
            onClick={() => onLoadClick(paginateInfo?.page + 1)}>
            Load more
          </p>}
        </div>
      )}
    </>
  );
};
