export const getDates = (currentDate) => {
  const date = new Date(currentDate);
  const yesterday = new Date(currentDate).setDate(date.getDate() - 1);
  const today = new Date(currentDate).setDate(date.getDate());
  const tomorrow = new Date(currentDate).setDate(date.getDate() + 1);
  const afterTomorrow = new Date(currentDate).setDate(date.getDate() + 2);

  return [yesterday, today, tomorrow, afterTomorrow].map((item) =>
    new Date(item).toISOString().substring(0, 10)
  );
};
