import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../utils/cookie";
import { setCookie } from "../utils/cookie";

const initialState = {
  access_token: getCookie("access_token"),
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.access_token = action.payload;
      setCookie("access_token", action.payload);
    },

    logout: (state) => {
      state.access_token = "";
      setCookie("access_token", "");
      window.location = "/login";
    },
  },
});

export const { setToken, logout } = authSlice.actions;
export const authSelector = (state) => state.auth.access_token;

export default authSlice.reducer;
