import React from "react"

export const TableFooter = ({ summary, colsWidth }) => {
  return (
    <div className="table-row" style={{ gridTemplateColumns: colsWidth }}>
      {summary &&
        Object.values(summary).map((cat, index) => (
          <span key={index} style={{ fontWeight: 600 }}>
            {cat}
          </span>
        ))}
    </div>
  )
}
