import { useSelector } from "react-redux"
import { themeSelector } from "../../../../store/themeSlice"
import ball from "../../../../assets/img/ball_bg.png"
import "./Matches.scss"

export const Matches = () => {
  const theme = useSelector(themeSelector)
  return (
    <div className="matches ">
      <div className="container matches-wrapper">
        <div className="matches-text">
          <h1 className={`matches__title  matches__title_${theme}`}>Matches</h1>
          <p className="matches__desc">
            We provide comprehensive statistics for football matches that are
            useful for both betting and general analysis purposes. Our database
            of football statistics can help you analyze matches and tournaments
            effectively.
          </p>
        </div>
      </div>
      <img className="matches-img" src={ball} alt="ball" />
    </div>
  )
}
