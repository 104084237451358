import { numToPerc } from "../../../../utils/numToPerc"
import "./StatsCard.scss"

export const StatsCard = ({ data }) => {
  return (
    <div className="stats-card">
      {Object.entries(data || {}).map(([key, value]) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 30,
            paddingBottom: 20,
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <div style={{minWidth: 30}}><p>{value?.home}</p></div>
          <div style={{ display: "flex", flexDirection: "column", gap: 27 }}>
            <h4>{key}</h4>
            <div style={{ display: "flex", width: 470}}>
              <div
                style={{
                  background: "#14151B",
                  height: 11,
                  width:
                    numToPerc(value?.home, value?.away + value?.home) > 0
                      ? 4.70 * numToPerc(value?.home, value?.away + value?.home)
                      : 4.70 * 50,
                }}
              ></div>
              <div
                style={{
                  background: "#5F8BFF",
                  height: 11,
                  width:
                    numToPerc(value?.away, value?.away + value?.home) > 0
                      ? 4.70 * numToPerc(value?.away, value?.away + value?.home)
                      : 4.70 * 50,
                }}
              ></div>
            </div>
          </div>
          <div style={{minWidth: 30, textAlign: "end"}}><p>{value?.away}</p></div>
        </div>
      ))}
    </div>
  )
}
