import { Layout } from "../../layouts/Layout";
import { Input } from "./UI/Input/Input";
import "../../assets/styles/contact-us.scss";
import { Button } from "../../UI/buttons/Button";

export const ContactUs = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="contact__title">Contact us</h1>
      </div>
      <div className="contact-us">
        <div className="container contact-us-wrapper">
          <div className="contact-us-form">
            <Input placeHolder="John">Name</Input>
            <Input placeHolder="name@gmail.com">Email</Input>
            <Input placeHolder="Subject">Subject</Input>
            <div className="contact-input">
              <label className="contact-input__label">Message</label>
              <textarea
                placeholder="Message"
                className="contact-input__input contact-input__input_textarea"
                type="text"></textarea>
            </div>
            <Button
              color="blue"
              className="contact-us__button">
              Send
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
