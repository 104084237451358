import { useState, useEffect } from "react";
import { getRefereesList } from "../http";

import { prepareFiltersStats } from "../../TeamsStats/utils/prepareFiltersStats";
import { sleep } from "../../../utils/sleep";

export const useStats = () => {
  const [filters, setFilters] = useState();
  const [teams, setTeams] = useState([]);
  const [searchFilters, setSearchFilters] = useState();
  const [page, setPage] = useState(1);

  const [paginateFilters, setPaginateFilters] = useState();

  const [filtersSeasons, setFiltersSeasons] = useState();
  const [filtersLeagues, setFiltersLeagues] = useState();

  const [loading, setLoading] = useState(false);
  const [refereeStatus, setRefereeStatus] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        await sleep(500);

        const { response, status } = await getRefereesList();

        if (!filters) {
          setSearchFilters(response.filters);
          setPaginateFilters({
            page: response.page,
            per_page: response.per_page,
            max_page: response.max_page,
          });
          setFilters(response.filters);
          setFiltersSeasons(response.filters.seasons);

          const newObj = {};
          for (const key in response.filters.leagues) {
            newObj[key] = true;
          }
          setFiltersLeagues(newObj);
        }

        setTeams(response.results);
        setRefereeStatus(status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const setStatType = (activeKey) => {
    const stat_type = filters.stat_type;
    var newStatType = {};
    for (const [key, value] of Object.entries(stat_type)) {
      newStatType[key] = key === activeKey ? true : false;
    }
    setFilters((prev) => ({ ...prev, stat_type: newStatType }));
  };

  const setTime = (activeKey) => {
    const time = filters.time;
    var newTime = {};
    for (const [key, value] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setSeason = (activeFilter) => {
    if (filtersSeasons.length === filters.seasons.length) {
      setFiltersSeasons([activeFilter]);
    } else {
      if (filtersSeasons.includes(activeFilter)) {
        setFiltersSeasons(
          filtersSeasons.filter((elem) => elem !== activeFilter)
        );
      } else {
        setFiltersSeasons(filtersSeasons.concat(activeFilter));
      }
    }
  };

  const setAllSeasons = () => {
    setFiltersSeasons(filters.seasons);
  };

  const setMin = (e) => {
    setFilters((prev) => ({ ...prev, min_count: e.target.value }));
  };

  const setCount = (count) => {
    setFilters((prev) => ({ ...prev, count: count }));
  };

  const setLeague = (league) => {
    var newLeagues = {};

    for (const [key, value] of Object.entries(filtersLeagues)) {
      newLeagues[key] =
        key === league ? !filtersLeagues[key] : filtersLeagues[key];
    }

    setFiltersLeagues(newLeagues);
  };

  const setAllLeagues = (toggle) => {
    var newLeagues = {};
    for (const [key] of Object.entries(filtersLeagues)) {
      newLeagues[key] = toggle;
    }
    setFiltersLeagues(newLeagues);
  };

  // const setPrettySeasons = (seasons) => {
  //   var newSeasons = {}
  //   Object.keys(seasons).forEach((season) => {
  //     const array = season.split("/")
  //     if (array.length === 1) {
  //       newSeasons[season] = seasons[season]
  //     } else {
  //       newSeasons[array[0] + "/" + array[1].slice(2, 4)] = seasons[season]
  //     }
  //   })

  //   return newSeasons
  // }

  const getSeasonsItems = (seasons) => {
    const previous = [];
    const current = [];
    for (let i = 0; i < seasons?.length; i++) {
      if (i > 9) {
        current.push(seasons[i]);
      } else {
        previous.push(seasons[i]);
      }
    }

    return [previous, current];
  };

  const onLoadClick = async (page) => {
    if (paginateFilters.page <= paginateFilters.max_page) {
      const { response, status } = await getRefereesList(
        prepareFiltersStats(filters, filtersSeasons, filtersLeagues, page)
      );
      setPaginateFilters({
        page: response.page,
        per_page: response.per_page,
        max_page: response.max_page,
      });
      setTeams(teams.concat(response.results));
      setRefereeStatus(status)
    }
  };

  const makeFetchFilters = (filter, seasons, leagues, sort_by, reverse) => {
    let newTime = "";
    let newStatType = "";
    const finalLeagues = [];

    Object.entries(leagues).forEach(([key, value]) => {
      if (value) {
        finalLeagues.push(Number(key));
      }
    });

    Object.entries(filter.time).forEach(([key, value]) => {
      if (value) {
        newTime = key;
      }
    });

    Object.entries(filter.stat_type).forEach(([key, value]) => {
      if (value) {
        newStatType = key;
      }
    });

    return {
      time: newTime,
      stat_type: newStatType,
      count: filter.count,
      page: filter.page,
      per_page: filter.per_page,
      sort: sort_by,
      order: reverse ? "asc" : "desc",
      min_count: filter.min_count,
      leagues: finalLeagues,
      seasons: seasons,
    };
  };

  const onApplyClick = async () => {
    try {
      setLoading(true)
      await sleep(500)

      const { response, status } = await getRefereesList(
        prepareFiltersStats(filters, filtersSeasons, filtersLeagues)
      );
      setPaginateFilters({
        page: response.page,
        per_page: response.per_page,
        max_page: response.max_page,
      });
      setTeams(response.results);
      setRefereeStatus(status)
    } catch (error) {
      if (error.response) {
        console.error("Status", error.response.status);
      } else if (error.request) {
        console.error("Error of request:", error.request);
      } else {
        console.error("Simle error is:", error);
      }
    } finally {
      setLoading(false)
    }
    
  };

  const onSortClick = async (sort_by, reverse) => {
    setFilters((prev) => ({
      ...prev,
      sort: sort_by,
      reverse: !reverse,
    }));
    // if (filters.sort === sort_by) {
    //   setFilters((prev) => ({
    //     ...prev,
    //     sort: sort_by,
    //     reverse: !reverse,
    //   }));

    //   Reverse = !reverse;
    // } else {
    //   setFilters((prev) => ({
    //     ...prev,
    //     reverse: true,
    //     sort: sort_by,
    //   }));
    // }
    // const fetchFilters = {
    //   ...makeFetchFilters(searchFilters),
    //   sort_by: sort_by,
    //   reverse: Reverse,
    // };
    const { response, status } = await getRefereesList(
      makeFetchFilters(
        filters,
        filtersSeasons,
        filtersLeagues,
        sort_by,
        reverse
      )
    );
    setTeams(response.results);
    setRefereeStatus(status)
  };

  return {
    filters,
    filtersSeasons,
    filtersLeagues,
    paginateFilters,
    onLoadClick,
    onSortClick,
    setStatType,
    getSeasonsItems,
    setSeason,
    setAllSeasons,
    setCount,
    setTime,
    setMin,
    teams,
    loading,
    refereeStatus,
    setLeague,
    setAllLeagues,
    onApplyClick,
  };
};
