export const timestampToDate = (date) => {
  const newDate =
    (new Date(date * 1000).getDate() < 10 ? "0" : "") +
    new Date(date * 1000).getDate() +
    "." +
    (new Date(date * 1000).getMonth() + 1 < 10 ? "0" : "") +
    (new Date(date * 1000).getMonth() + 1) +
    "." +
    new Date(date * 1000).getFullYear() +
    ", " +
    (new Date(date * 1000).getHours() < 10 ? "0" : "") +
    new Date(date * 1000).getHours() +
    ":" +
    (new Date(date * 1000).getMinutes() < 10 ? "0" : "") +
    new Date(date * 1000).getMinutes()
  return newDate
}
