export const filteredStats = (data) => {
  const keys = Object.keys(data);

  let sortedKeys;
  if (data["Goals"]) {
    sortedKeys = ["Goals", ...keys.filter((key) => key !== "Goals")];
  } else {
    sortedKeys = keys;
  }

  const newData = {};

  sortedKeys.forEach((key) => {
    newData[key] = data[key];
  });

  return newData
};
