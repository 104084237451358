export const Tabs = ({ setActiveTab, activeTab }) => {
  const swtichTab = (e) => {
    setActiveTab(e.target.innerText);
  };

  return (
    <div className="profile-tabs">
      <div
        className={`profile-tabs__tab ${
          activeTab === "Account" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Account
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Billing" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Billing
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Support" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Support
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Favorites" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Favorites
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Alerts" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Alerts
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Customization" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Customization
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Time Zone" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Time Zone
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Bookies" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Bookies
      </div>
      <div
        className={`profile-tabs__tab ${
          activeTab === "Odds" ? "profile-tabs__tab_active" : ""
        }`}
        onClick={swtichTab}>
        Odds
      </div>
    </div>
  );
};
