import { Layout } from "../../layouts/Layout";
import "../../assets/styles/subscription.scss";

export const Subscription = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="title">Plans</h1>
      </div>
    </Layout>
  );
};
