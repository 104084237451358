export const prepareFilters = (rawFilters) => {
  const seasonsArr = []
  let newTime = ""
  Object.entries(rawFilters.seasons).forEach(([key, value]) => {
    if (value) {
      seasonsArr.push(key)
    }
  })
  if (!Object.keys(rawFilters.time).includes("start")) {
    Object.entries(rawFilters.time).forEach(([key, value]) => {
      if (value) {
        newTime = key
      }
    })
  } else {
    newTime = rawFilters.time
  }
  return { ...rawFilters, seasons: seasonsArr, time: newTime }
}
