import { useSelector } from "react-redux";
import { themeSelector } from "../../store/themeSlice";
import "./Buttons.scss";

export const Button = ({ children, color, className, onClick }) => {
  const theme = useSelector(themeSelector);
  return (
    <button
      className={`button ${`button_${color} button_${color}_${theme} ${className}`}`}
      onClick={onClick}>
      {children}
    </button>
  );
};
