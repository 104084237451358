export const prepareOthers = (data) => {
  const result = []
  const rawResult = Object.entries(data || {}).map((item) => {
    return { id: item[0], name: item[1].name, m: item[1].count, avg: (item[1].stats["yellow_cards"].value / item[1].stats["yellow_cards"].count) }
  })

  for (let i = 0; i < rawResult?.length; i += 9) {
    if (i + 9 < rawResult?.length) result.push(rawResult?.slice(i, i + 9))
    else result.push(rawResult?.slice(i, rawResult?.length + 1))
  }

  return result
}
