import "./TimeZoneSelect.scss";

export const TimeZoneSelect = ({ options, label }) => {
  return (
    <div className="timezone-select">
      <label className="timezone-select__label">{label}</label>
      <select className="timezone-select__select">
        {options?.map((item, index) => (
          <option key={index}>{item}</option>
        ))}
      </select>
    </div>
  );
};
