import { useEffect, useState } from "react";
import { getHeader, getStats } from "../http";
import { getStatsTime } from "../utils/getStatsTime";
import { getNextFixtures, getPreviousFixtures } from "../../League/http";
import { filteredStats } from "../utils/filteredStats";

export const useStats = (id) => {
  const [result, setResult] = useState();
  const [stats, setStats] = useState();
  const [filters, setFilters] = useState();
  const [matches, setMatches] = useState();

  useEffect(() => {
    const fetch = async () => {
      const headerResponse = await getHeader(id);

      setResult(headerResponse.header);
      setFilters({
        time: { "All match": true, "1st half": false, "2nd half": false },
      });

      const statsResponse = await getStats(id, "All match");
      setStats(filteredStats(statsResponse));

      const previousResponse = await getPreviousFixtures(
        1,
        headerResponse.header.league_id
      );
      setMatches((prev) => ({
        ...prev,
        previous: previousResponse,
      }));

      const nextResponse = await getNextFixtures(
        1,
        headerResponse.header.league_id
      );
      setMatches((prev) => ({ ...prev, next: nextResponse }));
    };
    fetch();
  }, []);

  const setTime = (activeKey) => {
    const time = filters.time;
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const onStatsApplyClick = async () => {
    const response = await getStats(id, getStatsTime(filters.time));
    setStats(filteredStats(response));
  };

  const onLoadMoreClick = async (type, page) => {
    if (type === "previous") {
      const previousResponse = await getPreviousFixtures(
        page,
        result?.league_id
      );
      setMatches((prev) => ({
        ...prev,
        previous: ((previousPrev) => ({
          ...previousPrev,
          page: previousResponse.page,
          max_page: previousResponse.max_page,
          results: prev.previous.results.concat(previousResponse.results),
        }))(prev.previous),
      }));
    } else {
      const nextResponse = await getNextFixtures(page, result?.league_id);
      setMatches((prev) => ({
        ...prev,
        next: ((previousNext) => ({
          ...previousNext,
          page: nextResponse.page,
          max_page: nextResponse.max_page,
          results: prev.next.results.concat(nextResponse.results),
        }))(prev.next),
      }));
    }
  };

  return {
    result,
    filters,
    stats,
    matches,
    setTime,
    onStatsApplyClick,
    onLoadMoreClick,
  };
};
