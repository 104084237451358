import "./NumberFilter.scss"

export const NumberFilter = ({ filters, activeFilter, setFilter }) => {
  return (
    <div className="number-filter-wrapper">
      <div className="number-filter">
        {filters.map((item, index) => (
          <div
            key={index}
            onClick={(e) => setFilter(e.target.innerText)}
            className={`number-filter__item ${
              String(item) === String(activeFilter)
                ? "number-filter__item_active"
                : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>
      <input
        className="number-filter__input"
        type="number"
        onChange={(e) => setFilter(e.target.value)}
        value={activeFilter || ""}
        min={0}
      />
    </div>
  )
}
