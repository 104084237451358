import { Button } from "../../../../../UI/buttons/Button";
import { GridItem } from "../../../UI/GridItem/GridItem";
import "./Odds.scss";

export const Odds = () => {
  const items = [
    "Decimal odds",
    "American odds",
    "Fractional odds",
    "Hong Kong odds",
    "Indonesian odds",
    "Malaysian odds",
  ];
  return (
    <div className="odds">
      <div className="odds-grid">
        {items.map((item, index) => (
          <GridItem key={index}>
            <div className="grid-item-checkbox"></div>
            {item}
          </GridItem>
        ))}
      </div>
      <div className="odds-buttons">
        <Button color="blue">Save changes</Button>
        <Button color="gray">Reset</Button>
      </div>
    </div>
  );
};
