import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  page: "",
}

export const paginatorSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
  },
})

export const { setPage } = paginatorSlice.actions
export const paginatorSelector = (state) => state.paginator.page

export default paginatorSlice.reducer
