import $api from "../../../http"

export const getHeader = async (id) => {
  return await $api
    .get(`/fixture/header?id=${id}`)
    .then((response) => response.data)
}

export const getStats = async (id, time) => {
  return await $api
    .post(`/fixture/statistics?id=${id}`, { time: time })
    .then((response) => response.data)
}
