import $api from "../../../http"

export const getReferee = async (id) => {
  return await $api
    .get(`/referee/header?id=${id}`)
    .then((response) => response.data)
}

export const getRefereeSeasonStats = async (id, season_name) => {
  return await $api
    .get(`/referee/stats/season?id=${id}&season=${season_name}`)
    .then((response) => {
      return { data: response.data, status: response.status };
    });
}

export const getRefereeLeagueStats = async (id, league_id) => {
  return await $api
    .get(`/referee/stats/league?id=${id}&league_id=${league_id}`)
    .then((response) => {
      return { data: response.data, status: response.status };
    });
}

export const getRefereesList = async () => {
  return await $api
    .post(`/referee/list`)
    .then((response) => response.data)
}

export const getOtherReferees = async (page, id) => {
  return await $api
    .get(`/referee/other?page=${page}&per_page=10&sort=id&order=asc&id=${id}`)
    .then((response) => response.data)
}
