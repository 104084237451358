export const prepareRefereesTable = (data) => {
  const sumResult = {
    id: "Total",
    referee: "",
    matches: 0,
    winHome: 0,
    draws: 0,
    awayWins: 0,
    avg_total_home: 0,
    avg_total_away: 0,
    total: 0,
    avg_total: 0,
  }
  const tableResult = Object.entries(data).map(([key, value], index) => {
    sumResult.matches += value.count
    sumResult.avg_total += value.sums
    sumResult.total += value.t
    sumResult.avg_total_home += value.t1
    sumResult.avg_total_away += value.t2
    sumResult.winHome += value.fixture_results.home_wins
    sumResult.draws += value.fixture_results.draws
    sumResult.awayWins += value.fixture_results.away_wins

    return {
      id: index + 1,
      refereeObj: {name: value.name, id: value.id},
      matches: value.count,
      winHome: value.fixture_results.home_wins,
      draws: value.fixture_results.draws,
      awayWins: value.fixture_results.away_wins,
      avg_total_home: value.t1.toFixed(2),
      avg_total_away: value.t2.toFixed(2),
      total: value.t.toFixed(2),
      avg_total: value.sums.toFixed(2),
    }
  })

  sumResult.total = (sumResult.total / tableResult.length).toFixed(2)
  sumResult.avg_total = sumResult.avg_total.toFixed(2)
  sumResult.avg_total_home = (
    sumResult.avg_total_home / tableResult.length
  ).toFixed(2)
  sumResult.avg_total_away = (
    sumResult.avg_total_away / tableResult.length
  ).toFixed(2)

  return { table: tableResult, sums: sumResult }
}
